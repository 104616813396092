import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {KeycloakService} from "keycloak-angular";
import { environment } from 'src/environments/environment';
import { MembershipApplicationDetail } from 'src/app/models/membership-application-detail';

const headers = new HttpHeaders({
  'Content-Type': 'application/json'
});

@Injectable({
  providedIn: 'root'
})
export class MembershipApplicationDetailService {

  private url = `${environment.baseMicroServiceURL}:${environment.services.market.port}${environment.services.market.contextRoot}/membershipapplication`;
  private url2 = `${environment.baseCmisServiceURL}:${environment.services.cmisapplicationview.port}${environment.services.cmisapplicationview.contextRoot}/application`;



  constructor(private http: HttpClient,private keycloakService: KeycloakService) {

  }

  post(detail: MembershipApplicationDetail): Observable<any> {
    const url = `${this.url}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
        mergeMap((get) => this.http.post<any>(url, detail, {headers: get})
        )
    );
  }


  postupdate(detail: MembershipApplicationDetail): Observable<any> {
    const url = `${this.url}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
        mergeMap((get) => this.http.put<any>(url, detail, {headers: get})
        )
    );
  }



  get(userId: string): Observable<MembershipApplicationDetail> {
    const url = `${this.url2}/${userId}`;
    return this.http.get<MembershipApplicationDetail>(url);
  }

}
