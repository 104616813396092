import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {InvoiceDetail} from '../../../models/invoice';
import {PaymentDetail} from '../../../models/payment';
import {MembershipCertificateDetail} from '../../../models/membership-certificate';
import {Subscription} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {InvoiceService} from '../../../common-services/common-services/invoice.service';
import {PaymentService} from '../../../common-services/common-services/payment.service';
import {MembershipCertificateService} from '../../../common-services/common-services/membership-certificate.service';
import * as FileSaver from 'file-saver';


@Component({
  selector: 'app-download-documents',
  templateUrl: './download-documents.component.html',
  styleUrls: ['./download-documents.component.scss']
})
export class DownloadDocumentsComponent implements OnInit, OnDestroy {
  @Input('membershipApplicationNo') membershipApplicationNo: string;
  invoiceDetail: InvoiceDetail;
  paymentDetail: PaymentDetail;
  membershipCertificateDetail: MembershipCertificateDetail;
  invoiceSubscription: Subscription;
  paymentSubscription: Subscription;
  membershipCertificateSubscription: Subscription;

  constructor(private fb: FormBuilder,
              private invoiceService: InvoiceService,
              private paymentService: PaymentService,
              private membershipCertificateService: MembershipCertificateService) {
  }

  ngOnInit() {
    if (this.membershipApplicationNo) {




      this.getInvoice(this.membershipApplicationNo);
      this.getPaymentReceipt(this.membershipApplicationNo);
      this.getMembershipCertificate(this.membershipApplicationNo);

    }
  }

  downloadInvoice() {
    FileSaver.saveAs(this.invoiceDetail.filePath, 'Invoice_' + this.invoiceDetail.invoiceNo + '.pdf');
  }

  downloadPaymentReceipt() {
    FileSaver.saveAs(this.paymentDetail.filePath, 'Receipts_' + this.paymentDetail.receiptNo + '.pdf');
  }

  downloadMembershipCertificate() {
    FileSaver.saveAs(this.membershipCertificateDetail.filePath, 'Certificates_' + this.membershipApplicationNo + '.pdf');
  }

  private getInvoice(membershipApplicationNo: string) {
    this.invoiceSubscription =
        this.invoiceService.getbyApplicationNo(membershipApplicationNo).subscribe(value => {
          if (value) {
            this.invoiceDetail = value;
          }
        });
  }

  private getPaymentReceipt(membershipApplicationNo: string) {
    this.paymentSubscription =
        this.paymentService.getbyApplicationNo(membershipApplicationNo).subscribe(value => {
          if (value) {
            this.paymentDetail = value;
          }
        });

  }

  private getMembershipCertificate(membershipApplicationNo: string) {
    this.membershipCertificateSubscription =
        this.membershipCertificateService.getbyApplicationNo(membershipApplicationNo).subscribe(value => {
          if (value) {
            this.membershipCertificateDetail = value;
          }
        });
  }

  ngOnDestroy(): void {
    if (this.invoiceSubscription) {
      this.invoiceSubscription.unsubscribe();
    }
    if (this.paymentSubscription) {
      this.paymentSubscription.unsubscribe();
    }
    if (this.membershipCertificateSubscription) {
      this.membershipCertificateSubscription.unsubscribe();
    }
  }
}
