import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {KeycloakService} from 'keycloak-angular';
import {Subscription} from 'rxjs';
import {UserMemberService} from 'src/app/common-services/user-member.service';
import {Member} from 'src/app/models/member';
import {Chat} from '../../products/models/chat';
import {ChatList} from '../../products/models/chat-list';
import {ChatMessage} from '../../products/models/chat-message';
import {SenderReceiver} from '../../products/models/sender-receiver';
import {ChatMessageService} from '../chat-message.service';
import {ChatService} from '../chat.service';
import {ReceiverService} from '../receiver.service';
import {ChatDTO} from '../../products/models/chatDTO';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-chat-member',
    templateUrl: './chat-member.component.html',
    styleUrls: ['./chat-member.component.scss'],
})
export class ChatMemberComponent implements OnInit {
    chatForm: FormGroup;
    chatMessageSubscription: Subscription;
    userMemberSubscription: Subscription;
    messagesSubscription: Subscription;
    chatMessages: ChatList[] = [];
    sender: string;
    receiver: string;
    pageIndex = 0;
    pageSize = 50;
    isLoading = false;
    senderReceiver: SenderReceiver;
    userName: string;
    firstName: string;
    lastName: string;
    dataRefresher: any;
    allign: string;
    user: Member;
    chat: Chat;
    message: string;
    chatMessagess: ChatMessage[] = [];
    memberUuid: string;
    offset: any;
    @ViewChild('scrollMe') private myScrollContainer: ElementRef;
    @Input('selectedChat') selectedChat: ChatDTO;

    constructor(
        private fb: FormBuilder,
        private chatMessageService: ChatMessageService,
        private sharedService: ReceiverService,
        private keycloakService: KeycloakService,
        private chatService: ChatService,
        private userMemberService: UserMemberService) {
    }

    ngOnInit() {
        this.sharedService.getChatHistory().subscribe((chats) => {
            this.chatMessages = chats;

            this.sharedService.getSenderReceiver().subscribe((senderReceiver) => {
                this.senderReceiver = senderReceiver;
            });
        });
        this.getMember();

        this.buildForm();

        this.keycloakService.loadUserProfile().then((profile) => {
            this.userName = profile.email;
            this.firstName = profile.firstName;
            this.lastName = profile.lastName;
        });

        this.scrollToBottom();
        this.offset = new Date().getTimezoneOffset();
    }
    getMember() {
        this.userMemberSubscription = this.userMemberService.getMemberByUser().subscribe(value => {
            if (value) {
                this.memberUuid = value.uuid;
            }
        });
    }

    ngAfterViewInit() {
        this.refreshData();
    }

    ngAfterViewChecked() {
        this.scrollToBottom();
    }

    scrollToBottom(): void {
        try {
            this.myScrollContainer.nativeElement.scrollTop =
                this.myScrollContainer.nativeElement.scrollHeight;
        } catch (err) {
        }
    }

    refreshData() {
        this.dataRefresher = setInterval(() => {
            this.chatMessageService
                .getByChatUuid(this.senderReceiver.chatUuid)
                .subscribe((value) => {
                    this.chatMessages = value;
                });
        }, 3000);
    }

    onSend() {
        if (!this.chatForm.valid) {
            return;
        }

        if (this.senderReceiver.receiver === this.memberUuid) {
            this.message = this.chatForm.value.message;
            const chatMessage: ChatMessage = {
                chatUuid: this.senderReceiver.chatUuid,
                message: this.message,
                sender: this.memberUuid,
                receiver: this.senderReceiver.sender,
                sentDate: new Date('yyyy-MM-dd HH:mm:ss'),
            };

            this.chatMessageService.post(chatMessage).subscribe((value) => {
                this.chatForm.reset();
                if (value) {
                    this.chatMessagess.push(value);
                }
            });
        } else {
            this.message = this.chatForm.value.message;
            const chatMessage: ChatMessage = {
                chatUuid: this.senderReceiver.chatUuid,
                message: this.message,
                sender: this.memberUuid,
                receiver: this.senderReceiver.receiver,
                sentDate: new Date('yyyy-MM-dd HH:mm:ss'),
            };

            this.chatMessageService.post(chatMessage).subscribe((value) => {
                this.chatForm.reset();
                if (value) {
                    this.chatMessagess.push(value);
                }
            });
        }
    }

    getSlot(sender: string) {
        if (this.sender === sender) {
            return 'end start';
        }
    }

    ngOnDestroy(): void {
        this.cancelPageRefresh();
        if (this.chatMessageSubscription) {
            this.chatMessageSubscription.unsubscribe();
        }
        if (this.messagesSubscription) {
            this.messagesSubscription.unsubscribe();
        }
    }

    getStyle(sender: string) {
        return this.sender === sender ? '' : 'background-color : #65666D; ';
    }

    cancelPageRefresh() {
        if (this.dataRefresher) {
            clearInterval(this.dataRefresher);
        }
    }

    private buildForm() {
        this.chatForm = this.fb.group({
            id: [''],
            uuid: [''],
            message: ['', Validators.required],
        });
    }

    getImagePath(itemImageUrl?: string) {
        itemImageUrl = itemImageUrl ? itemImageUrl : environment.defaultImageName;
        return environment.imagesServerPath + itemImageUrl;
    }
}
