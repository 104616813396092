import { KeycloakConfig } from 'keycloak-js';

const keycloakConfig: KeycloakConfig = {
  url: 'https://market.kenyachamber.or.ke/auth/',
  realm: 'CMIS_PROD',
  clientId: 'cmis-web',
};

export const environment = {
  url: 'https://market.kenyachamber.or.ke/',
  production: true,
  keycloak: keycloakConfig,
  loginRedirectUri: 'https://market.kenyachamber.or.ke/',
  logoutRedirectUri: 'https://market.kenyachamber.or.ke/',
  googleMapsAPIKey:
    'AIzaSyCMJ-QgnhOQ_9IPMx9_aqj7MclyFws03NE',
  baseMicroServiceURL: 'https://market.kenyachamber.or.ke',
  // baseCmisServiceURL: "http://212.22.169.22",
  baseCmisServiceURL: 'https://cmis.kenyachamber.or.ke',
  imagesServerPath: 'https://market.kenyachamber.or.ke/cmis/webservices/document/images/',
  defaultImageName: 'greybg.png',
  imageConfigs: {
    serverPath: 'https://market.kenyachamber.or.ke/cmis/images/',
    imagePath: {
      front: 'insecure/fit/',
      end: 'no/0/plain/local:///'
    },
    resolution: {
      medium: {
        width: 480,
        height: 360
      },
      small: {
        width: 180,
        height: 135
      },
      big: {
        width: 960,
        height: 720
      },
      category: {
        width: 150,
        height: 150
      }
    }
  },
  services: {
    business: {
      port: 443,
      contextRoot: '/cmis/webservices/business',
    },

    document: {
      port: 443,
      contextRoot: '/cmis/webservices/document',
    },

    market: {
      port: 443,
      contextRoot: '/cmis/webservices/market',
    },
    content: {
      port: 443,
      contextRoot: '/cmis/webservices/content',
    },
    cmis: {
      port: '8070',
      contextRoot: '/cmis/seam/resource/rest/marketplace'
    },
    cmis1: {
      port: '',
      contextRoot: '/cmis/seam/resource/rest/marketplace'
    },
    cmisapplicationtest: {
      port: '',
      contextRoot: '/cmis/seam/resource/rest/marketplace'
    },
    cmisapplicationsavememebership: {
      port: '',
      contextRoot: '/cmis/seam/resource/rest/save/membership'
    },
    cmisapplicationview: {
      port: '',
      contextRoot: '/cmis/seam/resource/rest/marketplace/view'
    },
    cmisapplicationupdate: {
      port: '',
      contextRoot: '/cmis/seam/resource/rest/marketplace/edit'
    }


},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
