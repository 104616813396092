import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { environment } from 'src/environments/environment';
import {Category, CategoryData, CategoryDetail, CategoryPage} from '../models/category';

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
});

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {

  private _categoryList = new BehaviorSubject<Category[]>( []);

  private url = `${environment.baseMicroServiceURL}:${environment.services.market.port}${environment.services.market.contextRoot}/category-controller`;

  constructor(
    private http: HttpClient
  ) {}

  get categoryList(): Observable<Category[]> {
    return this._categoryList.asObservable();
  }

  getAllByPage(page, size): Observable<CategoryPage> {
    const url = `${this.url}/category/page?page=${page}&size=${size}`;
    return this.http.get<CategoryPage>(url, {headers});
  }

  getAll(): Observable<Category[]> {
    const url = `${this.url}/category`;
    return this.http.get<Category[]>(url);
  }

  getData(): Observable<CategoryData[]> {
    const url = `${this.url}/category/data`;
    return this.http.get<CategoryData[]>(url);
  }

  getbyId(id: number): Observable<Category> {
    const url = `${this.url}/category/${id}`;
    return this.http.get<Category>(url);
  }
  getDetailById(id: number): Observable<CategoryDetail> {
    const url = `${this.url}/category/detail/${id}`;
    return this.http.get<CategoryDetail>(url);
  }

  post(sender: CategoryDetail): Observable<Category> {
    const url = `${this.url}/category`;
    return this.http.post<Category>(url, sender);
  }

  put(sender: CategoryDetail): Observable<Category> {
    const url = `${this.url}/category/${sender.id}`;
    return this.http.put<Category>(url, sender);
  }

  delete(id: number): Observable<any> {
    const url = `${this.url}/category/${id}`;
    return this.http.delete<Category>(url);
  }

  findAllByCategoryLevel(level: string): Observable<Category[]> {
    return this.http.get<Category[]>(`${this.url}/categoryLevel?categoryLevel=${level}`);
  }
  findAllByCategoryLevelAndPage(level: string, page: number, size: number, random = false): Observable<CategoryPage> {
    return this.http.get<CategoryPage>(`${this.url}/categoryLevel/page?categoryLevel=${level}&page=${page}&size=${size}&random=${random}`);
  }

  findAllByParentCategoryUuid(s: string): Observable<Category[]> {
    return this.http.get<Category[]>(`${this.url}?parentCategoryUuid=${s}`);

  }
}
