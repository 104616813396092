<div class="products-tabs">

    <mat-tab-group mat-stretch-tabs [selectedIndex]="selectedTab" (selectedIndexChange)="tabChangedEvent($event)">


        <mat-tab label="Contact Details">
            <app-contact-deatils (nextTab)="selectedtabChangeEvent($event)"
                                 [selectedIndex]="selectedTab"></app-contact-deatils>
        </mat-tab>

        <mat-tab label="Person Details">
            <app-person-details (nextTab)="selectedtabChangeEvent($event)"
                                [selectedIndex]="selectedTab"></app-person-details>

        </mat-tab>

        <mat-tab label="Geo-Location">
            <app-geo-location (nextTab)="selectedtabChangeEvent($event)"
                              [selectedIndex]="selectedTab"></app-geo-location>

        </mat-tab>

        <mat-tab label="County & Services">
            <app-county-n-services (nextTab)="selectedtabChangeEvent($event)"
                                   [memberShipCategory]="memberShipCategory"
                                   [memberShipCategoryCounty]="memberShipCategoryCounty"
                                   [selectedIndex]="selectedTab"></app-county-n-services>

        </mat-tab>

        <mat-tab label="Documents">
            <app-documents (nextTab)="selectedtabChangeEvent($event)" [selectedIndex]="selectedTab"
                           (isDocumentsSaved)="documentsSaved($event)"
                           [accountType]="accountType"></app-documents>

        </mat-tab>
    </mat-tab-group>

    <br><br><br><br><br>

    <div fxLayout="row" *ngIf="selectedTab === 4">
        <button mat-stroked-button color="primary" (click)="onCancel()">Cancel</button>
        <span fxFlex>&nbsp;</span>
        <button mat-raised-button color="primary" [disabled]="clicked || !isDocumentsSaved" (click)="onSubmit()">
            Submit Application
        </button>
    </div>


</div>
