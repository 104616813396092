import { AccountType } from '../models/account-type';

export interface CountyNService {
  ownerUuid: string;
  accountType: AccountType;
  locationCounty: string;
  memberShipCategory: string;
  memberShipCategoryCounty: string;
 // memberShipCounty: string;
  duration: number;
  mainSector: string;
  division: string;
  group: string;
  sector: string;
  goodsService: string;
  businessServices: string[];
  firstRegistrationDate: Date;
  lastAccountRenewalDate: Date;
  companyTurnover: string;
  expectations: string;
}

export const COUNTY_N_SERVICE_STORAGE_KEY = 'county-n-servives';
