import {Component, OnDestroy, OnInit} from '@angular/core';
import {AccountType} from '../../models/account-type';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MembershipApplicationDetailService} from '../../common-services/common-services/membership-application-detail.service';
import {RegistrationService} from '../../common-services/registration.service';
import {KeycloakService} from 'keycloak-angular';
import {CONTACT_DETAIL_STORAGE_KEY, ContactDetail} from '../../models/contact-detail';
import {COMPANY_DETAIL_STORAGE_KEY, CompanyDetail} from '../../models/company-detail';
import {COUNTY_N_SERVICE_STORAGE_KEY, CountyNService} from '../../models/county-n-service';
import {FileAttachment} from '../../models/file-attachment';
import {GEOLOCATION_STORAGE_KEY, GeoLocationDto} from '../registration-common/geo-location/geo-location-dto';
import {DOCUMENT_STORAGE_KEY, FILES_STORAGE_KEY} from '../registration-common/documents/documents.component';
import {MembershipApplicationDetail} from '../../models/membership-application-detail';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html',
    styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit, OnDestroy {

    accountType = AccountType.COMPANY;
    private onSubmitSubscription: Subscription;
    private onCancelSubscription: Subscription;
    memberShipCategory: string;
    memberShipCategoryCounty: string;
    selectedTab = 0;
    clicked = false;
    isDocumentsSaved = false;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private _snackBar: MatSnackBar,
        private keycloakService: KeycloakService,
        private route: ActivatedRoute,
        private membershipApplicationDetailService: MembershipApplicationDetailService,
        private registrationService: RegistrationService) {
    }

    ngOnInit() {
        this.route.paramMap.subscribe((paramMap) => {
            if (!paramMap.has('memberShipCategoryCounty')) {
                this.router.navigate(['choose-service']);
                return;
            }
            if (!paramMap.has('memberShipCategory')) {
                this.router.navigate(['choose-service']);
                return;
            }
            this.memberShipCategoryCounty = paramMap.get('memberShipCategoryCounty');
            this.memberShipCategory = paramMap.get('memberShipCategory');
        });
    }


    async onSubmit() {
        const keycloakProfile = await this.keycloakService.loadUserProfile();
        let keycloakEmail = keycloakProfile.username;

        let contactDetails: ContactDetail;
        let companyDetail: CompanyDetail;
        let countyNService: CountyNService;
        let fileAttachments: FileAttachment[];
        let geolocation: GeoLocationDto;


        this.onSubmitSubscription = forkJoin([
            this.registrationService.getValueInStorage(CONTACT_DETAIL_STORAGE_KEY),
            this.registrationService.getValueInStorage(COMPANY_DETAIL_STORAGE_KEY),
            this.registrationService.getValueInStorage(COUNTY_N_SERVICE_STORAGE_KEY),
            this.registrationService.getValueInStorage(DOCUMENT_STORAGE_KEY),
            this.registrationService.getValueInStorage(GEOLOCATION_STORAGE_KEY),
        ]).subscribe((value) => {
            contactDetails = value[0];
            companyDetail = value[1];
            countyNService = value[2];
            fileAttachments = value[3];
            geolocation = value[4];

            if (!contactDetails) {
                console.log(contactDetails);
                this.presentToast(
                    'Contact Details is empty. Please add Contact Details..', 5000
                );
                return;
            } else if (!companyDetail) {
                this.presentToast(
                    'Company Details is empty. Please add company details ..', 5000
                );
                return;
            } else if (!countyNService) {
                this.presentToast(
                    'Country & Service Details is empty. Please add Country & Service details ..', 5000
                );
                return;
            } else if (!fileAttachments || fileAttachments.length === 0) {
                this.presentToast('Documents is empty. Please add documents ..', 5000);
                return;
            } else {
                this.clicked = true;
                countyNService.accountType = this.accountType;
                if (companyDetail.logo) {
                    fileAttachments.push(companyDetail.logo.fileAttachment);
                    delete companyDetail.logo;
                }
                const membershipApplicationDetail: MembershipApplicationDetail = {
                    companyDetail,
                    contactDetail: contactDetails,
                    countyNService,
                    fileAttachments,
                    geolocation,
                    personDetail: null,
                    keycloakEmail,
                };
                this.membershipApplicationDetailService
                    .post(membershipApplicationDetail)
                    .subscribe(value => {
                            this.clearLocalStorage().subscribe();
                            this.presentToast('Saved!');
                            this.router.navigate(['/', 'account', 'view-progress']);
                        },
                        (error) => {
                            console.log('error');
                            console.log(error);
                            this.presentToast('An error occured! Please try again.', 5000);
                        }, () => {
                            this.clicked = false;

                        }
                    );
            }
        });
    }

    clearLocalStorage(): Observable<any> {
        return forkJoin([
            this.registrationService.removeValueInStorage(CONTACT_DETAIL_STORAGE_KEY),
            this.registrationService.removeValueInStorage(COMPANY_DETAIL_STORAGE_KEY),
            this.registrationService.removeValueInStorage(
                COUNTY_N_SERVICE_STORAGE_KEY
            ),
            this.registrationService.removeValueInStorage(DOCUMENT_STORAGE_KEY),
            this.registrationService.removeValueInStorage(FILES_STORAGE_KEY),
            this.registrationService.removeValueInStorage(GEOLOCATION_STORAGE_KEY),
        ]);
    }

    onCancel() {
        this.onCancelSubscription = this.clearLocalStorage().subscribe((value) => {
            this.router.navigate(['/']);
        });
    }

    ngOnDestroy(): void {
        if (this.onSubmitSubscription) {
            this.onSubmitSubscription.unsubscribe();
        }
        if (this.onCancelSubscription) {
            this.onCancelSubscription.unsubscribe();
        }
    }

    async presentToast(text, duration = 3000) {
        this._snackBar.open(text, 'Show', {
            duration: duration,
        });
    }

    selectedtabChangeEvent(event) {
        this.selectedTab = event;
    }

    tabChangedEvent(event) {
        this.selectedTab = event;
    }

    documentsSaved(event: boolean) {
        this.isDocumentsSaved = event;
    }
}
