export interface ContactDetail {
 ownerUuid: string;
  firstName: string;
  lastName: string;
  otherName: string;
  gender: string;
  role: string;
  email: string;
  phoneNumber: string;
}

export const CONTACT_DETAIL_STORAGE_KEY = 'contact-details';
