import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from 'rxjs';
import {CmisPayload, CMISRESPONSE} from './testpipe.pipe';

const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
   // Authorization: 'Basic ' + btoa('rest-admin:test')
});


@Injectable({
    providedIn: 'root'
})
export class RegistrationNumberCOnfirmationService {

    constructor(private http: HttpClient) { }


    validate(payload: CmisPayload): Observable<CMISRESPONSE> {

        const url = `https://cmis.kenyachamber.or.ke/cmis/seam/resource/rest/marketplace/validate/registration`;
        return this.http.post<CMISRESPONSE>(url, payload, {headers});
    }



    validateKra(payload: CmisPayload): Observable<CMISRESPONSE> {

        const url = `https://cmis.kenyachamber.or.ke/cmis/seam/resource/rest/marketplace/validate/pin`;
        return this.http.post<CMISRESPONSE>(url, payload, {headers});
    }
}
