import {Component, EventEmitter, Input, OnInit, Output, Renderer2, VERSION, } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {RegistrationService} from 'src/app/common-services/registration.service';
import {UserMemberService} from 'src/app/common-services/user-member.service';
import {CONTACT_DETAIL_STORAGE_KEY, ContactDetail, } from 'src/app/models/contact-detail';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-contact-deatils',
    templateUrl: './contact-deatils.component.html',
    styleUrls: ['./contact-deatils.component.scss'],
})
export class ContactDeatilsComponent implements OnInit {
    contactDetailSubscription: Subscription;
    registrationSubscription: Subscription;
    userMemberSubscription: Subscription;
    memberDataSubscription: Subscription;
    contactPersonForm: FormGroup;
    @Output() nextTab = new EventEmitter<number>();
    @Input() selectedIndex: number;

    version = VERSION;

    @Input() isDisabled = false;

    constructor(
        private fb: FormBuilder,
        public renderer: Renderer2,
        private _snackBar: MatSnackBar,
        private userMemberService: UserMemberService,
        private registrationService: RegistrationService,
        public formBuilder: FormBuilder
    ) {
    }

    ngOnInit() {
        this.buildForm();
        this.registrationSubscription = this.registrationService
            .getValueInStorage(CONTACT_DETAIL_STORAGE_KEY)
            .subscribe((value) => {
                if (value) {
                    console.log('patch contact details');
                    console.log(value);
                    console.log('patch this');
                    this.contactPersonForm.patchValue(value);
                    console.log('this is the patch ' + value);
                } else {
                    this.getMember();
                }
            });
    }

    onSubmit() {
        if (!this.contactPersonForm.valid) {
            return;
        }
        this.saveForm();
    }

    getMember() {
        this.userMemberSubscription = this.userMemberService
            .getMemberByUser()
            .subscribe((value1) => {
                    if (value1) {
                        const member = JSON.parse(JSON.stringify(value1));
                        this.contactPersonForm
                            .get('firstName')
                            .setValue(member.firstName);
                        this.contactPersonForm
                            .get('lastName')
                            .setValue(member.lastName);
                        this.contactPersonForm
                            .get('otherName')
                            .setValue(member.otherName);
                    }
                },
                (error) => {
                    console.log('error');
                    console.log(error);
                });
    }

    async presentToast(text, duration = 3000) {
        this._snackBar.open(text, 'Show', {
            duration,
        });
    }

    saveForm() {
        const contactDetail: ContactDetail = this.contactPersonForm.value;
        this.contactDetailSubscription = this.registrationService
            .setValueInStorage(CONTACT_DETAIL_STORAGE_KEY, contactDetail)
            .subscribe(
                (value) => {
                    this.presentToast('Saved!');
                    this.nextTab.emit(++this.selectedIndex);
                },
                (error) => {
                    this.presentToast('An error occured! Please try again.', 5000);
                }
            );
    }

    ngOnDestroy(): void {
        if (this.contactDetailSubscription) {
            this.contactDetailSubscription.unsubscribe();
        }
        if (this.registrationSubscription) {
            this.registrationSubscription.unsubscribe();
        }
        if (this.contactPersonForm) {
            this.contactPersonForm.reset();
        }
        if (this.userMemberSubscription) {
            this.userMemberSubscription.unsubscribe();
        }
        if (this.memberDataSubscription) {
            this.memberDataSubscription.unsubscribe();
        }
    }

    private buildForm() {
        this.contactPersonForm = this.fb.group({
            ownerUuid: [''],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            otherName: [''],
            email: ['', [Validators.required, Validators.email]],
            phoneNumber: ['', [Validators.required, Validators.pattern('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$')]],
            gender: ['', Validators.required],
            role: ['', Validators.required],
        });
    }
}
