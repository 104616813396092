import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {KeycloakService} from 'keycloak-angular';
import {BehaviorSubject, Observable} from 'rxjs';
import { tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {MembershipApplicationDetail} from '../../models/membership-application-detail';
import {FileAttachment} from '../../models/file-attachment';


export interface MembershipApplicationStatus {
    applicationStatus: string;
    success: boolean;
    fileAttachments: FileAttachment[];

}
@Injectable({
    providedIn: 'root'
})
export class MembershipApplicationService {

    private _existingMembershipApplication = new BehaviorSubject<boolean>(false);
    private _activeMembership = new BehaviorSubject<boolean>(false);
    private url = `${environment.baseCmisServiceURL}:${environment.services.cmisapplicationview.port}${environment.services.cmisapplicationview.contextRoot}/application`;



    private url1 = `${environment.baseCmisServiceURL}:${environment.services.cmisapplicationview.port}${environment.services.cmisapplicationview.contextRoot}/status`;

  private  url2 = 'https://cmis.kenyachamber.or.ke/seam/resource/rest/marketplace/download';


//   private  url2 = 'http://projects.technovation.ke:8009/cmis/seam/resource/rest/marketplace/download';


    constructor(private http: HttpClient, private keycloakService: KeycloakService) {
    }

    get existingMembershipApplication(): BehaviorSubject<boolean> {
        return this._existingMembershipApplication;
    }

    set existingMembershipApplication(value: BehaviorSubject<boolean>) {
        this._existingMembershipApplication = value;
    }

    get activeMembership(): Observable<boolean> {
        return this._activeMembership.asObservable();
    }

    getByUser(userId: string): Observable<MembershipApplicationDetail> {
        const url = `${this.url}/${userId}`;
        return this.http.get<MembershipApplicationDetail>(url);
    }

    getApplicationStatusByUserId(userId: string): Observable<MembershipApplicationStatus> {
        console.log(":::::::::::::::::::::::::: this is the userId111111  .....email" + userId);

        const url = `${this.url1}/${userId}`;
        console.log(":::::::::::::::::::::::::: this is the userId  .....email" + userId);
        return this.http.get<MembershipApplicationStatus>(url).pipe(
            tap( x => {
                console.log('::::::::::::::::::::::::::::::' , x);
                if (x.success === true) {
                    this.existingMembershipApplication.next(true);
                    this._activeMembership.next(x.applicationStatus === 'APPROVED');
                }
            })
        );
    }


    downloadSampleDocuments(fileUuid: string): Observable<Blob> {
        const url = `${this.url2}/${fileUuid}`;
        return this.http.get<Blob>(url, {responseType: 'blob' as 'json'});

    }
}
