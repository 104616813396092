import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { KeycloakService } from "keycloak-angular";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { FileAttachment, FileAttachmentPage } from "src/app/models/file-attachment";
import { DocumentUpload } from "src/app/models/document-upload";

const headers = new HttpHeaders({
  "Content-Type": "application/json",
});

@Injectable({
  providedIn: "root",
})
export class FileAttachmentService {
  private url = `${environment.baseMicroServiceURL}:${environment.services.document.port}${environment.services.document.contextRoot}/file-attachment-controller`;

  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService
  ) {}

  getAll(): Observable<FileAttachment[]> {
    const url = `${this.url}/attachment`;
    return this.http.get<FileAttachment[]>(url);
  }

  getAllByPage(page, size): Observable<FileAttachmentPage> {
    const url = `${this.url}/attachment/page?page=${page}&size=${size}`;
    return this.http.get<FileAttachmentPage>(url, { headers: headers });
  }

  getbyId(id: number): Observable<FileAttachment> {
    const url = `${this.url}/attachment/${id}`;
    return this.http.get<FileAttachment>(url);
  }

  saveAll(fileAttachments: FileAttachment[]): Observable<any> {
    const url = `${this.url}/attachment/list`;
    return this.http.post<[]>(url, fileAttachments);
  }
  post(sender: FileAttachment): Observable<FileAttachment> {
    const url = `${this.url}/attachment`;
    return this.http.post<FileAttachment>(url, sender);
  }

  put(sender: FileAttachment): Observable<FileAttachment> {
    const url = `${this.url}/attachment/${sender.id}`;
    return this.http.put<FileAttachment>(url, sender);
  }

  delete(id: number): Observable<any> {
    const url = `${this.url}/attachment/${id}`;
    return this.http.delete<FileAttachment>(url);
  }

  getDocumentDetail(ownerUuid: string): Observable<DocumentUpload[]> {
    const url = `${this.url}/attachment/list/detail?ownerUuid=${ownerUuid}`;
    return this.http.get<DocumentUpload[]>(url);
  }
}
