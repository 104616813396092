import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-trade-informatio',
  templateUrl: './trade-informatio.component.html',
  styleUrls: ['./trade-informatio.component.scss']
})
export class TradeInformatioComponent implements OnInit {
  name = 'Set iframe source';
  url = 'https://www.kenyachamber.or.ke/trade-information/';
  urlSafe: SafeResourceUrl;
  constructor( public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
