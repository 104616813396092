import {SidenavMenu} from './sidenav-menu.model';
import {Menu} from '../../../admin/components/menu/menu.model';

export const sidenavMenuItems = [
    new SidenavMenu(1, 'Home', '/', null, null, false, 0),


    new Menu(30, 'Membership', null, null, 'monetization_on', null, true, 0),
    
    new Menu(31, 'Apply', '/choose-service', null, 'list_alt', null, false, 30),
    new Menu(32, 'Renew', '#', null, 'local_atm', null, false, 30),
    new Menu(33, 'Transactions', '/account/view-progress', null, 'local_atm', null, false, 30),
    new Menu(34, 'Profile', '/account/view-membership-profile', null, 'local_atm', null, false, 30),


    new Menu(20, 'Certificate of origin', null, null, 'grid_on', null, true, 0),
    new Menu(22, 'Apply', '/coo-operations', null, 'list', null, false, 20),
    new Menu(24, 'Transactions', '#', null, 'add_circle_outline', null, false, 20),


    new Menu(29, 'Websites', null, null, 'grid_on', null, true, 0),
    new Menu(22, 'News And Press', null, 'https://www.kenyachamber.or.ke/news-press/', 'list', '_blank', false, 29),
    new Menu(24, 'KNCCI Membership', null, 'https://www.kenyachamber.or.ke/membership/', null, '_blank', false, 29),
    new Menu(22, 'Trade Information', null, 'https://www.kenyachamber.or.ke/trade-information/', null, '_blank', false, 29),
    new Menu(24, 'Foreign Trade Procedures', null, 'https://www.kenyachamber.or.ke/kncci-services/foreign-trade-procedures/', null, '_blank', false, 29),
    new Menu(24, 'HQ & Country Contacts', null, 'https://www.kenyachamber.or.ke/our-contacts/', null, '_blank', false, 29),


];

