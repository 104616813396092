import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';
import { UserMemberService } from 'src/app/common-services/user-member.service';
import {MembershipApplicationService} from '../../../common-services/common-services/membership-application.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  isLoggedIn = false;
  userMemberSubscription: Subscription;
  activeMembership: boolean;
  membershipSubscription: Subscription;
  adminRole = false;
  roles: string[];
  userRole = false;
  keycloakUserId: string;

  constructor(
    private keycloakService: KeycloakService,
    private route: Router,
    private userMemberService: UserMemberService,
    private membershipApplicationService: MembershipApplicationService  ) {}

  async ngOnInit() {
    this.isLoggedIn = await this.keycloakService.isLoggedIn();
    const keycloakProfile =  await this.keycloakService.loadUserProfile();
    this.keycloakUserId = keycloakProfile.username;
    this.getMember();
    if (this.isLoggedIn) {
      this.roles = this.keycloakService.getUserRoles();
      this.adminRole = this.hasRole('admin');
      this.userRole = this.hasRole('user');
      if (this.userRole) {
        this.getMembership();
      }
    }
    this.membershipSubscription = this.membershipApplicationService.activeMembership
        .subscribe(value => {
          this.activeMembership = value;
        });
  }
  hasRole(rolaname: string) {
    let hasRole = false;
    this.roles.forEach((r) => {
      if (r === rolaname) {
        hasRole = true;
      }
    });
    return hasRole;
  }

  openMegaMenu() {
    const pane = document.getElementsByClassName('cdk-overlay-pane');
    [].forEach.call(pane, function(el) {
      if (el.children.length > 0) {
        if (el.children[0].classList.contains('mega-menu')) {
          el.classList.add('mega-menu-pane');
        }
      }
    });
  }

  getMember() {
    this.userMemberSubscription = this.userMemberService
      .getMemberByUser()
      .subscribe();
  }
  getMembership() {
    this.membershipSubscription = this.membershipApplicationService.getApplicationStatusByUserId(this.keycloakUserId).subscribe();
  }

  ngOnDestroy(): void {
    if (this.userMemberSubscription) {
      this.userMemberSubscription.unsubscribe();
    }
    if (this.membershipSubscription) {
      this.membershipSubscription.unsubscribe();
    }
  }

  goToSell(route: string) {
    if (this.activeMembership) {
      this.route.navigate([route]);
    } else {
      this.route.navigate(['/choose-service']);
    }
  }
}
