<mat-card fxLayout="row wrap" fxLayoutAlign="space-around">

    <div fxFlex="100" fxFlex.gt-xs="33.3" ngClass.xs="mt-2">
      <div fxLayout="column" fxLayoutAlign="center center">
      </div>
    </div>
    <div fxFlex="100" fxFlex.gt-xs="33.3" ngClass.xs="mt-2">
      <div fxLayout="column" fxLayoutAlign="center center">
      </div>
    </div>

    <div class="divider w-100 mt-2"></div>

    <body style="width: 100%;" >
    <table mat-table
           [dataSource]="membershipTypes | async" multiTemplateDataRows
           class="mat-elevation-z8"  >
        <ng-container matColumnDef="{{column.value1}}" *ngFor="let column of columnsToDisplay;">
            <th mat-header-cell *matHeaderCellDef style="background-color: #80808029; color:#000000;height:60px; text-align: left;  padding-top: 17px; font-size:17px;"> {{column.value2}} </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="column.value1 !== 'action'">{{element[column.value1]}}</span>
                <button *ngIf="column.value1 === 'action'" mat-raised-button class="button" size="medium" (click)="select(element.membershiptypeid)" style="border:1px solid green; color: green; margin-top: 20px;">
                    Select
                </button>

            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element"   [attr.colspan]="columnsToDisplay.length" style="height:10px;">
                <div class="example-element-detail"
                     [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="example-element-diagram">
                        <div class="example-element-name" > {{element.name}} </div>
                        <div class="example-element-symbol"  >{{element.entranceFee}} </div>
                        <div class="example-element-symbol"  > {{element.annualSubscriptionFee}}</div>
                    </div>
                    <div class="example-element-description">
                        {{element.description}}

                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay1"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay1"
            class="example-element-row"
            [class.example-expanded-row]="expandedElement === element"
            (click)="expandedElement = expandedElement === element ? null : element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>


    <!-- <div class="row header">
            <div class="col"
                style="background-color: #3A3A3A; color:#C4C4C4;height:60px; text-align: center;  padding-top: 17px;">
                Membership Category
            </div>

            <div class="col"
                style="background-color: #606060;color:#C4C4C4;height:60px; text-align: center;  padding-top: 17px; ">
                Entrance Fee
            </div>

            <div class="col"
                style="background-color: #3A3A3A;color:#C4C4C4;height:60px; text-align: center;  padding-top: 17px;">Annual
                Fee
            </div>

            <div class="col"
                style="background-color: #606060;color:#C4C4C4;height:60px; text-align: center;  padding-top: 17px;">Select
            </div>
        </div>

        <div class="row" ng-repeat="data in ctrl.data" style="text-align: center; height: 90px;"
            *ngFor="let membershipType of membershipTypes | async">
            <div class="col" style="color: #606060; border: solid 1px #ddd;  padding-top: 25px;">{{ membershipType.name }}
            </div>

            <div class="col" style="color: #606060; padding-top: 25px;"><span *ngIf="membershipType.entranceFee">Ksh.
                </span>{{  membershipType.entranceFee }}</div>

            <div class="col" style="color: #606060; padding-top: 25px;">
                Ksh. {{ membershipType.annualSubscriptionFee }}
            </div>

            <div class="col">
                <button mat-raised-button class="button" size="medium" (click)="select(membershipType.name)" style="border:1px solid green; color: green; margin-top: 20px;">
                    Select
                </button>
            </div>
        </div>-->
    </body>
  </mat-card>





























