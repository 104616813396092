<ngx-spinner bdColor="rgba(51,51,51,0.7)" size="large" color="#fff" type="ball-clip-rotate"></ngx-spinner>
<mat-list>
    <mat-list-item>
        <button *ngIf="invoiceDetail" (click)="downloadInvoice()">
            Download Payment Invoice
        </button>
    </mat-list-item>
    <br><br>
    <mat-list-item>
        <button *ngIf="paymentDetail" (click)="downloadPaymentReceipt()">
            Download Payment Receipt
        </button>
    </mat-list-item>
    <br><br>
    <mat-list-item>
        <button *ngIf="membershipCertificateDetail" (click)="downloadMembershipCertificate()">
            Download Interim Membership Certificate
        </button>
    </mat-list-item>
</mat-list>
