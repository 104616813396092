import {AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {MapsAPILoader} from '@agm/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-map-modal',
    templateUrl: './map-modal.component.html',
    styleUrls: ['./map-modal.component.scss'],
})
export class MapModalComponent implements OnInit, AfterViewInit {

    private readonly triggerElementRef: ElementRef;
    title: string;

    latitude = 1;
    longitude = 38;
    zoom = 7;
    address: string;
    private geoCoder;

    @ViewChild('search') public searchElementRef: ElementRef;


    constructor(private matDialogRef: MatDialogRef<MapModalComponent>,
                @Inject(MAT_DIALOG_DATA) data,
                private mapsAPILoader: MapsAPILoader,
                private ngZone: NgZone) {
        this.title = data.title;
        this.triggerElementRef = data.trigger;
    }

    ngAfterViewInit(): void {
        console.log('load Places Autocomplete');
        // load Places Autocomplete
        this.mapsAPILoader.load().then(() => {
            this.setCurrentLocation();
            // tslint:disable-next-line:new-parens
            this.geoCoder = new google.maps.Geocoder;

            console.log(this.searchElementRef);
            const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
            autocomplete.addListener('place_changed', () => {
                this.ngZone.run(() => {
                    // get the place result
                    const place: google.maps.places.PlaceResult = autocomplete.getPlace();

                    // verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    // set latitude, longitude and zoom
                    this.latitude = place.geometry.location.lat();
                    this.longitude = place.geometry.location.lng();
                    this.zoom = 12;
                });
            });

        });
    }

    // Get Current Location Coordinates
    private setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.zoom = 8;
                this.getAddress(this.latitude, this.longitude);
            }, (errorCallback) => {
                console.log(errorCallback);

            });
        }else {
            console.log('could not find current location');
        }
    }

    onSave() {
        const selectedCoords = {
            lat: this.latitude,
            lng: this.longitude
        };
        this.matDialogRef.close(selectedCoords);
    }

    ngOnInit(): void {

    }


    markerDragEnd(event) {
        console.log(event);
        this.latitude = event.coords.lat;
        this.longitude = event.coords.lng;
        this.getAddress(this.latitude, this.longitude);
    }

    getAddress(latitude, longitude) {
        this.geoCoder.geocode({location: {lat: latitude, lng: longitude}}, (results, status) => {
            console.log(results);
            console.log(status);
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                    this.address = results[0].formatted_address;
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }

        });
    }

    onCancel() {
        this.matDialogRef.close();
    }
}
