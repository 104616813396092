import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {FileAttachmentService} from 'src/app/common-services/common-services/file-attachment.service';
import {DocumentService} from 'src/app/common-services/document.service';
import {RegistrationService} from 'src/app/common-services/registration.service';
import {AccountType} from 'src/app/models/account-type';
import {DocumentUpload} from 'src/app/models/document-upload';
import {FileAttachment, FileAttachmentNUpload} from 'src/app/models/file-attachment';
import {FileUpload} from 'src/app/models/file-upload';
import {Storage} from '@ionic/storage';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {AttachmentType, AttachmentTypeService} from '../../membership-services/attachment-type.service';
import {COUNTY_N_SERVICE_STORAGE_KEY} from '../../../models/county-n-service';

export const FILES_STORAGE_KEY = 'my_images';
export const DOCUMENT_STORAGE_KEY = 'document';


@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit, OnDestroy {

    documents: DocumentUpload[] = [];
    @ViewChild(MatTable) table: MatTable<DocumentUpload>;
    documentServiceSubcription: Subscription;
    displayedColumns: string[] = ['fileUpload', 'type', 'action'];
    displayedColumns1: string[] = ['fileUpload', 'type'];

    public documentsForm: FormGroup;
    documentTypes: AttachmentType[] = [];
    fileAttachmentDataSource = new MatTableDataSource([]);

    registrationSubscription: Subscription;
    @Input('accountType') accountType: AccountType;
    @Input('ownerUuid') ownerUuid: string;
    fileAttachmentSubscription: Subscription;
    platform: any;

    @Output() nextTab = new EventEmitter<number>();
    @Input() selectedIndex: number;
    @Input() isDisabled = false;

    @Output() isDocumentsSaved = new EventEmitter<boolean>();


    constructor(
        public renderer: Renderer2,
        private fb: FormBuilder,
        private registrationService: RegistrationService,
        private documentService: DocumentService,
        private storage: Storage,
        private _snackBar: MatSnackBar,
        private ref: ChangeDetectorRef,
        private fileAttachmentService: FileAttachmentService,
        private attachmentTypeService: AttachmentTypeService
    ) {
    }

    ngOnInit() {
        console.log(this.accountType);
        this.buildForm();
        if (this.accountType === AccountType.INDIVIDUAL) {
             this.attachmentTypeService.getIndividualAttachmentTypes().subscribe(value => {
                 this.documentTypes = value;
            });
        }
        if (this.accountType === AccountType.COMPANY) {
            this.attachmentTypeService.getCompanyAttachmentTypes().subscribe(value => {
                this.documentTypes = value;
            });
        }
        if (this.ownerUuid) {
            this.registrationSubscription = this.registrationService
                .getValueInStorage(DOCUMENT_STORAGE_KEY)
                .subscribe((value) => {
                    if (value) {
                        this.fileAttachmentDataSource.data = value;
                    }
                });
        } else {
            console.log('loadStoredImages :: ');
            this.loadStoredImages();
        }
    }

    buildForm() {
        this.documentsForm = this.fb.group({
            fileAttachmentType: ['', Validators.required],
            file: ['', Validators.required]
        });
    }

    uploadFile() {
        if (!this.documentsForm.valid) {
            return;
        }

        const document = this.documentsForm.value;
        const file: File = document.file.files[0];
        const formData = new FormData();
        const currentName = file.name;
        const fileType = document.fileAttachmentType;
        const fileTypeName = this.documentTypes.find(value => value.id === fileType)?.name;
        formData.append('file', file, currentName);
        formData.append('fileAttachmentType', fileType);

        this.documentServiceSubcription = this.documentService
            .uploads(formData)
            .subscribe(value => {
                    const fileAtt: FileAttachment = !(value instanceof Array) ? value : value[0];
                    if (fileAtt) {
                        this.documentsForm.reset();
                        const fileAttachment: FileAttachment = {
                            fileAttachmentTypeUuid: fileAtt.fileAttachmentTypeUuid,
                            fileName: fileAtt.fileName,
                            path: fileAtt.path,
                            mimeType: fileAtt.mimeType,
                            cmisAttachmentTypeId: fileType,
                        };
                        const fileEntry: FileUpload = {
                            name: currentName,
                            fileType,
                            fileTypeName
                        };
                        const fileAttachmentNUpload: FileAttachmentNUpload = {
                            fileAttachment,
                            fileUpload: fileEntry,
                        };
                        console.log(fileAttachmentNUpload);
                        this.updateStoredImages(fileAttachmentNUpload);
                        this.presentToast('File upload complete.');
                    } else {
                        this.presentToast('File upload failed.');
                    }
                },
                (error) => {
                    this.presentToast('An error occured! Please try again.', 5000);
                });
    }

    loadStoredImages() {
        this.storage.get(FILES_STORAGE_KEY).then((files) => {
            if (files) {
                const arr: FileAttachmentNUpload[] = JSON.parse(files);
                arr.forEach((file, index, array) => {
                    this.addFileAttachmentNUpload(file);
                });
                if (this.checkDocumentsValidity()) {
                    this.registrationSubscription = this.storeFileAttachments().subscribe(value => {
                        // this.isDocumentsSaved.emit(true);
                        console.log('stored file attachments  upon loadStoredImages !!! ');
                    }, error => {});
                }
            }
        });
    }
    async presentToast(text, duration = 3000) {
        this._snackBar.open(text, 'Show', {
            duration,
        });
    }

    updateStoredImages(fileAttachmentNUpload: FileAttachmentNUpload) {
        this.storage.get(FILES_STORAGE_KEY).then((files) => {
            const arr: FileAttachmentNUpload[] = JSON.parse(files);
            if (!arr) {
                const newFiles = [fileAttachmentNUpload];
                this.storage.set(FILES_STORAGE_KEY, JSON.stringify(newFiles));
            } else {
                const index = arr.findIndex(value => value.fileUpload.fileType === fileAttachmentNUpload.fileUpload.fileType);
                if (index < 0) {
                    arr.push(fileAttachmentNUpload);
                } else {
                    arr[index] = fileAttachmentNUpload;
                }
                this.storage.set(FILES_STORAGE_KEY, JSON.stringify(arr));
            }
            this.addFileAttachmentNUpload(fileAttachmentNUpload);
        });
    }

    delete(documentUpload: DocumentUpload) {
        this.storage.get(FILES_STORAGE_KEY).then((files) => {
            const arr: FileAttachmentNUpload[] = JSON.parse(files);
            const filtered = arr.filter(
                (fileAttachmentNUpload) =>
                    fileAttachmentNUpload.fileUpload.fileType !==
                    documentUpload.fileUpload.fileType
            );
            this.storage.set(FILES_STORAGE_KEY, JSON.stringify(filtered));
            this.documents = this.documents.filter(value => value.fileUpload.fileType !== documentUpload.fileUpload.fileType);
            this.presentToast('File removed.');
        });
    }

    checkDocumentsValidity(): boolean {
        console.log(this.documentTypes);
        const requiredDocuments = this.documentTypes.filter(value => value.required === true);
        const b = requiredDocuments.every( (value, i, arr) => this.checkDocument(value, i, arr, this.documents));
        console.log('b :::::: ' + b);
        return b;
    }
    checkDocument(attachmentType: AttachmentType, index: number, arr: any[], documents: any[]): boolean {
        console.log(documents);
        const doc = documents.find(value => value.type === attachmentType.id);
        return !!doc;
    }

    save() {
        if (!this.checkDocumentsValidity()) {
            this.accountType === AccountType.COMPANY ? this.presentToast('Company PIN Certificate and Certificate of Incorporation are required !')
                : this.presentToast('National ID or Passport and PIN Certificate are required !');
            return;
        }
        this.registrationSubscription = this.storeFileAttachments().subscribe(
            (value) => {
                this.isDocumentsSaved.emit(true);
                this.presentToast('Saved!');
            },
            (error) => {
                this.presentToast('An error occured! Please try again.', 5000);
            }
        );
    }

    private storeFileAttachments(): Observable<any> {
        const fileAttachments: FileAttachment[] = [];
        this.documents.forEach(value => {
            if (value.fileAttachment) {
                fileAttachments.push(value.fileAttachment);
            }
        });
        return this.registrationService
            .setValueInStorage(DOCUMENT_STORAGE_KEY, fileAttachments);
    }

    ngOnDestroy(): void {
        if (this.registrationSubscription) {
            this.registrationSubscription.unsubscribe();
        }
        if (this.fileAttachmentSubscription) {
            this.fileAttachmentSubscription.unsubscribe();
        }
        if (this.documentServiceSubcription) {
            this.documentServiceSubcription.unsubscribe();
        }
    }

    goBack() {
        console.log(this.selectedIndex);
        if (this.selectedIndex !== 0) {
            this.nextTab.emit(--this.selectedIndex);
        }
    }

    private addFileAttachmentNUpload(fileAttachmentNUpload: FileAttachmentNUpload) {
        const index = this.documents.findIndex(value => value.type === fileAttachmentNUpload.fileUpload.fileType);
        if (index < 0) {
            const documentUpload: DocumentUpload = {
                type: fileAttachmentNUpload.fileUpload.fileType,
                uploaded: true,
                fileAttachment: fileAttachmentNUpload.fileAttachment,
                fileUpload: fileAttachmentNUpload.fileUpload,
                description: fileAttachmentNUpload.fileUpload.fileType
            };
            this.documents.push(documentUpload);
        } else {
            this.documents[index].fileAttachment = fileAttachmentNUpload.fileAttachment;
            this.documents[index].fileUpload = fileAttachmentNUpload.fileUpload;
        }
        console.log(this.documents);
        this.table.renderRows();
        this.ref.detectChanges(); // trigger change detection cycle
    }

}
