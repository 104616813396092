import { Component, OnInit, Input } from '@angular/core';
import {environment} from "../../../environments/environment";
import {Ad} from "../../admin/configs/configs-listing/configs/advert-content/advert-content";

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
  private defaultProfileImageUrl = `${environment.imagesServerPath}${environment.defaultImageName}`;
  @Input('banners') banners: Ad[] = [];

  constructor() { }

  ngOnInit() { }

  public getBanner(name){
    const index = this.banners.findIndex(value => value.banner === name);
    return this.banners[index];
  }

  public getBgImage(banner){
    const index = this.banners.findIndex(value => value.banner === banner);
    let bgImage = {
      'background-image': index >= 0 ? `url(${environment.imagesServerPath}${this.banners[index].imageUrl})` :
        `url(${this.defaultProfileImageUrl})`
    };
    return bgImage;
  }

}
