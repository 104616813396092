import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { County } from "src/app/admin/configs/models/county";
import { MembershipType } from "src/app/admin/configs/models/membership-type";
import { CountyService } from "src/app/admin/configs/services/county.service";
import { MembershipTypeService } from "src/app/admin/configs/services/membership-type.service";
import { AccountType } from "src/app/models/account-type";
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Package, PackageService} from '../membership-services/package.service';
import {Counties, CountiesService} from '../membership-services/counties.service';



const ELEMENT_DATA: MembershipType[] = [];

@Component({
  selector: "app-membership-category-packages",
  templateUrl: "./membership-category-packages.component.html",
  styleUrls: ["./membership-category-packages.component.scss"],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})



export class MembershipCategoryPackagesComponent implements OnInit {

    dataSource = ELEMENT_DATA;
  columnsToDisplay1 = ['name', 'entrancefee', 'subscriptionfee', 'action'];
  columnsToDisplay = [
    {
      value1: 'name' , value2: 'Name'
    },
    {
      value1: 'entrancefee' , value2: 'Entrance Fee'
    },
    {
      value1: 'subscriptionfee' , value2: 'Annual Subscription Fee'
    },
    {
      value1: 'action' , value2: 'Action'
    }
  ];
    expandedElement: Package | null;

  accountType: string;
  countyId: number;
  county: Counties;
  countySubscription: Subscription;
  membershipTypes: Observable<Package[]>;
  private sub: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    //private navCtrl: NavController,
    private membershipTypeService: PackageService,
    private countyService: CountiesService,
    private router: Router
  ) {}





  ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe((params) => {
      this.countyId = params["countyId"];
      this.getCounty(this.countyId);
      this.accountType = params["accountType"];
      this.membershipTypes = this.membershipTypeService.findByCounty(
        this.countyId,
       // this.accountType
      );
    });
  }

  select(membershipCategory: string) {
    if (this.accountType === AccountType.INDIVIDUAL) {
      this.router.navigate([
        "individual",
        this.county.name,
        membershipCategory,
      ]);
    }
    if (this.accountType === AccountType.COMPANY) {
      this.router.navigate(["company", this.county.name, membershipCategory]);
    }
  }

  private getCounty(countyId: number) {
    this.countySubscription = this.countyService
      .findByCountyId(countyId)
      .subscribe((value) => {
        this.county = value;
      });
  }
}
