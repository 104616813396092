import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-foreign-trade-procedure',
  templateUrl: './foreign-trade-procedure.component.html',
  styleUrls: ['./foreign-trade-procedure.component.scss']
})

export class ForeignTradeProcedureComponent implements OnInit {

  name = 'Set iframe source';
  url: string = "https://www.kenyachamber.or.ke/kncci-services/foreign-trade-procedures/";
  urlSafe: SafeResourceUrl;
  constructor( public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
