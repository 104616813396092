import {HttpClient} from '@angular/common/http';
import {
    AfterViewInit,
    Component, ElementRef,
    EventEmitter, Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {of, Subscription} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {RegistrationService} from 'src/app/common-services/registration.service';
import {
    GeoLocationDto,
    GEOLOCATION_STORAGE_KEY,
} from 'src/app/models/geo-location-dto';
import {PlaceLocation} from 'src/app/models/location';
import {environment} from 'src/environments/environment';
import {MapModalComponent} from './map-modal/map-modal.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';


@Component({
    selector: 'app-geo-location',
    templateUrl: './geo-location.component.html',
    styleUrls: ['./geo-location.component.scss'],
})
export class GeoLocationComponent implements OnInit, OnDestroy, AfterViewInit {

    geolocationDto: GeoLocationDto;
    geolocationSubscription: Subscription;
    registrationSubscription: Subscription;

    @Output() locationPick = new EventEmitter<PlaceLocation>();
    isLoading = false;
    form: any;
    @Output() nextTab = new EventEmitter<number>();
    @Input() selectedIndex: number;
    zoom = 12;


    constructor(private fb: FormBuilder,
                private _snackBar: MatSnackBar,
                private dialog: MatDialog,
                private registrationService: RegistrationService,
                private http: HttpClient) {

    }

    ngOnInit() {
        this.registrationSubscription = this.registrationService.getValueInStorage(GEOLOCATION_STORAGE_KEY)
            .subscribe(value => {
                if (value) {
                    this.isLoading = true;
                    this.geolocationDto = value;
                    this.isLoading = false;
                }
            });

    }


    ngAfterViewInit(): void {
    }

    onPickLocation(event) {
        const target = new ElementRef(event.currentTarget);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '80%';
        dialogConfig.data = {
            title: 'Pick Location ',
            trigger: target
        };

        const dialogRef = this.dialog.open(MapModalComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                console.log(data);
                const pickedLocation: PlaceLocation = {
                    lat: data.lat,
                    lng: data.lng,
                    address: null,
                    staticMapImageUrl: null
                };

                this.isLoading = true;
                // console.log(pickedLocation.lat, pickedLocation.lng);
                const geolocation: GeoLocationDto = {
                    latitude: pickedLocation.lat,
                    longitude: pickedLocation.lng
                };
                if (this.geolocationDto && this.geolocationDto.ownerUuid) {
                    geolocation.ownerUuid = this.geolocationDto.ownerUuid;
                }
                this.geolocationDto = geolocation;
                this.isLoading = false;
            }
        });
    }
    async presentToast(text, duration = 3000) {
        this._snackBar.open(text, 'Show', {
            duration,
        });
    }


    onSave() {
        if (!this.geolocationDto.longitude || !this.geolocationDto.latitude) {
            return;
        }
        console.log(this.geolocationDto);
        this.geolocationSubscription = this.registrationService.setValueInStorage(GEOLOCATION_STORAGE_KEY, this.geolocationDto)
            .subscribe(value => {
                this.presentToast('Saved!');
                this.nextTab.emit(++this.selectedIndex);
            }, error => {
                this.presentToast('An error occured! Please try again.', 5000);
            });
    }


    ngOnDestroy(): void {
        if (this.geolocationSubscription) {
            this.geolocationSubscription.unsubscribe();
        }
        if (this.registrationSubscription) {
            this.registrationSubscription.unsubscribe();
        }
    }
    goBack() {
        console.log(this.selectedIndex);
        if (this.selectedIndex !== 0) {
            this.nextTab.emit(--this.selectedIndex);
        }
    }
}
