import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";

export interface CompanyNature {
  name: string;
  id: number;
}

const headers = new HttpHeaders({
  "Content-Type": "application/json",
});

@Injectable({
  providedIn: 'root'
})
export class CompanyNaturesService {

  private url = `${environment.baseCmisServiceURL}:${environment.services.cmis1.port}${environment.services.cmis1.contextRoot}/company_nature`;

  constructor(private http: HttpClient) {}

  getAll(): Observable<CompanyNature[]> {
    const url = `${this.url}`;
    return this.http.get<CompanyNature[]>(url);
  }
}
