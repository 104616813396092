<div class="banners-container" fxLayout="column" fxLayout.gt-sm="row" *ngIf="banners.length > 0">
    <div fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="40">
        <div class="banner" fxFlex="50" fxFlex.gt-sm="67" [ngStyle]="getBgImage('HOME_BANNER_1')">
            <div class="info" >
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
<!--                    <h1 class="title" style="color: #000000">{{getBanner('HOME_BANNER_1').banner}}</h1>-->
                    <a [routerLink]="['/products', 'shop']" mat-raised-button color="primary" class="mt-2"> Shop now</a>
                </div> 
            </div> 
        </div>
        <div fxLayout="row" fxFlex="50" fxFlex.gt-sm="33">
            <div class="banner" fxFlex [ngStyle]="getBgImage('HOME_BANNER_2')">
                <div class="info" fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
<!--                      <h2 class="title" style="color: #000000">{{getBanner('HOME_BANNER_2').banner}}</h2>-->
<!--                      <h4 class="subtitle">{{getBanner(1).subtitle}}</h4>-->
                      <a [routerLink]="['/products', 'shop']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                    </div> 
                </div> 
            </div>
            <div class="banner" fxFlex [ngStyle]="getBgImage('HOME_BANNER_3')">
                <div class="info" fxFlex fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
<!--                      <h2 class="title" style="color: #000000">{{getBanner('HOME_BANNER_3').banner}}</h2>-->
<!--                      <h4 class="subtitle">{{getBanner(2).subtitle}}</h4>-->
                      <a [routerLink]="['/products', 'shop']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                    </div> 
                </div> 
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayout.gt-sm="column" fxLayout.xs="column" fxFlex="100" fxFlex.gt-sm="60">
        <div fxLayout="row" fxFlexOrder="2" fxFlexOrder.gt-sm="1" fxFlex="50" fxFlex.gt-sm="33">
            <div class="banner" fxFlex [ngStyle]="getBgImage('HOME_BANNER_4')">
                <div class="info" fxLayoutAlign="space-around center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
<!--                      <h2 class="title" style="color: #000000">{{getBanner('HOME_BANNER_4').banner}}</h2>-->
<!--                      <h4 class="subtitle">{{getBanner(3).subtitle}}</h4>-->
                      <a [routerLink]="['/products', 'shop']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                    </div>
                </div>
            </div>
            <div class="banner" fxFlex [ngStyle]="getBgImage('HOME_BANNER_5')">
                <div class="info" fxLayoutAlign="center center">
                    <div class="px-2" fxLayout="column" fxLayoutAlign="end center">
<!--                      <h2 class="title" style="color: #000000">{{getBanner('HOME_BANNER_5').banner}}</h2>-->
<!--                      <h4 class="subtitle">{{getBanner(4).subtitle}}</h4>-->
                      <a [routerLink]="['/products', 'shop']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="banner" fxFlexOrder="1" fxFlexOrder.gt-sm="2" fxFlex="50" fxFlex.gt-sm="67" [ngStyle]="getBgImage('HOME_BANNER_6')">
            <div class="info" fxLayout="column" >
                <div class="px-2" fxLayout="column" fxLayoutAlign="center center">
<!--                  <h1 class="title" style="color: #000000">{{getBanner('HOME_BANNER_6').banner}}</h1>-->
<!--                  <h3 class="subtitle">{{getBanner(5).subtitle}}</h3>-->
                  <a [routerLink]="['/products', 'shop']" mat-raised-button color="primary" class="mt-2">Shop now</a>
                </div>
            </div>
        </div>
    </div>
</div>
