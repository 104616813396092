import { Injectable } from "@angular/core";
import { send } from "process";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { ChatList } from "../products/models/chat-list";
import { ChatMessage } from "../products/models/chat-message";
import { SenderReceiver } from "../products/models/sender-receiver";
import { ChatMessageService } from "./chat-message.service";

@Injectable({
  providedIn: "root",
})
export class ReceiverService {
  pageIndex = 0;
  pageSize = 50;
  chatMessages: ChatList[] = [];
  messagesSubscription: Subscription;

  private messageSource = new BehaviorSubject<SenderReceiver>(null);
  private chatHistory = new BehaviorSubject<ChatList[]>(null);

  constructor(private chatMessageService: ChatMessageService) {}

  changeChatHistory(senderreceiver: SenderReceiver) {
    this.messageSource.next(senderreceiver);
    this.messagesSubscription = this.chatMessageService
      .getByChatUuid(senderreceiver.chatUuid)
      .subscribe((value) => {
        this.chatMessages = value;
        this.chatHistory.next(this.chatMessages);
      });
  }

  getChatHistory(): Observable<ChatList[]> {
    return this.chatHistory.asObservable();
  }

  getSenderReceiver(): Observable<SenderReceiver> {
    return this.messageSource.asObservable();
  }
}
