import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-kncci-membership',
  templateUrl: './kncci-membership.component.html',
  styleUrls: ['./kncci-membership.component.scss']
})
export class KncciMembershipComponent implements OnInit {

  name = 'Set iframe source';
  url: string = "https://www.kenyachamber.or.ke/membership/";
  urlSafe: SafeResourceUrl;


  constructor( public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
