import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";

export interface UserRepresentation {
  userName: string;
  firstName: string;
  lastName: string;
  email: string;
}


export interface KraPinRepresentation {
  tin: string;

}


export interface CompanyRegNoRepresentation {
  registrationNumber: string;

}
const headers = new HttpHeaders({
  'Content-Type': 'application/json',
});


@Injectable({
  providedIn: 'root'
})
export class UserRepresentationService {

  private url = `${environment.baseMicroServiceURL}:${environment.services.business.port}${environment.services.business.contextRoot}/user-representation-controller`;

  private url2 = `https://cmis.kenyachamber.or.ke/cmis/seam/resource/rest/marketplace/validate/registration`;
  // private url2 = `http://projects.technovation.ke:8009/cmis/seam/resource/rest/marketplace/validate/registration`;


  // private url3 = `http://projects.technovation.ke:8009/cmis/seam/resource/rest/marketplace/validate/pin`;
  private url3 = `https://cmis.kenyachamber.or.ke/cmis/seam/resource/rest/marketplace/validate/pin`;


  // private url4 = `http://projects.technovation.ke:8009/cmis/seam/resource/rest/marketplace/validate/individual`;
  private url4 = `https://cmis.kenyachamber.or.ke/cmis/seam/resource/rest/marketplace/validate/individual`;

  constructor(private http: HttpClient) { }

  searchByUsername(username: string): Observable<UserRepresentation> {
    const url = `${this.url}/user/${username}`;
    return this.http.get<UserRepresentation>(url, { headers });
  }


  searchByCompanyKrapin(tin: string): Observable<any> {
    const url = `${this.url3}?kraPin=${tin}`;
    return this.http.get<any>(url);
  }


  searchByIndividualKrapin(tin: string): Observable<any> {
    const url = `${this.url4}?pin=${tin}`;
    return this.http.get<any>(url);
  }

  searchBycompanyRegNo(registrationNumber: string): Observable<any> {
    const url = `${this.url2}?reg=${registrationNumber}`;
    return this.http.get<any>(url);
  }






}

