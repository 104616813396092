import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-copy-right-policy',
  templateUrl: './copy-right-policy.component.html',
  styleUrls: ['./copy-right-policy.component.scss']
})
export class CopyRightPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
