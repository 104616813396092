import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Member } from '../models/member';
import { mergeMap } from 'rxjs/operators';

const headers = new HttpHeaders({
  'Content-Type': 'application/json',
});

@Injectable({
  providedIn: 'root',
})
export class UserMemberService {
  private url = `${environment.baseMicroServiceURL}:${environment.services.business.port}${environment.services.business.contextRoot}/user-member-controller`;

  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService) {}

  getMemberByUser(): Observable<Member> {
    const url = `${this.url}/user`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
      mergeMap((get) => this.http.get<Member>(url, { headers: get }))
    );
  }
}
