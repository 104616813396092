<h2 mat-dialog-title>{{ title }}</h2>
<mat-dialog-content>
    <div class="form-group" style="border:solid black;">
        <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
               placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
    </div>

    <agm-map
            [latitude]="latitude"
            [longitude]="longitude"
            [zoom]="zoom" >
        <agm-marker (dragEnd)="markerDragEnd($event)" [latitude]="latitude" [longitude]="longitude"
                    [markerDraggable]="true"></agm-marker>
    </agm-map>



</mat-dialog-content>
<mat-dialog-actions align="end">
    <button (click)="onCancel()" mat-button>Cancel</button>
    <button (click)="onSave()" [disabled]="!this.longitude || !this.latitude"
            mat-button>Save</button>
</mat-dialog-actions>
