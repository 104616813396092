import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { KeycloakService } from "keycloak-angular";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Payment, PaymentDetail, PaymentPage } from "src/app/models/payment";

const headers = new HttpHeaders({
  "Content-Type": "application/json",
});

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  private url = `${environment.baseMicroServiceURL}:${environment.services.business.port}${environment.services.business.contextRoot}/payment-controller`;

  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService
  ) {}

  getAll(): Observable<Payment[]> {
    const url = `${this.url}/payment`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
      mergeMap((get) => this.http.get<Payment[]>(url, { headers: get }))
    );
  }

  getAllByPage(page, size): Observable<PaymentPage> {
    const url = `${this.url}/payment/page?page=${page}&size=${size}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
      mergeMap((get) => this.http.get<PaymentPage>(url, { headers: get }))
    );
  }

  getbyId(id: number): Observable<Payment> {
    const url = `${this.url}/payment/${id}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
      mergeMap((get) => this.http.get<Payment>(url, { headers: get }))
    );
  }

  post(sender: Payment): Observable<Payment> {
    const url = `${this.url}/payment`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
      mergeMap((get) => this.http.post<Payment>(url, sender, { headers: get }))
    );
  }

  put(sender: Payment): Observable<Payment> {
    const url = `${this.url}/payment/${sender.id}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
      mergeMap((get) => this.http.put<Payment>(url, sender, { headers: get }))
    );
  }

  delete(id: number): Observable<any> {
    const url = `${this.url}/payment/${id}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
      mergeMap((get) => this.http.delete<Payment>(url, { headers: get }))
    );
  }

  getbyApplicationNo(applicationNo: string): Observable<PaymentDetail> {
    const url = `${this.url}/payment/detail/${applicationNo}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
      mergeMap((get) => this.http.get<PaymentDetail>(url, { headers: get }))
    );
  }
}
