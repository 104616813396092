import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {DocumentService} from 'src/app/common-services/document.service';
import {RegistrationService} from 'src/app/common-services/registration.service';
import {COMPANY_DETAIL_STORAGE_KEY, CompanyDetail,} from 'src/app/models/company-detail';
import {FileAttachment, FileAttachmentNUpload} from 'src/app/models/file-attachment';
import {FileUpload} from 'src/app/models/file-upload';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DocumentUpload} from '../../../models/document-upload';
import {Capacity, CapacityService} from '../../membership-services/capacity.service';
import {Source, SourceService} from '../../membership-services/source.service';
import {CompanyNature, CompanyNaturesService} from '../../membership-services/company-nature.service';
import {CheckKraPinExists} from '../../utils/krapin-validator';
import {UserRepresentationService} from '../../../common-services/user-representation.service';
import {CheckCompanyRegistartionExists} from '../../utils/companyRegistration-validator';

@Component({
    selector: 'app-company-details',
    templateUrl: './company-details.component.html',
    styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent implements OnInit {
    companyDetailSubscription: Subscription;
    registrationSubscription: Subscription;
    documentServiceSubcription: Subscription;
    companyDetailsForm: FormGroup;
    logo: DocumentUpload;



    @Output() nextTab = new EventEmitter<number>();
    @Input() selectedIndex: number;
    @Input() isDisabled = false;

    staffs: Capacity[] = [];
    options: Source[] = [];
    natures: CompanyNature[] = [];

    constructor(
        public formBuilder: FormBuilder,
        private fb: FormBuilder,
        private _snackBar: MatSnackBar,
        private registrationService: RegistrationService,
        private documentService: DocumentService,
        private staffCapacityService: CapacityService,
        private userRepresentationService: UserRepresentationService,
        private knowUsService: SourceService,
        private companyNatureService: CompanyNaturesService,
    ) {

    }

    ngOnInit() {
        console.log('####### isDisabled  ' + this.isDisabled + ' :::::::::::');
        this.buildForm();
        this.registrationSubscription = this.registrationService
            .getValueInStorage(COMPANY_DETAIL_STORAGE_KEY)
            .subscribe((value) => {
                if (value) {
                    const companyDetail: CompanyDetail = JSON.parse(
                        JSON.stringify(value)
                    );
                    if (companyDetail.logo) {
                        this.logo = companyDetail.logo;
                    }
                    companyDetail.registrationDate = new Date(companyDetail.registrationDate);
                    this.companyDetailsForm.patchValue(companyDetail);
                }
            });

        this.staffCapacityService.getAll().subscribe((value) => {
            this.staffs = value;
        });

        this.knowUsService.getAll().subscribe((value) => {
            this.options = value;
        });

        this.companyNatureService.getAll().subscribe((value) => {
            this.natures = value;
        });
    }

    async presentToast(text, duration = 3000) {
        this._snackBar.open(text, 'Show', {
            duration: duration,
        });
    }

    save() {
        if (!this.companyDetailsForm.valid) {
            return;
        }
        this.saveForm();
    }

    saveForm() {
        const companyDetailFormValue = this.companyDetailsForm.value;

        const files: File[] = companyDetailFormValue.fileUpload?.files;
        delete companyDetailFormValue.fileUpload;
        const companyDetail: CompanyDetail = companyDetailFormValue;
        if (files?.length > 0) {
            const file: File = files[0];
            const formData = this.createFormData(file, 'LOGO');
            this.startFileUpload(formData).subscribe(value => {
                const fileAtt: FileAttachment = !(value instanceof Array) ? value : value[0];
                if (fileAtt) {
                    const fileAttachment = fileAtt;
                    const fileEntry = this.createFileEntry(formData);
                    const fileAttachmentNUpload = this.createFileAttachmentNUpload(fileAttachment, fileEntry);
                    companyDetail.logo = this.createLogo(fileAttachmentNUpload);
                    this.saveCompanyDetail(companyDetail);
                }
            }, error => {

            });
        } else {
            this.saveCompanyDetail(companyDetail);
        }
    }


    private saveCompanyDetail(companyDetail: CompanyDetail) {
        this.companyDetailSubscription = this.registrationService
            .setValueInStorage(COMPANY_DETAIL_STORAGE_KEY, companyDetail)
            .subscribe(
                (value) => {
                    this.nextTab.emit(++this.selectedIndex);
                    this.presentToast('Saved!');
                },
                (error) => {
                    this.presentToast('An error occured! Please try again.', 5000);
                }
            );
    }

    private createFileAttachmentNUpload(fileAttachment: FileAttachment, fileEntry: FileUpload) {
        const fileAttachmentNUpload: FileAttachmentNUpload = {
            fileAttachment,
            fileUpload: fileEntry,
        };
        return fileAttachmentNUpload;
    }

    private createFileEntry(formData: FormData) {
        const file = formData.get('file');
        let fileName = (file instanceof File) ? file.name : file;
        const fileEntry: FileUpload = {
            name: fileName,
            fileType: formData.get('fileAttachmentType') as string
        };
        return fileEntry;
    }

    deleteLogo() {
        // delete in backend.
        this.logo = null;
        this.companyDetailsForm.get('logo').setValue(null);
        this.companyDetailsForm.get('fileUpload').setValue(null);
        this.presentToast('File removed.');
    }


    goBack() {
        console.log(this.selectedIndex);
        if (this.selectedIndex !== 0) {
            this.nextTab.emit(--this.selectedIndex);
        }
    }

    private createFormData(file: File, fileType: string): FormData {
        const formData = new FormData();
        const currentName = file.name;
        formData.append('file', file, currentName);
        formData.append('fileAttachmentType', fileType);
        return formData;
    }

    private startFileUpload(formData: FormData): Observable<FileAttachment | FileAttachment[]> {
        return this.documentService
            .uploads(formData);
    }

    private createLogo(fileAttachmentNUpload: FileAttachmentNUpload) {
        const logo: DocumentUpload = {
            type: 'LOGO',
            description: 'COMPANY LOGO',
            uploaded: true,
            fileUpload: fileAttachmentNUpload.fileUpload,
            fileAttachment: fileAttachmentNUpload.fileAttachment
        };
        return logo;
    }

    private buildForm() {
        this.companyDetailsForm = this.fb.group({
            businessEntityUuid: [''],
            name: ['', Validators.required],
            registrationNumber: ['',
                {
                    validators: [Validators.required],
                    asyncValidators: [CheckCompanyRegistartionExists(this.userRepresentationService)],
                    updateOn: 'blur'
                }
            ],
            registrationDate: ['', Validators.required],
            tin: ['',
                {
                    validators: [Validators.required],
                    asyncValidators: [CheckKraPinExists(this.userRepresentationService)],
                    updateOn: 'blur'
                }],
            email: ['', [Validators.required, Validators.email]],
            telPhoneNumber: ['', [Validators.required, Validators.pattern('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$')]],
            mobilePhoneNumber: ['', [Validators.required, Validators.pattern('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$')]],
            postalAddress: [''],
            physicalAddress: [''],
            websiteUrl: [''],
            staffCapacityUuid: ['', Validators.required],
            hearUsFrom: ['', Validators.required],
            logo: [],
            fileUpload: []
        });
        if (this.isDisabled) {
            this.companyDetailsForm.get('registrationNumber').clearAsyncValidators();
            this.companyDetailsForm.get('tin').clearAsyncValidators();
            this.companyDetailsForm.get('registrationNumber').updateValueAndValidity();
            this.companyDetailsForm.get('tin').updateValueAndValidity();
        }
    }

    ngOnDestroy(): void {
        if (this.companyDetailSubscription) {
            this.companyDetailSubscription.unsubscribe();
        }
        if (this.registrationSubscription) {
            this.registrationSubscription.unsubscribe();
        }
        if (this.documentServiceSubcription) {
            this.documentServiceSubcription.unsubscribe();
        }
        if (this.companyDetailsForm) {
            this.companyDetailsForm.reset();
        }
    }

    getExistingKraPinErrorMessage() {
        return this.companyDetailsForm.get('tin').errors
            && this.companyDetailsForm.get('tin').hasError('ExistingTin');
    }


    getExistingCompanyRegistrationErrorMessage() {
        return this.companyDetailsForm.get('registrationNumber').errors
            && this.companyDetailsForm.get('registrationNumber').hasError('ExistingRegistrationNumber');
    }
}
