import { DocumentUpload } from "./document-upload";

export interface PersonDetail {
  businessEntityUuid: string;
  identificationNumber: string;
  tin: string;
  email: string;
  telPhoneNumber: string;
  postalAddress: string;
  physicalAddress: string;
  websiteUrl: string;
  logo?: DocumentUpload;
}

export const PERSON_DETAIL_STORAGE_KEY = "person-details";
