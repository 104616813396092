import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import { Subscription} from 'rxjs';
import {ItemListService} from 'src/app/common-services/item-list.service';
import {Chat} from '../../products/models/chat';
import {ChatList} from '../../products/models/chat-list';
import {ChatMessage} from '../../products/models/chat-message';
import {OneItem} from '../../products/models/item-list';
import {SenderReceiver} from '../../products/models/sender-receiver';
import {ChatMessageService} from '../chat-message.service';
import {ChatService} from '../chat.service';
import {ReceiverService} from '../receiver.service';
import {environment} from '../../../../environments/environment';
import {ChatDTO} from '../../products/models/chatDTO';

@Component({
    selector: 'app-chat-new',
    templateUrl: './chat-new.component.html',
    styleUrls: ['./chat-new.component.scss'],
})
export class ChatNewComponent implements OnInit, AfterViewInit {
    chatForm: FormGroup;
    chatMessageSubscription: Subscription;
    chatListSubscription: Subscription;
    chats: Chat[] = [];
    chatMessages: ChatMessage[] = [];
    @Input('selectedChat') selectedChat: ChatDTO;

    sender: string;
    receiver: string;
    pageIndex = 0;
    pageSize = 50;
    isLoading = false;
    senderReceiver: SenderReceiver;
    userName: string;
    firstName: string;
    lastName: string;
    itemUuid: string;
    itemChat: OneItem;
    productName: string;
    dataRefresher: any;
    message: string;
    chat: Chat;
    chatList: ChatList[] = [];

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private chatService: ChatService,
        private chatMessageservice: ChatMessageService,
        private sharedService: ReceiverService,
        private keycloakService: KeycloakService,
        private itemListService: ItemListService
    ) {
    }

    ngOnInit() {
        this.route.paramMap.subscribe((paramMap) => {
            this.receiver = paramMap.get('receiver');
            this.sender = paramMap.get('sender');
            this.itemUuid = paramMap.get('itemUuid');
            console.log('Sender ' + this.sender);
            console.log('Receiver ' + this.receiver);

            this.getBySenderReceiverItemUuid(
                this.sender,
                this.receiver,
                this.itemUuid
            );
        });

        this.buildForm();

        this.itemListService.oneitem(this.itemUuid).subscribe((value) => {
            this.itemChat = value;
            this.productName = this.itemChat.itemTitle;
        });
        this.sharedService.getSenderReceiver().subscribe((senderReceiver) => {
            this.senderReceiver = senderReceiver;
        });

        this.keycloakService.loadUserProfile().then((profile) => {
            this.userName = profile.email;
            this.firstName = profile.firstName;
            this.lastName = profile.lastName;
        });
    }

    ngAfterViewInit() {
        this.refreshData();
    }

    getBySenderReceiverItemUuid(
        sender: string,
        receiver: string,
        itemUuid: string
    ) {
        this.chatMessageSubscription = this.chatService
            .getBySenderReceiverItemUuid(sender, receiver, itemUuid)
            .subscribe((value) => {
                this.chat = value;

                if (this.chat) {
                    this.getMessagesByChatUuid(this.chat.uuid);
                }
            });
    }

    getMessagesByChatUuid(chatUuid: string) {
        this.chatListSubscription = this.chatMessageservice.getByChatUuid(chatUuid).subscribe((value) => {
            this.chatList = value;
        });
    }

    private buildForm() {
        this.chatForm = this.fb.group({
            id: [''],
            uuid: [''],
            message: ['', Validators.required],
        });
    }

    refreshData() {
        this.dataRefresher = setInterval(() => {
            this.getBySenderReceiverItemUuid(
                this.sender,
                this.receiver,
                this.itemUuid
            );
        }, 3000);
    }

    onSend() {
        if (!this.chatForm.valid) {
            return;
        }

        if (this.chat) {
            this.message = this.chatForm.value.message;
            this.chatForm.reset();
            const chatMessage: ChatMessage = {
                chatUuid: this.chat.uuid,
                message: this.message,
                sender: this.sender,
                receiver: this.receiver,
                sentDate: new Date('yyyy-MM-dd HH:mm:ss'),
            };
            this.chatMessageservice.post(chatMessage).subscribe((value) => {
                if (value) {
                    this.chatMessages.push(value);
                }
            });
        } else {
            this.message = this.chatForm.value.message;
            this.chatForm.reset();
            const chat: Chat = {
                sender: this.sender,
                receiver: this.receiver,
                itemUuid: this.itemUuid,
            };

            this.chatService.post(chat).subscribe((value) => {
                // this.chatForm.reset();
                if (value) {
                    this.chats.push(value);
                }

                const chatMessage: ChatMessage = {
                    chatUuid: value.uuid,
                    message: this.message,
                    sender: this.sender,
                    receiver: this.receiver,
                    sentDate: new Date('yyyy-MM-dd HH:mm:ss'),
                };

                this.chatMessageservice.post(chatMessage).subscribe((value2) => {
                    if (value2) {
                        this.chatMessages.push(value2);
                    }
                });
            });
        }
    }

    getSlot(sender: string) {
        if (this.sender === sender) {
            return 'end start';
        }
    }

    ngOnDestroy(): void {
        if (this.chatMessageSubscription) {
            this.chatMessageSubscription.unsubscribe();
        }
        if (this.chatListSubscription) {
            this.chatListSubscription.unsubscribe();
        }

        this.cancelPageRefresh();
    }

    cancelPageRefresh() {
        if (this.dataRefresher) {
            clearInterval(this.dataRefresher);
        }
    }

    getStyle(sender: string) {
        return this.sender === sender ? '' : 'background-color : #65666D; ';
    }

    getImagePath(itemImageUrl?: string) {
        itemImageUrl = itemImageUrl ? itemImageUrl : environment.defaultImageName;
        return environment.imagesServerPath + itemImageUrl;
    }
}
