import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { DecimalPipe } from "@angular/common";
import { SwiperConfigInterface } from "ngx-swiper-wrapper";
import { MatDialog } from "@angular/material/dialog";
import { ProductDialogComponent } from "./product-dialog/product-dialog.component";
import { Data, AppService } from "../../app.service";
import { Product } from "../../app.models";
import { Settings, AppSettings } from "src/app/app.settings";
import { ItemList } from "src/app/pages/products/models/item-list";
import { ItemListService } from "src/app/common-services/item-list.service";
import { Subscription } from "rxjs";
import {getImageURL} from "../../../utils/app-image-url-builder";
import {environment} from "../../../environments/environment";

@Component({
  selector: "app-products-carousel",
  templateUrl: "./products-carousel.component.html",
  styleUrls: ["./products-carousel.component.scss"],
})
export class ProductsCarouselComponent implements OnInit {
  //@Input("products") products: Array<Product> = [];
  @Input("itemLists") itemLists: ItemList[] = [];
  pageIndex = 0;
  pageSize = 20;
  
  @Output() change: EventEmitter<any> = new EventEmitter();
  public config: SwiperConfigInterface = {};
  public settings: Settings;
  itemListSubscription: Subscription;
  constructor(
    public appSettings: AppSettings,
    public appService: AppService,
    public dialog: MatDialog,
    private router: Router,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
  }


  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1,
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
        1500: {
          slidesPerView: 5,
        },
      },
    };
  }

  public openProductDialog(product) {
    let dialogRef = this.dialog.open(ProductDialogComponent, {
      data: product,
      panelClass: "product-dialog",
      direction: this.settings.rtl ? "rtl" : "ltr",
    });
    dialogRef.afterClosed().subscribe((product) => {
      if (product) {
        this.router.navigate(["/products", product.id, product.name]);
      }
    });
  }
  public changeCategory(event) {
    this.change.emit(event);
  }

  getImagePath(imageName: string) {
    return getImageURL(environment.imageConfigs.resolution.medium.width,
            environment.imageConfigs.resolution.medium.height, imageName);
  }

}
