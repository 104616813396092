import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ChatList } from "../products/models/chat-list";
import { ChatMessage, ChatMessagePage } from "../products/models/chat-message";

const headers = new HttpHeaders({
  "Content-Type": "application/json",
});

@Injectable({
  providedIn: "root",
})
export class ChatMessageService {
  private url = `${environment.baseMicroServiceURL}:${environment.services.market.port}${environment.services.market.contextRoot}/chat-message-controller`;

  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService
  ) {}

  getAll(): Observable<ChatMessage[]> {
    const url = `${this.url}/message`;
    return this.keycloakService
      .addTokenToHeader(headers)
      .pipe(
        mergeMap((get) => this.http.get<ChatMessage[]>(url, { headers: get }))
      );
  }

  getbyId(id: number): Observable<ChatMessage> {
    const url = `${this.url}/message/${id}`;
    return this.keycloakService
      .addTokenToHeader(headers)
      .pipe(
        mergeMap((get) => this.http.get<ChatMessage>(url, { headers: get }))
      );
  }

  post(sender: ChatMessage): Observable<ChatMessage> {
    const url = `${this.url}/message`;
    return this.keycloakService
      .addTokenToHeader(headers)
      .pipe(
        mergeMap((get) =>
          this.http.post<ChatMessage>(url, sender, { headers: get })
        )
      );
  }

  put(sender: ChatMessage): Observable<ChatMessage> {
    const url = `${this.url}/message/${sender.id}`;
    return this.keycloakService
      .addTokenToHeader(headers)
      .pipe(
        mergeMap((get) =>
          this.http.put<ChatMessage>(url, sender, { headers: get })
        )
      );
  }

  delete(id: number): Observable<any> {
    const url = `${this.url}/message/${id}`;
    return this.keycloakService
      .addTokenToHeader(headers)
      .pipe(
        mergeMap((get) => this.http.delete<ChatMessage>(url, { headers: get }))
      );
  }

  getByChatUuid(chatUuid: string): Observable<ChatList[]> {
    const url = `${this.url}/message?chatUuid=${chatUuid}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
      mergeMap((get) => {
        return this.http.get<ChatList[]>(url, { headers: get });
      })
    );
  }
}
