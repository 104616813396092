<mat-sidenav-container>

    <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
        <button mat-icon-button color="warn" class="close" (click)="sidenav.close()">
            <mat-icon color="warn">close</mat-icon>
        </button>
        <div class="divider"></div>
        <app-sidenav-menu [menuItems]="sidenavMenuItems" [menuParentId]="0"></app-sidenav-menu>
        <div class="divider"></div>
        <div class="text-center py-2">
            <svg class="social-icon" viewBox="0 0 24 24">
                <path
                        d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z"/>
            </svg>
            <svg class="social-icon" viewBox="0 0 24 24">
                <path
                        d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M17.71,9.33C18.19,8.93 18.75,8.45 19,7.92C18.59,8.13 18.1,8.26 17.56,8.33C18.06,7.97 18.47,7.5 18.68,6.86C18.16,7.14 17.63,7.38 16.97,7.5C15.42,5.63 11.71,7.15 12.37,9.95C9.76,9.79 8.17,8.61 6.85,7.16C6.1,8.38 6.75,10.23 7.64,10.74C7.18,10.71 6.83,10.57 6.5,10.41C6.54,11.95 7.39,12.69 8.58,13.09C8.22,13.16 7.82,13.18 7.44,13.12C7.81,14.19 8.58,14.86 9.9,15C9,15.76 7.34,16.29 6,16.08C7.15,16.81 8.46,17.39 10.28,17.31C14.69,17.11 17.64,13.95 17.71,9.33Z"/>
            </svg>
            <svg class="social-icon" viewBox="0 0 24 24">
                <path
                        d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M19.5,12H18V10.5H17V12H15.5V13H17V14.5H18V13H19.5V12M9.65,11.36V12.9H12.22C12.09,13.54 11.45,14.83 9.65,14.83C8.11,14.83 6.89,13.54 6.89,12C6.89,10.46 8.11,9.17 9.65,9.17C10.55,9.17 11.13,9.56 11.45,9.88L12.67,8.72C11.9,7.95 10.87,7.5 9.65,7.5C7.14,7.5 5.15,9.5 5.15,12C5.15,14.5 7.14,16.5 9.65,16.5C12.22,16.5 13.96,14.7 13.96,12.13C13.96,11.81 13.96,11.61 13.89,11.36H9.65Z"/>
            </svg>
        </div>
    </mat-sidenav>
    <!-- nav bar tool bar  --->
    <mat-toolbar class="mat-elevation-z2" fxLayoutAlign="center center" style="height: 30px;
    border-top: solid 1px grey;">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


        <a mat-button routerLink="/" routerLinkActive="horizontal-active-link"
           [routerLinkActiveOptions]="{exact:true}" style="font-size: 12px;">Home</a>

        <!-- <a mat-button *ngIf="isLoggedIn" [routerLink]="['/products', 'shop']" routerLinkActive="horizontal-active-link"-->
        <!--        [routerLinkActiveOptions]="{exact:true}">MARKET PLACE</a>-->
        <!--    <a mat-button *ngIf="isLoggedIn" [routerLink]="['/products', 'Agri']" routerLinkActive="horizontal-active-link"-->
        <!--        [routerLinkActiveOptions]="{exact:true}">Agri</a>-->
        <!--    <a mat-button *ngIf="isLoggedIn" [routerLink]="['/products', 'Tech']" routerLinkActive="horizontal-active-link"-->
        <!--        [routerLinkActiveOptions]="{exact:true}">Tech</a>-->
        <!--    <a mat-button *ngIf="isLoggedIn" [routerLink]="['/', 'chat-room']" routerLinkActive="horizontal-active-link"-->
        <!--        [routerLinkActiveOptions]="{exact:true}">Chat</a>-->
        <!--  <a mat-button *ngIf="isLoggedIn" [routerLink]="['/', 'choose-service']" routerLinkActive="horizontal-active-link"-->
        <!--        [routerLinkActiveOptions]="{exact:true}">Membership Application</a>-->

        <button mat-button *ngIf="isLoggedIn" [matMenuTriggerFor]="menu1" class="mat-select-arrow-wrapper" style="font-size: 12px;">Membership
        </button>
        <mat-menu #menu1="matMenu">
            <a *ngIf="!existingMembershipApplication " mat-button routerLink="/choose-service" routerLinkActive="horizontal-active-link"
               [routerLinkActiveOptions]="{exact:true}" style="font-size: 12px;">Apply</a> <br *ngIf="!existingMembershipApplication">
            <a mat-button routerLink="/#" routerLinkActive="horizontal-active-link"
               [routerLinkActiveOptions]="{exact:true}" style="font-size: 12px;">Renew </a><br>
            <a mat-button routerLink="/account/view-progress" routerLinkActive="horizontal-active-link"
               [routerLinkActiveOptions]="{exact:true}" style="font-size: 12px;">Transactions</a> <br>
            <a mat-button routerLink="/account/view-membership-profile" routerLinkActive="horizontal-active-link"
               [routerLinkActiveOptions]="{exact:true}" style="font-size: 12px;">Profile</a> <br>

        </mat-menu>


        <!--    <button mat-button *ngIf="isLoggedIn" [matMenuTriggerFor]="menu2" class="mat-select-arrow-wrapper">Certificate of origin</button>-->
        <!--    <mat-menu #menu2="matMenu">-->
        <!--         <a mat-button routerLink="/coo-operations" routerLinkActive="horizontal-active-link"-->
        <!--                [routerLinkActiveOptions]="{exact:true}">Apply</a> <br>-->
        <!--        <a mat-button routerLink="/#" routerLinkActive="horizontal-active-link"-->
        <!--               [routerLinkActiveOptions]="{exact:true}">Transactions</a>-->

        <!--    </mat-menu>-->

        <button mat-button [matMenuTriggerFor]="menu" class="mat-select-arrow-wrapper" style="font-size: 12px;">Websites</button>
        <mat-menu #menu="matMenu">
            <!--    <a mat-button routerLink="/certificate-of-origin" routerLinkActive="horizontal-active-link"
                   [routerLinkActiveOptions]="{exact:true}">Certificate Of Origin</a>-->
            <!--     <a mat-button routerLink="/coo-operations" routerLinkActive="horizontal-active-link"
                    [routerLinkActiveOptions]="{exact:true}">Coo New Form</a> <br>-->
            <a mat-button target="_blank" href="https://www.kenyachamber.or.ke/news-press/"
               routerLinkActive="horizontal-active-link"
               [routerLinkActiveOptions]="{exact:true}" style="font-size: 12px;">News And Press </a><br>
            <a mat-button target="_blank" href="https://www.kenyachamber.or.ke/membership/"
               routerLinkActive="horizontal-active-link"
               [routerLinkActiveOptions]="{exact:true}" style="font-size: 12px;">KNCCI Membership</a> <br>
            <a mat-button target="_blank" href="https://www.kenyachamber.or.ke/trade-information/"
               routerLinkActive="horizontal-active-link"
               [routerLinkActiveOptions]="{exact:true}" style="font-size: 12px;">Trade Information</a>
            <a mat-button target="_blank" href="https://www.kenyachamber.or.ke/kncci-services/foreign-trade-procedures/"
               routerLinkActive="horizontal-active-link"
               [routerLinkActiveOptions]="{exact:true}" style="font-size: 12px;">Foreign Trade Procedures</a>
            <a mat-button target="_blank" href="https://www.kenyachamber.or.ke/our-contacts/"
               routerLinkActive="horizontal-active-link"
               [routerLinkActiveOptions]="{exact:true}" style="font-size: 12px;">HQ & Country Contacts</a>
        </mat-menu>


        <button mat-button [matMenuTriggerFor]="menu3" class="mat-select-arrow-wrapper" style="font-size: 12px;">SELL</button>
        <mat-menu #menu3="matMenu">
            <a mat-button (click)="goToSell('/admin/products/add-product')" routerLinkActive="horizontal-active-link"
               [routerLinkActiveOptions]="{exact:true}" style="font-size: 12px;" style="font-size: 12px;">Add Product</a> <br>
            <a mat-button (click)="goToSell('/admin/products/product-list')" routerLinkActive="horizontal-active-link"
               [routerLinkActiveOptions]="{exact:true}" style="font-size: 12px;" style="font-size: 12px;">My Products</a>

        </mat-menu>

            <a mat-button style="font-size: 12px;" *ngIf="isLoggedIn" [routerLink]="['/', 'chat-room']" routerLinkActive="horizontal-active-link"
                [routerLinkActiveOptions]="{exact:true}">My Messages</a>

        <!-- <a mat-button routerLink="/contact" routerLinkActive="horizontal-active-link"
             [routerLinkActiveOptions]="{exact:true}">CONTACT</a>-->
        <a *ngIf="isLoggedIn && adminRole" mat-button routerLink="/admin" routerLinkActive="horizontal-active-link"
           [routerLinkActiveOptions]="{exact:true}" style="font-size: 12px;">Admin Panel</a>


        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        <div class="top-menu" style="font-size: 12px;">

            <a *ngIf="!isLoggedIn" mat-button (click)="login()">
                Sign In
                <mat-icon class="mat-icon-sm caret">login</mat-icon>
            </a>

            <a *ngIf="!isLoggedIn" (click)="register()" mat-button>
                <span>Register</span>
                <mat-icon class="mat-icon-sm caret">how_to_reg</mat-icon>
            </a>


            <div *ngIf="isLoggedIn">

                <a mat-button [matMenuTriggerFor]="accountMenu" #accountMenuTrigger="matMenuTrigger">
                    <mat-icon class="mat-icon-sm account-icon">person</mat-icon>
                    <span fxShow="false" fxShow.gt-sm>Account</span>
                    <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
                </a>
                <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before"
                          class="app-dropdown account">
            <span (mouseleave)="accountMenuTrigger.closeMenu()">
                <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">
                    <img [src]="profileUrl | async" alt="user-image" width="50">
                    <p>{{firstName}} {{lastName}}<br> <small>{{userName}} <span *ngIf="settings.rtl">&rlm;</span>
                           </small></p>
                </div>
                <div class="divider"></div>
                <a mat-menu-item routerLink="/account">
                    <mat-icon class="mat-icon-sm">settings</mat-icon>
                    <span>Account Settings</span>
                </a>


                <!--<a mat-menu-item routerLink="/">
                    <mat-icon class="mat-icon-sm">lock</mat-icon>
                    <span>Lock screen</span>
                </a>
                <a mat-menu-item routerLink="/">
                    <mat-icon class="mat-icon-sm">help</mat-icon>
                    <span>Help</span>
                </a>-->
                <div class="divider"></div>
                <a mat-menu-item (click)="logout()">
                    <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                    <span>Log Out</span>
                </a>
            </span>
                </mat-menu>
            </div>

        </div>

    </mat-toolbar>


    <!-- search bar tool bar  --->
    <mat-toolbar color="primary">
        <mat-toolbar-row fxLayoutAlign="space-between center" class="top-toolbar theme-container">
            <span fxHide="false" fxHide.gt-sm>
                <button mat-button (click)="sidenav.toggle()" class="sidenav-toggle">
                    <mat-icon>menu</mat-icon>
                </button>
            </span>
            <span fxShow="false" fxShow.gt-xs fxLayoutAlign="center center">

            </span>
<!--            <span fxShow="false" fxShow.gt-sm>{{ 'WELCOME' | translate }}</span>-->
            <app-top-menu></app-top-menu>
        </mat-toolbar-row>
        <mat-toolbar-row fxLayoutAlign="space-between center" class="logo-toolbar theme-container">

            <div fxFlex="20">
                <a class="logo" routerLink="/" (click)="closeSubMenus()"><img src="./assets/img/knccimklogo3.png"
                                                                              style="height: 35px; margin-bottom: 3px;"></a>
            </div>
            <div fxFlex fxShow="false" fxShow.gt-sm style="margin-left: 35px; border: #0c4128 solid 1px; ">

                <form style="height: 52px;" [formGroup]="form" class="search-form" fxLayout="row">
                    <button mat-raised-button [matMenuTriggerFor]="categoriesMenu"
                            #categoriesMenuTrigger="matMenuTrigger"
                            type="button"
                            class="mat-elevation-z0 categories text-truncate text-muted">{{category?.name}}
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #categoriesMenu="matMenu" [overlapTrigger]="false" xPosition="before"
                              class="categories-dropdown">
            <span (mouseleave)="categoriesMenuTrigger.closeMenu()">
                <app-category-list [categories]="categories" (change)="changeCategory($event)"></app-category-list>
            </span>
                    </mat-menu>
                    <input formControlName="itemTitle" type="text" placeholder="Search..." fxFlex>
                    <button mat-mini-fab (click)="search()" type="submit"
                            class="search-btn mat-elevation-z0 text-muted">
                        <mat-icon>search</mat-icon>
                    </button>
                </form>
            </div>
            <div fxFlex="20" fxLayout="row" fxLayoutAlign="end center">
                <div fxLayoutAlign="center center" [@.disabled]="true">

                    <mat-menu #cartMenu="matMenu" [overlapTrigger]="false" xPosition="before"
                              class="top-menu-dropdown cart-dropdown">
                        <ng-template matMenuContent>
                            <span (mouseleave)="cartMenuTrigger.closeMenu()" class="d-block">
                                <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                                    <b><span>{{appService.Data.totalCartCount}} ITEM<span
                                            *ngIf="appService.Data.totalCartCount > 1">S</span></span></b>
                                    <b><a [routerLink]="['/cart']" class="text-muted">VIEW CART</a></b>
                                </div>
                                <div class="divider mt-1"></div>
                                <div *ngIf="appService.Data.totalCartCount == 0" class="py-1 text-muted text-center">You
                                    have no items in your shopping cart.</div>
                                <mat-list>
                                    <mat-list-item *ngFor="let product of appService.Data.cartList">
                                        <h3 matLine class="text-muted"> {{product.name}} </h3>
                                        <p matLine class="text-muted">
                                            <bdi>{{product.cartCount}} x ${{product.newPrice | number : '1.2-2'}}</bdi>
                                        </p>
                                        <img [src]="product.images[0].small" alt="image">
                                        <button mat-icon-button color="warn" class="remove"
                                                (click)="remove(product);stopClickPropagate($event)" matTooltip="Remove"
                                                matTooltipPosition="before">
                                            <mat-icon class="mat-icon-sm">close</mat-icon>
                                        </button>
                                    </mat-list-item>
                                </mat-list>
                                <div *ngIf="appService.Data.cartList.length > 0" class="cart-dropdown-footer">
                                    <div class="divider mt-1"></div>
                                    <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted mt-1">
                                        <b>TOTAL:</b>
                                        <b class="new-price">${{appService.Data.totalPrice | number : '1.2-2'}}</b>
                                    </div>
                                    <div class="divider mt-1"></div>

                                </div>
                            </span>
                        </ng-template>
                    </mat-menu>
                </div>
                <div fxLayoutAlign="center center" fxHide="false" fxHide.gt-sm [@.disabled]="true">
                    <button mat-icon-button [matMenuTriggerFor]="searchMenu" #searchMenuTrigger="matMenuTrigger"
                            class="search-toggle-btn">
                        <mat-icon class="mat-icon-lg">search</mat-icon>
                    </button>
                    <mat-menu #searchMenu="matMenu" [overlapTrigger]="false" xPosition="before"
                              class="search-dropdown mat-elevation-z8">
                        <ng-template matMenuContent>
                            <!-- <form method="get" fxFlex class="search-form">
                                <button mat-raised-button [matMenuTriggerFor]="categories2Menu"
                                    #categories2MenuTrigger="matMenuTrigger" type="button"
                                    class="mat-elevation-z0 categories text-muted"
                                    (click)="stopClickPropagate($event)">{{category?.name}}<mat-icon>arrow_drop_down
                                    </mat-icon></button>
                                <mat-menu #categories2Menu="matMenu" [overlapTrigger]="false" xPosition="before"
                                    class="categories-dropdown">
                                    <ng-template matMenuContent>
                                        <span (mouseleave)="categories2MenuTrigger.closeMenu()">
                                            <mat-option (click)="categorySelect(categorizedItemList.category)"
                                                *ngFor="let categorizedItemList of categorizedItemLists"
                                                [value]="categorizedItemList.category">
                                                {{ categorizedItemList.category }}
                                            </mat-option>
                                        </span>
                                    </ng-template>
                                </mat-menu>
                                <input type="text" placeholder="Type to search..." fxFlex
                                    (click)="stopClickPropagate($event)">
                                <button mat-mini-fab (click)="search()" type="button"
                                    class="search-btn mat-elevation-z0 text-muted">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </form> -->
                        </ng-template>
                    </mat-menu>
                </div>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>


    <app-menu fxShow="false" fxShow.gt-sm></app-menu>


    <div class="theme-container main">
        <app-breadcrumb></app-breadcrumb>
        <router-outlet></router-outlet>
    </div>


    <!--    <app-options></app-options>-->

    <app-footer></app-footer>

    <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition"
         (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </div>


</mat-sidenav-container>
