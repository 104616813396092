import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {CategorizedItemList, CategorizedItemListPage, } from '../pages/products/models/categorized-item-list';
import {ItemDetail, ItemList, ItemListPage, OneItem, } from '../pages/products/models/item-list';
import {ReviewDTO} from '../pages/products/models/item-review-dto';


@Injectable({
  providedIn: 'root',
})
export class ItemListService {

  private _categorizedItemList = new BehaviorSubject<CategorizedItemList[]>([]);
  private _itemList = new BehaviorSubject<ItemList[]>([]);
  private _searchItems: BehaviorSubject<{ itemTitle: string; category?: string }> = new BehaviorSubject<{itemTitle: string, category?: string}>(null);

  private url = `${environment.baseMicroServiceURL}:${environment.services.market.port}${environment.services.market.contextRoot}/item-list-controller`;

  constructor(private http: HttpClient) {}

  get categorizedItemList(): Observable<CategorizedItemList[]> {
    return this._categorizedItemList.asObservable();
  }

  listCategorizedItems(): Observable<CategorizedItemList[]> {
    const url = `${this.url}/list`;
    return this.http.get<CategorizedItemListPage>(url).pipe(
      tap((categorizedItemPage) => {
        this._categorizedItemList.next(categorizedItemPage.content);
      }),
      map((categorizedItemPage) => {
        return categorizedItemPage.content;
      })
    );
  }

  get itemList(): Observable<ItemList[]> {
    return this._itemList.asObservable();
  }

  listItems(
    category: string,
    pageIndex: number,
    pageSize: number
  ): Observable<ItemList[]> {
    const url = `${this.url}/list/category/${category}?page=${pageIndex}&size=${pageSize}`;
    return this.http.get<ItemListPage>(url).pipe(
      tap((items) => {
        this._itemList.next(items.content);
      }),
      map((items) => {
        return items.content;
      })
    );
  }


  get getSearchItems(): Observable<{ itemTitle: string; category?: string }> {
    return this._searchItems.asObservable();
  }

  searchItems(value: { itemTitle: string; category?: string }) {
    this._searchItems.next(value);
  }

  search(name: string, category?: string): Observable<ItemList[]> {
    let url = `${this.url}/list/search/${name}`;
    url = category ? url + `?category=${category}` : url;
    return this.http.get<ItemList[]>(url).pipe(
      tap((items) => {
        this._itemList.next(items);
      }),
      map((items) => {
        return items;
      })
    );
  }

  allItems(pageIndex: number, pageSize: number, type?: string): Observable<ItemList[]> {
    let url = `${this.url}/list/all?page=${pageIndex}&size=${pageSize}`;
    url = type ? url + `&type=${type}` : url;
    console.log(url);
    return this.http.get<ItemListPage>(url).pipe(
      map((items) => {
        return items.content;
      })
    );
  }


  allItemsCounty(pageIndex: number, pageSize: number, county?: string): Observable<ItemList[]> {
    let url = `${this.url}/list/all?page=${pageIndex}&size=${pageSize}`;
    url = county ? url + `&county=${county}` : url;
    return this.http.get<ItemListPage>(url).pipe(
        map((items) => {
          return items.content;
        })
    );
  }

  oneitem(itemUuid: string): Observable<OneItem> {
    const url = `${this.url}/list/${itemUuid}`;
    return this.http.get<OneItem>(url);
  }

  getItemDetail(itemUuid: string): Observable<ItemDetail> {
    const url = `${this.url}/list/edit/${itemUuid}`;
    return this.http.get<ItemDetail>(url);
  }

  listItemReviews(itemUuid: string, page, size): Observable<ReviewDTO> {
    const url = `${this.url}/list/reviews/${itemUuid}?page=${page}&size=${size}`;
    return this.http.get<ReviewDTO>(url);
  }

}
