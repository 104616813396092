import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";


export interface CmisDuration {
    periodId: number;
    duration: number;
    durationType: string;

}

const headers = new HttpHeaders({
    "Content-Type": "application/json",
});

@Injectable({
    providedIn: 'root'
})
export class CmisDurationService {
    private url = `${environment.baseCmisServiceURL}:${environment.services.cmis1.port}${environment.services.cmis1.contextRoot}/duration`;

    constructor(private http: HttpClient) {}



    findByMembershipCategory(memberShipCategory: number ): Observable<CmisDuration[]> {
        // const url = 'http://212.22.169.22:8070/cmis/seam/resource/rest/marketplace/counties';
        // return this.http.get<Counties[]>(url);
        const url = `${this.url}/${memberShipCategory}`;
        return this.http.get<CmisDuration[]>(url);
    }
}
