import {Component, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'app-certificate-of-origin',
    templateUrl: './certificate-of-origin.component.html',
    styleUrls: ['./certificate-of-origin.component.scss']
})
export class CertificateOfOriginComponent implements OnInit {


    name = 'Set iframe source';
    url = 'https://cmis.kenyachamber.or.ke/cportal/';
    urlSafe: SafeResourceUrl;

    constructor(public sanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }

}
