import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";
import { AgmCoreModule } from "@agm/core";

import { OverlayContainer, Overlay } from "@angular/cdk/overlay";
import { MAT_MENU_SCROLL_STRATEGY } from "@angular/material/menu";
import { CustomOverlayContainer } from "./theme/utils/custom-overlay-container";
import { menuScrollStrategy } from "./theme/utils/scroll-strategy";

import { environment } from "src/environments/environment";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(
    httpClient,
    environment.url + "/assets/i18n/",
    ".json"
  );
}

/**
 * This function is used internal to get a string instance of the `<base href="" />` value from `index.html`.
 * This is an exported function, instead of a private function or inline lambda, to prevent this error:
 *
 * `Error encountered resolving symbol values statically.`
 * `Function calls are not supported.`
 * `Consider replacing the function or lambda with a reference to an exported function.`
 *
 * @param platformLocation an Angular service used to interact with a browser's URL
 * @return a string instance of the `<base href="" />` value from `index.html`
 */
export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}
import { SharedModule } from "./shared/shared.module";
import {AppRoutingModule, routes} from './app.routing';
import { AppComponent } from "./app.component";
import { PagesComponent } from "./pages/pages.component";
import { TopMenuComponent } from "./theme/components/top-menu/top-menu.component";
import { MenuComponent } from "./theme/components/menu/menu.component";
import { SidenavMenuComponent } from "./theme/components/sidenav-menu/sidenav-menu.component";
import { BreadcrumbComponent } from "./theme/components/breadcrumb/breadcrumb.component";

import { AppSettings } from "./app.settings";
import { AppService } from "./app.service";
import { AppInterceptor } from "./theme/utils/app-interceptor";
import { OptionsComponent } from "./theme/components/options/options.component";
import { FooterComponent } from "./theme/components/footer/footer.component";
import { initializer } from "src/utils/app-init";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import {APP_BASE_HREF, CommonModule, HashLocationStrategy, LocationStrategy, PlatformLocation} from '@angular/common';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { IonicStorageModule } from "@ionic/storage";
import { ChatRoomComponent } from "./pages/chat-room/chat-room.component";
import { ChooseServiceComponent } from "./pages/choose-service/choose-service.component";
import { MembershipCategoryPackagesComponent } from "./pages/membership-category-packages/membership-category-packages.component";
import { CompanyComponent } from "./pages/company/company.component";
import { IndividualComponent } from "./pages/individual/individual.component";
import { ChatMemberComponent } from "./pages/chat-room/chat-member/chat-member.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RegistrationModule } from "./pages/registration-common/registration.module";
import { ChatNewComponent } from "./pages/chat-room/chat-new/chat-new.component";
import { MatListModule } from "@angular/material/list";
import { ScrollToBottomDirective } from './pages/chat-room/chat-member/scroll-to-bottom.directive';
import { TradeInformatioComponent } from './pages/trade-informatio/trade-informatio.component';
import { KncciMembershipComponent } from './pages/kncci-membership/kncci-membership.component';
import { ForeignTradeProcedureComponent } from './pages/foreign-trade-procedure/foreign-trade-procedure.component';
import { CertificateOfOriginComponent } from './pages/certificate-of-origin/certificate-of-origin.component';
import { NewsAndPressComponent } from './pages/news-and-press/news-and-press.component';
import { ApplicationFormTestComponent } from './pages/application-form-test/application-form-test.component';
import { ApplicationFormTestViewComponent } from './pages/application-form-test-view/application-form-test-view.component';
import { CopyRightPolicyComponent } from './pages/copy-right-policy/copy-right-policy.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCMJ-QgnhOQ_9IPMx9_aqj7MclyFws03NE",
      libraries: ["places"],
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,
    AppRoutingModule,
    KeycloakAngularModule,
    CommonModule,
    NgbModule,
    IonicStorageModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    RegistrationModule,
    MatListModule,
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    TopMenuComponent,
    MenuComponent,
    SidenavMenuComponent,
    BreadcrumbComponent,
    OptionsComponent,
    FooterComponent,
    ChatRoomComponent,
    ChooseServiceComponent,
    MembershipCategoryPackagesComponent,
    CompanyComponent,
    IndividualComponent,
    ChatMemberComponent,
    ChatNewComponent,
    ScrollToBottomDirective,
    TradeInformatioComponent,
    KncciMembershipComponent,
    ForeignTradeProcedureComponent,
    CertificateOfOriginComponent,
    NewsAndPressComponent,
    ApplicationFormTestComponent,
    ApplicationFormTestViewComponent,
    CopyRightPolicyComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
  ],
  providers: [
    AppSettings,
    AppService,

    // {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    {
      provide: MAT_MENU_SCROLL_STRATEGY,
      useFactory: menuScrollStrategy,
      deps: [Overlay],
    },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation]
    }

    ],
  bootstrap: [AppComponent],
})
export class AppModule {}
