<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ---------->


<link href="//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//netdna.bootstrapcdn.com/bootstrap/3.0.0/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>
<!------ Include the above in your HEAD tag ---------->


<!DOCTYPE html>
<html class=''>

<head>
    <link href='https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700,300' rel='stylesheet'
          type='text/css'>


    <link rel='stylesheet prefetch' href='https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css'>
    <link rel='stylesheet prefetch'
          href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.2/css/font-awesome.min.css'>
</head>

<body>


<div id="frame">
    <div class="content">
        <div class="contact-profile">
            <p style="margin-left: 10px;">
                Vendor: {{itemChat.itemVendor.firstName }}&nbsp;&nbsp; {{itemChat.itemVendor.lastName}} <br>
                <img style="width: 85px !important; border-radius: 0% !important;"
                     [src]="getImagePath(itemChat?.itemImages[0])" alt="product"/> &nbsp;&nbsp;
                &nbsp;&nbsp;Product: {{itemChat?.itemTitle}} <br></p>
        </div>
        <br>
        <br>
        <div class="row">
            <div class="messages" style="width:100% !important;">
                <ul *ngFor="let message of chatList">
                    <li fxFlex [fxLayoutAlign]="getSlot(message.sender)" class="sent">
                        <img src="http://emilcarlsson.se/assets/mikeross.png" alt=""/>
                        <p [style]="getStyle(message.sender)">{{ message.message }}
                        </p>
                        {{ message.sentDate |  date:'short' }}
                    </li>

                </ul>

            </div>
        </div>
        <div class="message-input">
            <form [formGroup]="chatForm">
                <div class="wrap">
                    <input type="text" formControlName="message" placeholder="Write your message..."/>
                    <i class="fa fa-paperclip attachment" aria-hidden="true"></i>
                    <button (click)="onSend()" [disabled]="!chatForm.valid" class="submit"><i
                            class="fa fa-paper-plane" aria-hidden="true"></i></button>
                </div>
            </form>
        </div>
    </div>
</div>
</body>

</html>
