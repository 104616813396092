<div>
    <div *ngIf="geolocationDto && !isLoading">
        <agm-map [latitude]="geolocationDto.latitude" [longitude]="geolocationDto.longitude" [zoom]="zoom" [mapTypeControl]="true">
            <agm-marker [latitude]="geolocationDto.latitude" [longitude]="geolocationDto.longitude" [markerDraggable]="true"></agm-marker>
        </agm-map>
    </div>
    <br>
    <div>
        <button mat-raised-button color="primary" (click)="onPickLocation($event)" expand="block">
            Select Your Location
        </button>
    </div>


    <div fxLayout="row" class="w-100 py-2">
        <button mat-stroked-button color="primary" (click)="goBack()" >Previous</button>
        <span fxFlex>&nbsp;</span>
        <button mat-raised-button color="primary" (click)="onSave()" style="--background:#07833F;
            --padding-start: 150px;
            --padding-end: 150px;
            --padding-top: 18px;
            --padding-bottom: 18px;"
                [disabled]="!geolocationDto || !geolocationDto.longitude || !geolocationDto.latitude"
                type="submit">
            Next
        </button>
    </div>
</div>
