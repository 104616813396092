import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { KeycloakService } from "keycloak-angular";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {
  MembershipCertificate,
  MembershipCertificateDetail,
  MembershipCertificatePage,
} from "src/app/models/membership-certificate";

const headers = new HttpHeaders({
  "Content-Type": "application/json",
});

@Injectable({
  providedIn: "root",
})
export class MembershipCertificateService {
  private url = `${environment.baseMicroServiceURL}:${environment.services.business.port}${environment.services.business.contextRoot}/membership-certificate-controller`;

  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService
  ) {}

  getAll(): Observable<MembershipCertificate[]> {
    const url = `${this.url}/certificate`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
      mergeMap((get) =>
        this.http.get<MembershipCertificate[]>(url, { headers: get })
      )
    );
  }

  getAllByPage(page, size): Observable<MembershipCertificatePage> {
    const url = `${this.url}/certificate/page?page=${page}&size=${size}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
      mergeMap((get) =>
        this.http.get<MembershipCertificatePage>(url, { headers: get })
      )
    );
  }

  getbyId(id: number): Observable<MembershipCertificate> {
    const url = `${this.url}/certificate/${id}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
      mergeMap((get) =>
        this.http.get<MembershipCertificate>(url, { headers: get })
      )
    );
  }

  post(sender: MembershipCertificate): Observable<MembershipCertificate> {
    const url = `${this.url}/certificate`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
      mergeMap((get) =>
        this.http.post<MembershipCertificate>(url, sender, { headers: get })
      )
    );
  }

  put(sender: MembershipCertificate): Observable<MembershipCertificate> {
    const url = `${this.url}/certificate/${sender.id}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
      mergeMap((get) =>
        this.http.put<MembershipCertificate>(url, sender, { headers: get })
      )
    );
  }

  delete(id: number): Observable<any> {
    const url = `${this.url}/certificate/${id}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
      mergeMap((get) =>
        this.http.delete<MembershipCertificate>(url, { headers: get })
      )
    );
  }

  getbyApplicationNo(
    applicationNo: string
  ): Observable<MembershipCertificateDetail> {
    const url = `${this.url}/certificate/detail/${applicationNo}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
      mergeMap((get) =>
        this.http.get<MembershipCertificateDetail>(url, { headers: get })
      )
    );
  }
}
