<div *ngIf="itemLists" class="swiper-container" [swiper]="config">
    <div class="swiper-wrapper h-100">
        <div *ngFor="let product of itemLists" class="swiper-slide">
            <mat-card class="product-item">
                <a [routerLink]="['/products', product.itemUuid, product.itemTitle]" class="image-link">
                    <img [src]="getImagePath(product.itemImageUrl)" class="swiper-lazy"  style="height: 200px; width: 100%;" />
<!--                    <div class="swiper-lazy-preloader"></div>-->
                </a>                                                                    
                <a [routerLink]="['/products', product.itemUuid, product.itemTitle]" class="title text-truncate">
                    {{product.itemTitle}}
                </a>
                <div fxLayout="row" fxLayoutAlign="space-between center" class="prices">
                    <div fxLayout="column" fxLayoutAlign="center start">
                        <p class="new-price">KES {{product.itemPrice | number : '1.2-2'}}</p>
                    </div>
                </div>
                <div class="divider mt-2"></div>
                <div class="icons">
                </div>
            </mat-card>
        </div>
    </div>
    
    <button mat-mini-fab class="swiper-button-prev swipe-arrow">
        <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button mat-mini-fab class="swiper-button-next swipe-arrow">
        <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
</div>
