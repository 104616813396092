import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import {Observable, of} from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {UserRepresentationService} from "../../common-services/user-representation.service";

function isEmptyInputValue(value: any): boolean {
    return value === null || value.length === 0;
}


export function CheckCompanyRegistartionExists(userRepresentationService: UserRepresentationService): AsyncValidatorFn {
    let initialCompanyPin: string = "";
    return (control: AbstractControl): Promise<ValidationErrors> | Observable<ValidationErrors> => {
        if (isEmptyInputValue(control.value)) {
            return of(null);
        } else if (control.value === initialCompanyPin) {
            return of(null);
        } else {
            return userRepresentationService.searchBycompanyRegNo(control.value).pipe(
                debounceTime(1000000),
                distinctUntilChanged(),
                map(userRepExists => {
                    return (userRepExists && userRepExists.status === false) ? {ExistingRegistrationNumber: true} : null;
                })
            );
        }

    };

}
