<mat-card fxLayout="row wrap" fxLayoutAlign="space-around">
    <div fxFlex="100" fxFlex.gt-xs="100" style="border: solid 1px black">
        <div fxFlex="100" fxFlex.gt-xs="50">
            <h5>Exporters Name: </h5>
            <p>EMPIRE KENYA E.P.Z LIMITED</p>

            <h5>P.O.BOX</h5>
            <p>92130 -80102, CHANGAMWE ROAD,<br>MOMBASA,KENYA..</p>

            <h5>Tel: </h5>
            <p>254725947136</p>

            <h5>Email</h5>
            <p>info@empirekenya.com</p>

        </div>

        <div fxFlex="100" fxFlex.gt-xs="50">
            <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
                <img src="assets/kncci-logo.png" alt="logo" style="height:250px; width:250px;"/>

            </div>
            <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
                <h5>KENYA NATIONAL CHAMBER OF COMMERCE & INDUSTRY</h5>

                <p>Heritan Hse, Woodland Rd Off Argwings Kodhek Rd, Opp D.O.D Hq Hurlingham P.O. Box 47024-00100
                    Nairobi-Kenya Tel:+254 725 453 376 / 739 522 563 Email:info@kenyachamber.or.ke
                    www.kenyachamber.or.ke
                </p>


            </div>


        </div>
    </div>
    <div fxFlex="100" fxFlex.gt-xs="100" style="border: solid 1px black">
        <div fxFlex="100" fxFlex.gt-xs="50">
            <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">

                <!--<mat-form-field appearance="outline">
                    <mat-label>Select Export Type</mat-label>
                    <select matNativeControl id="my">
                        <option value="" disabled selected></option>
                        <option value="volvo">Export</option>
                        <option value="saab">Re-Export</option>
                    </select>
                </mat-form-field>-->

                <h5>2. Consignee's Details</h5> <br>
                <p>MARGINA TEA (PVT) LTD <br>
                    PLOT#A172,SMALL INDUSTRIAL <br>
                    ESTATE,AJK,MIRPUR <br>
                    PAKISTAN.TEL:0315-2177123 <br>
                    EMAIL:MARGINATEA786@GMAIL.COM</p>
            </div>
            <!--    <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
                    <mat-form-field appearance="outline">
                        <mat-label>Select Comodity</mat-label>
                        <select matNativeControl id="myu">
                            <option value="" disabled selected></option>
                            <option value="volvo">Kenyan_Tea</option>
                            <option value="saab">Others</option>
                        </select>
                    </mat-form-field>


                </div>-->
        </div>

        <div fxFlex="100" fxFlex.gt-xs="50">

            <div fxFlex="100" fxFlex.gt-sm="100" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
                <h5>3. Mode of Shipment(Water,Air,Land) </h5>
                <p>water</p>
                <!--<mat-form-field appearance="outline">
                    <mat-label>Select Mode of shipment</mat-label>
                    <select matNativeControl id="mySelectId">
                        <option value="" disabled selected></option>
                        <option value="volvo">water</option>
                        <option value="saab">air</option>
                        <option value="mercedes">road</option>
                        <option value="audi">sea</option>
                    </select>
                </mat-form-field>-->


                <h5>5. Country of Origin</h5>
                <p>Kenya</p>
                <!-- <mat-form-field appearance="outline">
                     <mat-label>Select counrty of origin</mat-label>
                     <mat-select [formControl]="toppings" multiple>
                         <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                     </mat-select>
                 </mat-form-field>-->


                <h5>7. Customs Export's Code No.</h5>
                <p>Pakistan</p>
                <!-- <mat-form-field appearance="outline">
                     <mat-label>Export Code</mat-label>
                     <input matInput>
                 </mat-form-field>-->

                <h5>9. Company Registration No.</h5>
                <p>CPR/2014/138869</p>


            </div>


            <div fxFlex="100" fxFlex.gt-sm="100" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
                <br><br>&nbsp;&nbsp;
                <!-- <mat-form-field appearance="outline">
                     <mat-label>Vessal</mat-label>
                     <input matInput>
                 </mat-form-field>-->


                <h5>6. Country of Destination </h5>
                <p>Pakistan</p>
                <!--<mat-form-field appearance="outline">
                    <mat-label>Country of Destination</mat-label>
                    <select matNativeControl id="mySelectId1">
                        <option value="" disabled selected></option>
                        <option value="volvo">Germany</option>
                        <option value="saab">Algeria</option>
                        <option value="mercedes">Morocco</option>
                        <option value="audi">Egypt</option>
                        <option value="rrrr">Korea</option>
                    </select>
                </mat-form-field>-->

                <h5>8. PIN Code</h5>
                <p>P051576350M</p>


                <h5>10. Bill of Lading / Airway Bill No.</h5>
                <p>MBABQM160121363</p>
                <!--<mat-form-field appearance="outline">
                    <mat-label>Bill of Lading</mat-label>
                    <input matInput>
                </mat-form-field>-->

            </div>

        </div>

    </div>

    <div fxFlex="100" fxFlex.gt-xs="100"
         style="border-top: solid 1px black; border-right: solid 1px black;border-left: solid 1px black;">
        <div fxFlex="100" fxFlex.gt-xs="16.6">
            <h5>11. Number of packages</h5>
            <p>220.0</p>
            <!-- <mat-form-field appearance="outline">
                 <mat-label>packages</mat-label>
                 <input matInput>
             </mat-form-field>
             <mat-form-field appearance="outline">
                 <mat-label>unit</mat-label>
                 <select matNativeControl id="test">
                     <option value="volvo">packets</option>
                     <option value="saab">cartons</option>
                     <option value="mercedes">container</option>
                     <option value="audi">boxes</option>
                 </select>
             </mat-form-field>-->
        </div>

        <div fxFlex="100" fxFlex.gt-xs="16.6">
            <h5>12. Tariff Number</h5>
            <p>0902.4090</p>
            <!--<mat-form-field appearance="outline">
                <mat-label>Tariff</mat-label>
                <input matInput>
            </mat-form-field>-->


        </div>

        <div fxFlex="100" fxFlex.gt-xs="16.6">

            <h5>13. Description of Goods</h5>
            <p>1X20 FCL CONTAINER ( MSKU5720689)
                STC 220 PACKAGES OF STRAIGHT LINE TEAS,
                PACKED IN ORIGINAL PAPER SACK , POLY BAGS,
                SINGLE GUNNY AND POLY BAGS COVER,BLACK TEA,
                NETT WEIGHT:14,960KG S GROSS WEIGHT:15,316KG S
                MFG DATE :06/2021 EXPIRY DATE :06/2024
                SHIPPING MARKS: ALAMDIN TEA/ADAG BEST QUALITY
                TEA ALAMDIN TEA/ADAG PRIME </p>
            <!--<mat-form-field class="example-full-width" appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
            </mat-form-field>-->


        </div>

        <div fxFlex="100" fxFlex.gt-xs="16.6">
            <h5>14. Gross Weight Kg</h5>
            <p>15316.0</p>
            <!--    <mat-form-field appearance="outline">
                    <mat-label>packages</mat-label>
                    <input matInput>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>unit</mat-label>
                    <select matNativeControl id="test2">
                        <option value="volvo">gram</option>
                        <option value="saab">kg</option>
                        <option value="mercedes">tonnes</option>
                    </select>
                </mat-form-field>-->


        </div>

        <div fxFlex="100" fxFlex.gt-xs="16.6">

            <h5>15. Invoice Number</h5>
            <p>KEN/0138/2021</p>
            <!--<mat-form-field appearance="outline">
                <mat-label>Invoice Number</mat-label>
                <input matInput>
            </mat-form-field>-->

        </div>

        <div fxFlex="100" fxFlex.gt-xs="16.6">

            <h5>16. Value</h5>

            <p>USD <br>

                36,189.60</p>
            <!--    <mat-form-field appearance="outline">
                    <mat-label>value</mat-label>
                    <input matInput>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>unit</mat-label>
                    <select matNativeControl id="test3">
                        <option value="volvo">USD</option>
                        <option value="saab">KES</option>
                        <option value="mercedes">EURO</option>
                    </select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Show Ammount</mat-label>
                    <select matNativeControl id="test4">
                        <option value="volvo">yes</option>
                        <option value="saab">No</option>
                    </select>
                </mat-form-field>-->
        </div>

    </div>


</mat-card>

