<!-- <div *ngFor="let category of mainCategories">
    <button mat-menu-item (click)="changeCategory($event)">
        <span>{{category.name}}</span>
    </button>

    <app-category-list [categories]="categories" (change)="changeCategory($event)">
    </app-category-list>

</div> -->
<div *ngFor="let category of categories">
    <button mat-menu-item (click)="changeCategory(category)">
        <span>{{category.name}}</span>
    </button>
    <div *ngIf="category.subCategories?.length > 0" class="sub-category">
        <app-category-list [categories]="category.subCategories" (change)="changeCategory($event)"></app-category-list>
    </div>
</div>
