import { DocumentUpload } from './document-upload';

export interface CompanyDetail {
  businessEntityUuid: string;
  name: string;
  registrationNumber: string;
  registrationDate: Date;
  tin: string;
  email: string;
  telPhoneNumber: string;
  mobilePhoneNumber: string;
  postalAddress: string;
  physicalAddress: string;
  websiteUrl: string;
  logo?: DocumentUpload;
  hearUsFrom: string;
  staffCapacity: string;
}

export const COMPANY_DETAIL_STORAGE_KEY = 'company-details';
