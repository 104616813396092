import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";

export interface AttachmentType {
  name: string;
  id: number;
  required: boolean;
}

const headers = new HttpHeaders({
  "Content-Type": "application/json",
});

@Injectable({
  providedIn: 'root'
})
export class AttachmentTypeService {
  private individual = `${environment.baseCmisServiceURL}:${environment.services.cmis1.port}${environment.services.cmis1.contextRoot}/individual/attachment`;
  private company = `${environment.baseCmisServiceURL}:${environment.services.cmis1.port}${environment.services.cmis1.contextRoot}/attachment-types`;

  constructor(private http: HttpClient) {}

  getIndividualAttachmentTypes(): Observable<AttachmentType[]> {
    const url = `${this.individual}`;
    return this.http.get<AttachmentType[]>(url);
  }

  getCompanyAttachmentTypes(): Observable<AttachmentType[]> {
    const url = `${this.company}`;
    return this.http.get<AttachmentType[]>(url);
  }
}
