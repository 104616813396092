<style>
  input:disabled {
    color: grey;
  }
</style>

<ngx-spinner bdColor="rgba(51,51,51,0.7)" size="large" color="#fff" type="ball-clip-rotate"></ngx-spinner>
<mat-card fxLayout="row wrap" fxLayoutAlign="space-around">
  <form fxLayout="row wrap" [formGroup]="countyNServicesForm">
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Location County</mat-label>
        <mat-select formControlName="locationCounty" required>
          <mat-option *ngFor="let location of locationCounties" [value]="location.id">
            <span>{{ location.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


<!--    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">-->
<!--      <mat-form-field appearance="outline" class="w-100">-->
<!--        <mat-label>Membership County</mat-label>-->
<!--        <mat-select formControlName="memberShipCounty" required>-->
<!--          <mat-option *ngFor="let memberShipCounty of memberShipCounties" [value]="memberShipCounty.id">-->
<!--            <span>{{ memberShipCounty.name }}</span>-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->
<!--    </div>-->



    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Duration</mat-label>
        <mat-select formControlName="duration" required>
          <mat-option *ngFor="let duration of durations" [value]="duration.periodId">
            <span>{{ duration.duration}} {{duration.durationType}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

<!--    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">-->
<!--      <mat-form-field appearance="outline" class="w-100">-->
<!--        <mat-label>Duration</mat-label>-->
<!--        <input formControlName="duration" matInput placeholder="Duration">-->
<!--      </mat-form-field>-->
<!--    </div>-->

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Main Sector</mat-label>
        <mat-select formControlName="mainSector" required >
          <mat-option *ngFor="let sector of mainSectors | async" [value]="sector.id">
            <span>{{ sector.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Division</mat-label>
        <mat-select formControlName="division" required >
          <mat-option *ngFor="let division of divisions | async" [value]="division.id">
            <span>{{ division.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Group</mat-label>
        <mat-select formControlName="group" required >
          <mat-option *ngFor="let group of groups | async" [value]="group.id">
            <span>{{ group.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Class</mat-label>
        <mat-select formControlName="sector"  required >
          <mat-option *ngFor="let class1 of classes | async" [value]="class1.id">
            <span>{{ class1.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>



    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Specific Products / Services</mat-label>
        <input formControlName="goodsService" matInput placeholder="Specific Goods & Services">

      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Services from KNCCI</mat-label>
        <mat-select formControlName="businessServices" multiple="true">
          <mat-option *ngFor="let service of services" [value]="service.name">
            <span>{{ service.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Date of First Registration</mat-label>
        <input formControlName="firstRegistrationDate" matInput [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker [calendarHeaderComponent]=""></mat-datepicker>
      </mat-form-field>
    </div>


    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>AccountLast Renewal Date</mat-label>
        <input formControlName="lastAccountRenewalDate" matInput [matDatepicker]="picker1">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1 [calendarHeaderComponent]=""></mat-datepicker>
      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Company Turnover</mat-label>
        <mat-select formControlName="companyTurnoverUuid">
          <mat-option *ngFor="let turnover of turnovers"  [value]="turnover.id">
            <span>{{ turnover.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field  class="example-full-width" appearance="outline" class="w-100">
        <mat-label>What Are Your Expectations</mat-label>
        <textarea matInput formControlName="expectations" placeholder="My Expectations are ......"></textarea>
      </mat-form-field>
    </div>


    <div fxLayout="row" class="w-100 py-2">
      <button mat-stroked-button color="primary" (click)="goBack()">Previous</button>
      <span fxFlex>&nbsp;</span>
      <button mat-raised-button (click)="onSubmit()" [disabled]="!countyNServicesForm.valid" color="primary"
        type="submit">
        Next
      </button>
    </div>
  </form>
</mat-card>
