export interface GeoLocationDto {
    ownerUuid?: string;

    latitude: number;

    longitude: number;
}

export interface GeoLocationWithBusinessNameDto {
    ownerUuid?: string;

    latitude: number;

    longitude: number;

    name: string;

    county: string;
}

export const GEOLOCATION_STORAGE_KEY = 'geolocation';
