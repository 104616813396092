import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
 // public lat: number = 40.678178;
 // public lng: number = -73.944158;
  // public zoom: number = 12;
  activeMembership: boolean;
  constructor(
      private route: Router,
  ) { }

  ngOnInit() { }

  subscribe(){ }



  goToSell(route: string) {
    if (this.activeMembership) {
      this.route.navigate([route]);
    } else {
      this.route.navigate(['/choose-service']);
    }
  }
}
