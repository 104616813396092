<footer class="footer" style="background-image: linear-gradient(#00873400,#212529);">

    <div flLayout="column" fxLayoutGap="20px" fxLayoutGap.xs="0px" style="    margin-top: 80px;">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.sm="0px">
            <mat-card style="flex: 1">
                <div fxFlex appearance="fill">
                    <span class="col-title" style=" color: #767676 !important;font-family:Helvetica,Arial,Sans-serif;font-size: 16px !important;">CONTACT INFORMATION</span>
                    <br>
                    <br>
                    <mat-icon style="color: #767676 !important;">location_on</mat-icon>
                    <span style="color: #767676 !important;font-family:Helvetica,Arial,Sans-serif;">Ground Floor, Heritan House, Woodlands Road,<br>&nbsp;&nbsp;&nbsp; Off Argwings Kodhek, Nairobi, Kenya</span><br><br>
                    <mat-icon style="color: #767676 !important;">call</mat-icon>&nbsp;&nbsp;&nbsp;&nbsp;
                    <span style="color: #767676 !important;font-family:Helvetica,Arial,Sans-serif;">+254777392700</span><br>&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp; <span style="color: #767676 !important;font-family:Helvetica,Arial,Sans-serif;">+254782392700</span> <br>&nbsp;&nbsp;&nbsp;&nbsp;
                    <br>
                    <mat-icon style="color: #767676 !important;">mail_outline</mat-icon>
                    &nbsp;&nbsp;&nbsp;&nbsp; <span style="color: #767676 !important;font-family:Helvetica,Arial,Sans-serif;">info@kenyachamber.or.ke</span>
                </div>
            </mat-card>


            <mat-card style="flex: 1">
                <div fxFlex  appearance="fill">
                    <span class="col-title" style="color: #767676 !important;font-family:Helvetica,Arial,Sans-serif;font-size: 16px !important;">Quick Links</span><br>

                    <a mat-button style="color: #767676 !important;font-family:Helvetica,Arial,Sans-serif;" routerLink="/products/shop" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}" >
                        <mat-icon>shopping_cart</mat-icon> &nbsp;
                        Buy </a>  <br>

                    <a mat-button style="color: #767676 !important;font-family:Helvetica,Arial,Sans-serif;" (click)="goToSell('/admin/products/add-product')" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}" >
                        <mat-icon>sell</mat-icon> &nbsp;
                        Start Selling</a> <br>

                    <a mat-button style="color: #767676 !important;font-family:Helvetica,Arial,Sans-serif;" routerLink="/choose-service" routerLinkActive="horizontal-active-link" [routerLinkActiveOptions]="{exact:true}">
                        <mat-icon> forum</mat-icon>&nbsp;
                        Membership Registration </a> <br>

                    <a mat-button style="color: #767676 !important;font-family:Helvetica,Arial,Sans-serif;" href="https://cmis.kenyachamber.or.ke/cportal" target="_blank" >
                        <mat-icon>receipt</mat-icon>&nbsp;
                        Apply for Certificate of Origin  </a> <br>

                    <a mat-button style="color: #767676 !important;font-family:Helvetica,Arial,Sans-serif;" href="https://www.kenyachamber.or.ke/" target="_blank"  >
                        <mat-icon>public</mat-icon>&nbsp;
                        Website</a>


                </div>
            </mat-card>

            <mat-card style="flex: 1">
            <div fxFlex appearance="fill">
                <agm-map [latitude]="-1.29364" [longitude]="36.79957" [zoom]="19" [mapTypeControl]="true">
                    <agm-marker [latitude]="-1.29364" [longitude]="36.79957" [markerDraggable]="true"></agm-marker>
                </agm-map>

            </div>
            </mat-card>

        </div>
    </div>


    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" style="color: white"
         class="copyright secondary-color theme-container">
        <p ngClass.xs="mt-1">Copyright © 2022 All Rights Reserved</p>
        <p>Designed & Developed by <a mat-button href="https://technovation.co.ke/" target="_blank">Technovation</a></p>
    </div>
</footer>
