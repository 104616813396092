import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AppAuthGuard } from "src/utils/app-auth-guard";
import { ChatMemberComponent } from "./pages/chat-room/chat-member/chat-member.component";
import { ChatNewComponent } from "./pages/chat-room/chat-new/chat-new.component";
import { ChatRoomComponent } from "./pages/chat-room/chat-room.component";
import { ChooseServiceComponent } from "./pages/choose-service/choose-service.component";
import { CompanyComponent } from "./pages/company/company.component";
import { IndividualComponent } from "./pages/individual/individual.component";
import { MembershipCategoryPackagesComponent } from "./pages/membership-category-packages/membership-category-packages.component";

import { TradeInformatioComponent } from './pages/trade-informatio/trade-informatio.component';
import { PagesComponent } from "./pages/pages.component";
import { ForeignTradeProcedureComponent } from "./pages/foreign-trade-procedure/foreign-trade-procedure.component";
import { KncciMembershipComponent } from "./pages/kncci-membership/kncci-membership.component";
import {NewsAndPressComponent} from "./pages/news-and-press/news-and-press.component";
import {CertificateOfOriginComponent} from "./pages/certificate-of-origin/certificate-of-origin.component";
import {ApplicationFormTestComponent} from "./pages/application-form-test/application-form-test.component";
import {PrivacyPolicyComponent} from "./pages/privacy-policy/privacy-policy.component";
import {TermsAndConditionsComponent} from "./pages/terms-and-conditions/terms-and-conditions.component";
import {CopyRightPolicyComponent} from "./pages/copy-right-policy/copy-right-policy.component";

export const routes: Routes = [
  {
    path: "",
    component: PagesComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "account",
        loadChildren: () =>
          import("./pages/account/account.module").then((m) => m.AccountModule),
        data: { breadcrumb: "Account Settings" },
      },
      {
        path: "chat-room",
        component: ChatRoomComponent,
        canActivate: [AppAuthGuard],
        // data: {
        //   roles: [],
        // },
      },
      {
        path: "trade-information",
        component: TradeInformatioComponent,
        // canActivate: [AppAuthGuard],
        // data: {
        //   roles: [],
        // },
      },
      {
        path: "foreign-trade",
        component: ForeignTradeProcedureComponent,
        // canActivate: [AppAuthGuard],
        // data: {
        //   roles: [],
        // },
      },
      {
        path: "kncci-membership",
        component: KncciMembershipComponent,
        // canActivate: [AppAuthGuard],
        // data: {
        //   roles: [],
        // },
      },
      {
        path: "news-and-press",
        component: NewsAndPressComponent,
        // canActivate: [AppAuthGuard],
        // data: {
        //   roles: [],
        // },
      },
      {
        path: "certificate-of-origin",
        component: CertificateOfOriginComponent,
        // canActivate: [AppAuthGuard],
        // data: {
        //   roles: [],
        // },
      },
      {
        path: "coo-operations",
        component: ApplicationFormTestComponent,
        // canActivate: [AppAuthGuard],
        // data: {
        //   roles: [],
        // },
      },

      {
        path: "chat-new",
        component: ChatNewComponent,
        // canActivate: [AppAuthGuard],
        // data: {
        //   roles: [],
        // },
      },

      {
        path: "company/:memberShipCategoryCounty/:memberShipCategory",
        component: CompanyComponent,
        canActivate: [AppAuthGuard]
      },

      {
        path: "individual/:memberShipCategoryCounty/:memberShipCategory",
        component: IndividualComponent,
        canActivate: [AppAuthGuard]
      },

      {
        path: "chat-member",
        component: ChatMemberComponent,
        canActivate: [AppAuthGuard],
        data: {
          roles: [],
        },
      },

      {
        path: "chat-member/:sender/:receiver/:itemUuid",
        component: ChatNewComponent,
        canActivate: [AppAuthGuard],
        data: {
          roles: [],
        },
      },


      {
        path: "choose-service",
        component: ChooseServiceComponent,
        canActivate: [AppAuthGuard]
      },
      {
        path: "membership-category-packages/:countyId/:accountType",
        component: MembershipCategoryPackagesComponent,
        canActivate: [AppAuthGuard]
      },

      {
        path: "contact",
        loadChildren: () =>
          import("./pages/contact/contact.module").then((m) => m.ContactModule),
        data: { breadcrumb: "Contact" },
      },
      {
        path: "registration",
        loadChildren: () =>
          import("./pages/registration-common/registration.module").then(
            (m) => m.RegistrationModule
          ),
      },
      {
        path: "sign-up",
        loadChildren: () =>
          import("./pages/sign-in/sign-in.module").then((m) => m.SignInModule),
        data: { breadcrumb: "Sign In " },
      },
      {
        path: "products",
        loadChildren: () =>
          import("./pages/products/products.module").then(
            (m) => m.ProductsModule
          ),
        data: { breadcrumb: "All Products" },
      },
    ],
  },
  //{ path: 'landing', loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule) },
  {
    path: "admin",
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
  },

  {
    path: "chat",
    component: ChatRoomComponent,
  },
  {
    path: "copy-right-policy",
    component: CopyRightPolicyComponent,
  },
  {
    path: "terms-and-conditions",
    component: TermsAndConditionsComponent,
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
      relativeLinkResolution: "legacy",
      initialNavigation: "enabled", // for one load page, without reload
      // useHash: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
