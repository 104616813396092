<style>
  input:read-only {
    color: grey;
  }
</style>
<ngx-spinner bdColor="rgba(51,51,51,0.7)" size="large" color="#fff" type="ball-clip-rotate"></ngx-spinner>
<mat-card fxLayout="row wrap" fxLayoutAlign="space-around">
  <form [formGroup]="personalDetailsForm"  fxLayout="row wrap">
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>ID / Passport No</mat-label>
        <input formControlName="identificationNumber" matInput placeholder="ID / Passport No" [readonly]="isDisabled">
      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Kra Pin Number</mat-label>
        <input formControlName="tin" matInput placeholder="Pin No" [readonly]="isDisabled">

        <mat-error *ngIf="getExistingKraPinErrorMessage()">
          KRA Pin already exists !!
        </mat-error>
      </mat-form-field>
    </div>


    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Business Email</mat-label>
        <input matInput formControlName="email" placeholder="Biz Email" required>
        <mat-error
                *ngIf="personalDetailsForm.get('email').hasError('required') || personalDetailsForm.get('email').touched">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Business Telephone Number</mat-label>
        <input matInput formControlName="telPhoneNumber" placeholder="Biz Tel" required>
        <mat-error
                *ngIf="personalDetailsForm.get('telPhoneNumber').hasError('required') || personalDetailsForm.get('telPhoneNumber').touched">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Postal Address</mat-label>
        <input formControlName="postalAddress" matInput placeholder="Postal Address" required>
      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Physical Address</mat-label>
        <input formControlName="physicalAddress" matInput placeholder="Physical Address" required>
      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Website Url</mat-label>
        <input formControlName="websiteUrl" matInput placeholder="Website Url" required>
      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1" *ngIf="!logo">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label> Upload Company Logo</mat-label>
        <ngx-mat-file-input [value]="" #removableInput formControlName="fileUpload" placeholder="Click to Select Logo">
        </ngx-mat-file-input>
        <button (click)="removableInput.clear($event)" *ngIf="!removableInput.empty" mat-icon-button
                matSuffix>
          <mat-icon>clear</mat-icon>
        </button>
        <mat-icon matSuffix>folder</mat-icon>
      </mat-form-field>
    </div>

    <div *ngIf="logo">
      <p>Logo: {{ logo.fileUpload.name }}
        <span (click)="deleteLogo()" class="material-icons">delete</span>
      </p>
    </div>


    <div fxLayout="row" class="w-100 py-2">
      <button mat-stroked-button color="primary" (click)="goBack()">Previous</button>
      <span fxFlex>&nbsp;</span>
      <button mat-raised-button color="primary" (click)="save()" [disabled]="!personalDetailsForm.valid" type="submit">
        Next
      </button>
    </div>
  </form>

</mat-card>
