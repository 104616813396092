import {Injectable} from '@angular/core';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot,} from '@angular/router';
import {environment} from '../environments/environment';
import {KeycloakLoginOptions} from 'keycloak-js';

@Injectable({
  providedIn: "root",
})

export class AppAuthGuard extends KeycloakAuthGuard {
  constructor(
    protected router: Router,
    protected keycloakAngular: KeycloakService
  ) {
    super(router, keycloakAngular);
  }

  isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      console.log(
        "::::::::::::::::: Authenticated " +
          this.authenticated +
          ":::::::::::::::::::::::"
      );
      if (!this.authenticated) {
        const url = environment.loginRedirectUri;
        const options: KeycloakLoginOptions = {
          redirectUri: url,
        };
        this.keycloakAngular.login(options);
        return;
      }

      const requiredRoles = route.data.roles;
      if (!requiredRoles || requiredRoles.length === 0) {
        return resolve(true);
      } else {
        if (!this.roles || this.roles.length === 0) {
          resolve(false);
        }
        let granted = false;
        for (const requiredRole of requiredRoles) {
          if (this.roles.indexOf(requiredRole) > -1) {
            granted = true;
            break;
          }
        }
        resolve(granted);
      }
    });
  }
}
