<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ---------->

<link href="//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//netdna.bootstrapcdn.com/bootstrap/3.0.0/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>
<!------ Include the above in your HEAD tag ---------->


<!DOCTYPE html>
<html class=''>

<head>
    <link href='https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700,300' rel='stylesheet'
          type='text/css'>


    <link rel='stylesheet prefetch' href='https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css'>
    <link rel='stylesheet prefetch'
          href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.2/css/font-awesome.min.css'>
</head>

<body>

<ngx-spinner [name]="spinner1"></ngx-spinner>


<div id="frame">
    <div id="sidepanel">
        <div id="profile">
            <div class="wrap">
                <img id="profile-img" [src]="profileUrl | async" class="online" alt=""/>
                <p>{{firstName}} {{lastName}}</p>

            </div>
        </div>

        <div id="contacts">
            <ul *ngFor="let message of chats"
                (click)="chatClicked(message.receiver, message.sender, message.product, message.chatUuid)">
                <li class="contact" (click)="onSelect(message)" [class.selected]="message === selectedChat">
                    <div class="wrap">
                        <span class="contact-status online"></span>
                        <img src="http://emilcarlsson.se/assets/louislitt.png" alt=""/>
                        <div class="meta">
                            <p class="name">{{ message.name}}</p>
                            <p>Product:{{message.productName}}</p>
                            <p class="preview">{{ message.message}}</p>
                        </div>
                    </div>
                </li>

            </ul>
        </div>
        <div id="bottom-bar">
            <!-- <button id="addcontact"><i class="fa fa-user-plus fa-fw" aria-hidden="true"></i> <span>Add
                    contact</span></button>
            <button id="settings"><i class="fa fa-cog fa-fw" aria-hidden="true"></i> <span>Settings</span></button> -->
        </div>
    </div>
    <div>
        <app-chat-member [selectedChat]="selectedChat"></app-chat-member>

    </div>
</div>
</body>

</html>
