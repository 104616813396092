import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { KeycloakService } from "keycloak-angular";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Chat } from "../products/models/chat";
import { ChatDTO } from "../products/models/chatDTO";

const headers = new HttpHeaders({
  "Content-Type": "application/json",
});

@Injectable({
  providedIn: "root",
})
export class ChatService {
  private url = `${environment.baseMicroServiceURL}:${environment.services.market.port}${environment.services.market.contextRoot}/chat-controller`;

  constructor(
    private http: HttpClient,
    private keycloakService: KeycloakService
  ) {}

  getAll(): Observable<Chat[]> {
    const url = `${this.url}/chat`;
    return this.keycloakService
      .addTokenToHeader(headers)
      .pipe(mergeMap((get) => this.http.get<Chat[]>(url, { headers: get })));
  }

  getbyId(id: number): Observable<Chat> {
    const url = `${this.url}/chat/${id}`;
    return this.keycloakService
      .addTokenToHeader(headers)
      .pipe(mergeMap((get) => this.http.get<Chat>(url, { headers: get })));
  }

  post(sender: Chat): Observable<Chat> {
    const url = `${this.url}/chat`;
    return this.keycloakService
      .addTokenToHeader(headers)
      .pipe(
        mergeMap((get) => this.http.post<Chat>(url, sender, { headers: get }))
      );
  }

  put(sender: Chat): Observable<Chat> {
    const url = `${this.url}/chat/${sender.id}`;
    return this.keycloakService
      .addTokenToHeader(headers)
      .pipe(
        mergeMap((get) => this.http.put<Chat>(url, sender, { headers: get }))
      );
  }

  delete(id: number): Observable<any> {
    const url = `${this.url}/chat/${id}`;
    return this.keycloakService
      .addTokenToHeader(headers)
      .pipe(mergeMap((get) => this.http.delete<Chat>(url, { headers: get })));
  }

  getBySenderReceiverItemUuid(sender: string, receiver: string, itemUuid: string): Observable<Chat> {
    const url = `${this.url}/chat?sender=${sender}&receiver=${receiver}&itemUuid=${itemUuid}`;
    return this.keycloakService
      .addTokenToHeader(headers)
      .pipe(mergeMap((get) => this.http.get<Chat>(url, { headers: get })));
  }

  getMemberChats(): Observable<ChatDTO[]> {
    const url = `${this.url}/chat/member`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
        mergeMap((get) => {
          return this.http.get<ChatDTO[]>(url, { headers: get });
        })
    );
  }
}
