import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { KeycloakService } from "keycloak-angular";
import { KeycloakLoginOptions } from "keycloak-js";
import { Observable, Subscription } from "rxjs";
import { BusinessProfileService } from "src/app/common-services/common-services/business-profile.service";
import { RegistrationService } from "src/app/common-services/registration.service";
import { environment } from "src/environments/environment";
import { AppService } from "../../../app.service";
import { Settings, AppSettings } from "../../../app.settings";

@Component({
  selector: "app-top-menu",
  templateUrl: "./top-menu.component.html",
})
export class TopMenuComponent implements OnInit {
  public settings: Settings;
  isLoggedIn = false;
  profileSubscription: Subscription;
  profileUrl: Observable<string>;
  userName: string;
  firstName: string;
  lastName: string;
  constructor(
    public appSettings: AppSettings,
    public appService: AppService,
    public translateService: TranslateService,
    private keycloakService: KeycloakService,
    private businessProfileService: BusinessProfileService,
    private registrationService: RegistrationService
  ) {
    this.settings = this.appSettings.settings;
  }

  async ngOnInit() {
    this.isLoggedIn = await this.keycloakService.isLoggedIn();
    if (this.isLoggedIn == true) {
      this.getProfileLogo();
      this.profileUrl = this.businessProfileService.profileUrl;
      this.keycloakService.loadUserProfile().then((profile) => {
        this.userName = profile.email;
        this.firstName = profile.firstName;
        this.lastName = profile.lastName;
      });
    }
  }

  private getProfileLogo() {
    this.profileSubscription = this.businessProfileService
      .getLogo()
      .subscribe();
  }

  login() {
    const url = environment.loginRedirectUri;
    const options: KeycloakLoginOptions = {
      redirectUri: url,
    };
    this.keycloakService
      .login(options)
      .then(() => {
        console.log("login successful");
      })
      .catch((reason) => {
        console.log(JSON.stringify(reason));
      });
  }

  async logout() {
    if (await this.keycloakService.isLoggedIn()) {
      this.registrationService.clear().subscribe((value) => {
        console.log(value);
        this.keycloakService.logout(environment.logoutRedirectUri);
      });
    }
  }
}
