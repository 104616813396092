import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {KeycloakService} from "keycloak-angular";
import {Observable} from "rxjs";
import {mergeMap} from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { Invoice, InvoiceDetail, InvoicePage } from 'src/app/models/invoice';

const headers = new HttpHeaders({
  'Content-Type': 'application/json'
});

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  private url =
      `${environment.baseMicroServiceURL}:${environment.services.business.port}${environment.services.business.contextRoot}/invoice-controller`;

  constructor(private http: HttpClient, private keycloakService: KeycloakService) {


  }

  getAll(): Observable<Invoice[]> {
    const url = `${this.url}/invoice`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
        mergeMap((get) => this.http.get<Invoice[]>(url, {headers: get})
        )
    );
  }

  getAllByPage(page, size): Observable<InvoicePage> {
    const url = `${this.url}/invoice/page?page=${page}&size=${size}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
        mergeMap((get) => this.http.get<InvoicePage>(url, {headers: get})
        )
    );
  }

  getbyId(id: number): Observable<Invoice> {
    const url = `${this.url}/invoice/${id}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
        mergeMap((get) => this.http.get<Invoice>(url, {headers: get})
        )
    );
  }

  post(sender: Invoice): Observable<Invoice> {
    const url = `${this.url}/invoice`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
        mergeMap((get) => this.http.post<Invoice>(url, sender, {headers: get})
        )
    );
  }

  put(sender: Invoice): Observable<Invoice> {
    const url = `${this.url}/invoice/${sender.id}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
        mergeMap((get) => this.http.put<Invoice>(url, sender, {headers: get})
        )
    );
  }

  delete(id: number): Observable<any> {
    const url = `${this.url}/invoice/${id}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
        mergeMap((get) => this.http.delete<Invoice>(url, {headers: get})
        )
    );
  }
  getbyApplicationNo(applicationNo: string): Observable<InvoiceDetail> {
    const url = `${this.url}/invoice/detail/${applicationNo}`;
    return this.keycloakService.addTokenToHeader(headers).pipe(
        mergeMap((get) => this.http.get<InvoiceDetail>(url, {headers: get})
        )
    );
  }

}
