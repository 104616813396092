<mat-card fxLayout="row wrap" fxLayoutAlign="space-around">
    <div fxFlex="100" fxFlex.gt-xs="100" style="border: solid 1px black">
        <div fxFlex="100" fxFlex.gt-xs="50">
            <h5>Exporters Name: </h5>
            <p>EMPIRE KENYA E.P.Z LIMITED</p>

            <h5>P.O.BOX</h5>
            <p>92130 -80102, CHANGAMWE ROAD,<br>MOMBASA,KENYA..</p>

            <h5>Tel: </h5>
            <p>254725947136</p>

            <h5>Email</h5>
            <p>info@empirekenya.com</p>

        </div>

        <div fxFlex="100" fxFlex.gt-xs="50">
            <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
                <img src="assets/kncci-logo.png" alt="logo" style="height:250px; width:250px;"/>

            </div>
            <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
                <h5>KENYA NATIONAL CHAMBER OF COMMERCE & INDUSTRY</h5>

                <p>Heritan Hse, Woodland Rd Off Argwings Kodhek Rd, Opp D.O.D Hq Hurlingham P.O. Box 47024-00100
                    Nairobi-Kenya Tel:+254 725 453 376 / 739 522 563 Email:info@kenyachamber.or.ke
                    www.kenyachamber.or.ke
                </p>


            </div>


        </div>
    </div>
    <div fxFlex="100" fxFlex.gt-xs="100" style="border: solid 1px black">
        <div fxFlex="100" fxFlex.gt-xs="50">
            <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">

                <mat-form-field appearance="outline">
                    <mat-label>Select Export Type</mat-label>
                    <select matNativeControl id="my">
                        <option value="" disabled selected></option>
                        <option value="volvo">Export</option>
                        <option value="saab">Re-Export</option>
                    </select>
                </mat-form-field>

                <h5>2. Consignee's Details</h5> <br>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Consignee's Details</mat-label>
                    <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
                </mat-form-field>
            </div>
            <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
                <mat-form-field appearance="outline">
                    <mat-label>Select Comodity</mat-label>
                    <select matNativeControl id="myu">
                        <option value="" disabled selected></option>
                        <option value="volvo">Kenyan_Tea</option>
                        <option value="saab">Others</option>
                    </select>
                </mat-form-field>


            </div>
        </div>

        <div fxFlex="100" fxFlex.gt-xs="50">

            <div fxFlex="100" fxFlex.gt-sm="100" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
                <h5>3. Mode of Shipment(Water,Air,Land) </h5>
                <mat-form-field appearance="outline">
                    <mat-label>Select Mode of shipment</mat-label>
                    <select matNativeControl id="mySelectId">
                        <option value="" disabled selected></option>
                        <option value="volvo">water</option>
                        <option value="saab">air</option>
                        <option value="mercedes">road</option>
                        <option value="audi">sea</option>
                    </select>
                </mat-form-field>


                <h5>5. Country of Origin</h5>
                <mat-form-field appearance="outline">
                    <mat-label>Select counrty of origin</mat-label>
                    <mat-select [formControl]="toppings" multiple>
                        <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                    </mat-select>
                </mat-form-field>


                <h5>7. Customs Export's Code No.</h5>
                <mat-form-field appearance="outline">
                    <mat-label>Export Code</mat-label>
                    <input matInput>
                </mat-form-field>

                <h5>9. Company Registration No.</h5>
                <p>CPR/2014/138869</p>


            </div>


            <div fxFlex="100" fxFlex.gt-sm="100" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
                <br><br>&nbsp;&nbsp;
                <mat-form-field appearance="outline">
                    <mat-label>Vessel</mat-label>
                    <input matInput>
                </mat-form-field>


                <h5>6. Country of Destination </h5>
                <mat-form-field appearance="outline">
                    <mat-label>Country of Destination</mat-label>
                    <select matNativeControl id="mySelectId1">
                        <option value="" disabled selected></option>
                        <option value="volvo">Germany</option>
                        <option value="saab">Algeria</option>
                        <option value="mercedes">Morocco</option>
                        <option value="audi">Egypt</option>
                        <option value="rrrr">Korea</option>
                    </select>
                </mat-form-field>

                <h5>8. PIN Code</h5>
                <p>P051576350M</p>


                <h5>10. Bill of Lading / Airway Bill No.</h5>
                <mat-form-field appearance="outline">
                    <mat-label>Bill of Lading</mat-label>
                    <input matInput>
                </mat-form-field>

            </div>

        </div>

    </div>
    <form [formGroup]="form">


        <div fxFlex="100" fxFlex.gt-xs="100"
             style="border-top: solid 1px black; border-right: solid 1px black;border-left: solid 1px black;">
            <div fxFlex="100" fxFlex.gt-xs="16.6">
                <h5>11. Number of packages</h5>
                <mat-form-field appearance="outline">
                    <mat-label>packages</mat-label>
                    <input matInput formControlName="package">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>unit</mat-label>
                    <select matNativeControl id="test" formControlName="unit">
                        <option value="packets">packets</option>
                        <option value="cartons">cartons</option>
                        <option value="container">container</option>
                        <option value="boxes">boxes</option>
                    </select>
                </mat-form-field>
            </div>

            <div fxFlex="100" fxFlex.gt-xs="16.6">
                <h5>12. Tariff Number</h5>
                <mat-form-field appearance="outline">
                    <mat-label>Tariff</mat-label>
                    <input matInput formControlName="tariff">
                </mat-form-field>


            </div>

            <div fxFlex="100" fxFlex.gt-xs="16.6">

                <h5>13. Description of Goods</h5>
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea formControlName="description" matInput placeholder="Ex. It makes me feel..."></textarea>
                </mat-form-field>


            </div>

            <div fxFlex="100" fxFlex.gt-xs="16.6">
                <h5>14. Gross Weight</h5>
                <mat-form-field appearance="outline">
                    <mat-label>packages</mat-label>
                    <input matInput formControlName="packages">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>unit</mat-label>
                    <select matNativeControl id="test2" formControlName="unit2">
                        <option value="gram">gram</option>
                        <option value="kg">kg</option>
                        <option value="tonnes">tonnes</option>
                    </select>
                </mat-form-field>


            </div>

            <div fxFlex="100" fxFlex.gt-xs="16.6">

                <h5>15. Invoice Number</h5>
                <mat-form-field appearance="outline">
                    <mat-label>Invoice Number</mat-label>
                    <input matInput formControlName="invoice">
                </mat-form-field>

            </div>

            <div fxFlex="100" fxFlex.gt-xs="16.6">

                <h5>16. Value</h5>
                <mat-form-field appearance="outline">
                    <mat-label>value</mat-label>
                    <input matInput formControlName="value">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>unit</mat-label>
                    <select matNativeControl id="test3" formControlName="unit3">
                        <option value="USD">USD</option>
                        <option value="KES">KES</option>
                        <option value="EURO">EURO</option>
                    </select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Show Ammount</mat-label>
                    <select matNativeControl id="test4" formControlName="show">
                        <option value="yes">yes</option>
                        <option value="No">No</option>
                    </select>
                </mat-form-field>

                <button mat-raised-button color="primary" (click)="onSubmit()" style="margin: 20px">Submit</button>
            </div>

        </div>
    </form>

    <div fxFlex="100" fxFlex.gt-xs="100" style="border: solid 1px black">


        <mat-card>

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                   style="margin-left: 10px;margin-right: 10px;">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->


                <!-- Name Column -->
                <ng-container matColumnDef="package">
                    <th mat-header-cell *matHeaderCellDef> Number Of Packets</th>
                    <td mat-cell *matCellDef="let element"> {{element.package}} {{element.unit}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="tariff">
                    <th mat-header-cell *matHeaderCellDef> Tariff Number</th>
                    <td mat-cell *matCellDef="let element"> {{element.tariff}} </td>
                </ng-container>


                <!-- Name Column -->
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef> Description Of Goods</th>
                    <td mat-cell *matCellDef="let element"> {{element.description}} </td>
                </ng-container>


                <!-- Name Column -->
                <ng-container matColumnDef="packages">
                    <th mat-header-cell *matHeaderCellDef> Gross Weight</th>
                    <td mat-cell *matCellDef="let element"> {{element.packages}} {{element.unit2}} </td>
                </ng-container>


                <!-- Name Column -->
                <ng-container matColumnDef="invoice">
                    <th mat-header-cell *matHeaderCellDef> Invoice Number</th>
                    <td mat-cell *matCellDef="let element"> {{element.invoice}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef> Value</th>
                    <td mat-cell *matCellDef="let element"> {{element.value}} {{element.unit3}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </mat-card>
    </div>

    <div fxFlex="100" fxFlex.gt-xs="100" style="border: solid 1px black; height: 100px;">
        <div fxFlex="40" fxFlex.gt-xs="50">
            <mat-label>Document Upload</mat-label>
            <br>
            <button mat-stroked-button (click)="showUploadDialog()">
                Click to Select File
            </button>
            <input type="file" #fileUpload style="visibility: hidden"/>

        </div>

    </div>


    <div fxFlex="100" fxFlex.gt-xs="100"
         style="border-bottom: solid 1px black; border-right: solid 1px black;border-left: solid 1px black; ">

        <div fxFlex="100" fxFlex.gt-xs="25">
            <button mat-raised-button color="basic" (click)="add()" style="margin: 20px">Preview</button>
        </div>
        <div fxFlex="100" fxFlex.gt-xs="25">
            <button mat-raised-button color="accent" style="margin: 20px">Save</button>
        </div>
        <div fxFlex="100" fxFlex.gt-xs="25">
            <button mat-raised-button color="primary" style="margin: 20px">Submit</button>

        </div>
        <div fxFlex="100" fxFlex.gt-xs="25">
            <button mat-raised-button color="warn" style="margin: 20px">Cancel</button>

        </div>
    </div>
</mat-card>

