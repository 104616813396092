import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import {NgxSpinnerService} from 'ngx-spinner';
import {Observable, Subscription} from 'rxjs';
import {BusinessProfileService} from 'src/app/common-services/common-services/business-profile.service';
import {ChatDTO} from '../products/models/chatDTO';
import {SenderReceiver} from '../products/models/sender-receiver';
import {ChatService} from './chat.service';
import {ReceiverService} from './receiver.service';

@Component({
    selector: 'app-chat-room',
    templateUrl: './chat-room.component.html',
    styleUrls: ['./chat-room.component.scss'],
})
export class ChatRoomComponent implements OnInit {
    chats: ChatDTO[] = [];
    pageIndex = 0;
    pageSize = 20;
    chatSubscription: Subscription;
    isLoading = false;
    profileUrl: Observable<string>;
    userName: string;
    firstName: string;
    lastName: string;
    dataRefresher: any;
    spinner1 = 'sp1';
    selectedChat: ChatDTO;

    constructor(
        private keycloakService: KeycloakService,
        private chatService: ChatService,
        public router: Router,
        private sharedService: ReceiverService,
        private businessProfileService: BusinessProfileService,
        private spinner: NgxSpinnerService) {
    }

    ngOnInit() {
        /** spinner ends after 5 seconds */
        this.spinner.hide();

        this.profileUrl = this.businessProfileService.profileUrl;
        this.getMemberChats();
        this.keycloakService.loadUserProfile().then((profile) => {
            this.userName = profile.email;
            this.firstName = profile.firstName;
            this.lastName = profile.lastName;
        });
    }

    cancelPageRefresh() {
        if (this.dataRefresher) {
            clearInterval(this.dataRefresher);
        }
    }

    ngOnDestroy(): void {
        if (this.chatSubscription) {
            this.chatSubscription.unsubscribe();
        }

        this.cancelPageRefresh();
    }

    chatClicked(
        receiver: string,
        sender: string,
        product: string,
        chatUuid: string
    ) {
        const senderReceiver: SenderReceiver = {
            receiver: receiver,
            sender: sender,
            product: product,
            chatUuid: chatUuid,
        };
        this.sharedService.changeChatHistory(senderReceiver);
    }

    onSelect(chatDTO: ChatDTO) {
        this.selectedChat = chatDTO;
    }

    private getMemberChats() {
        this.chatSubscription = this.chatService
            .getMemberChats()
            .subscribe(
                (value) => {
                    this.chats = value;
                },
                (error) => {
                    console.log(JSON.stringify(error));
                }
            );
    }


}
