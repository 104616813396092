import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Router} from "@angular/router";
import {CategoryData} from "../../models/category";

@Component({
    selector: "app-category-list",
    templateUrl: "./category-list.component.html",
    styleUrls: ["./category-list.component.scss"],
})
export class CategoryListComponent implements OnInit {
    @Input() categories: [CategoryData];
    @Output() change: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    changeCategory(event) {
        this.change.emit(event);
    }
}
