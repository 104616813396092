import {AfterViewInit, Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild,} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AppSettings, Settings} from '../app.settings';
import {AppService} from '../app.service';
import {SidenavMenuService} from '../theme/components/sidenav-menu/sidenav-menu.service';
import {isPlatformBrowser} from '@angular/common';
import {ItemListService} from '../common-services/item-list.service';
import {CategorizedItemList} from './products/models/categorized-item-list';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {ItemList} from './products/models/item-list';
import {MatSelectChange} from '@angular/material/select';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CategoriesService} from '../common-services/categories.service';
import {CategoryData} from '../models/category';
import {KeycloakService} from 'keycloak-angular';
import {UserMemberService} from '../common-services/user-member.service';
import {TranslateService} from '@ngx-translate/core';
import {BusinessProfileService} from '../common-services/common-services/business-profile.service';
import {RegistrationService} from '../common-services/registration.service';
import {environment} from '../../environments/environment';
import {KeycloakLoginOptions} from 'keycloak-js';
import {AuthService} from '../common-services/auth.service';
import {MembershipApplicationService} from '../common-services/common-services/membership-application.service';

interface Food {
    value: string;
    viewValue: string;
}

interface Search {
    name: string;
}

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss'],
    providers: [SidenavMenuService],
})
export class PagesComponent implements OnInit, AfterViewInit {
    foods: Food[] = [
        {value: 'steak-0', viewValue: 'Steak'},
        {value: 'pizza-1', viewValue: 'Pizza'},
        {value: 'tacos-2', viewValue: 'Tacos'},
    ];


    //public settings: Settings;
    // isLoggedIn = false;
    profileSubscription: Subscription;
    profileUrl: Observable<string>;
    userName: string;
    firstName: string;
    lastName: string;

    isLoggedIn = false;
    // userMemberSubscription: Subscription;
    activeMembership: boolean;
    existingMembershipApplication: boolean;
    membershipSubscription: Subscription;
    membershipApplicationSubscription: Subscription;
    adminRole = false;
    roles: string[];
    userRole = false;


    itemLists: ItemList[] = [];
    pageIndex = 0;
    pageSize = 20;
    form: FormGroup;
    searchValue: string;

    public showBackToTop: boolean = false;
    public categories: CategoryData[];
    public category: CategoryData;
    private allCategories: CategoryData = {name: 'All Categories'};
    public sidenavMenuItems: Array<any>;
    @ViewChild('sidenav', {static: true}) sidenav: any;
    categorizedItemLists: CategorizedItemList[];
    itemListSubscription: Subscription;
    userMemberSubscription: Subscription;
    categorizedListSubscription: Subscription;
    selectedVal: string;
    selectedData: any;
    keycloakUserId: string;
    public settings: Settings;

    constructor(
        public appSettings: AppSettings,
        public appService: AppService,
        private itemListService: ItemListService,
        public sidenavMenuService: SidenavMenuService,
        private categoriesService: CategoriesService,
        private membershipApplicationService: MembershipApplicationService,
        public fb: FormBuilder,
        public router: Router,
        public translateService: TranslateService,
        private businessProfileService: BusinessProfileService,
        private registrationService: RegistrationService,
        private keycloakService: KeycloakService,
        private route: Router,
        private userMemberService: UserMemberService,
        @Inject(PLATFORM_ID) private platformId: Object,
        private authService: AuthService
    ) {
        this.settings = this.appSettings.settings;
        this.form = this.createForm();
    }

    private createForm() {
        return this.fb.group({
            itemTitle: [''],
            itemCategory: [''],
        });
    }

    async ngOnInit() {

        this.checkIfLoggedIn();
        this.isLoggedIn = await this.keycloakService.isLoggedIn();
        if (this.isLoggedIn === true) {
            this.getProfileLogo();
            this.profileUrl = this.businessProfileService.profileUrl;

            this.keycloakService.loadUserProfile().then((profile) => {
                this.userName = profile.email;
                this.firstName = profile.firstName;
                this.lastName = profile.lastName;
            });
        }


        this.isLoggedIn = await this.keycloakService.isLoggedIn();
        const keycloakProfile = await this.keycloakService.loadUserProfile();
        this.keycloakUserId = keycloakProfile.username;

        if (this.isLoggedIn) {
            this.getMember();
            this.roles = this.keycloakService.getUserRoles();
            this.adminRole = this.hasRole('admin');
            this.userRole = this.hasRole('user');

            if (this.userRole) {
                this.getMembership();
                this.getExistingMembershipApplication(this.keycloakUserId);
            }
        }
        this.membershipSubscription = this.membershipApplicationService.activeMembership
            .subscribe(value => {
                this.activeMembership = value;
            });
        this.membershipApplicationSubscription =  this.membershipApplicationService.existingMembershipApplication.subscribe(value => {
            this.existingMembershipApplication = value;
            console.log('existingMembershipApplication ::::::::::::' + this.existingMembershipApplication);

        });
        //this.getAll(this.pageIndex, this.pageSize);
        this.itemListSubscription = this.itemListService.categorizedItemList.subscribe(
            (categorizedItemList) => {
                this.categorizedItemLists = categorizedItemList;
            }
        );

        this.getCategories();
        this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
        setTimeout(() => {
            this.settings.theme = 'green';
        });
    }

    private getProfileLogo() {
        this.profileSubscription = this.businessProfileService
            .getLogo()
            .subscribe();
    }

    login() {
        const url = environment.loginRedirectUri;
        const options: KeycloakLoginOptions = {
            redirectUri: url,
        };
        this.keycloakService
            .login(options)
            .then(() => {
                console.log('login successful');
            })
            .catch((reason) => {
                console.log(JSON.stringify(reason));
            });
    }

    register() {
        const url = environment.loginRedirectUri;
        const options: KeycloakLoginOptions = {
            redirectUri: url,
        };
        this.keycloakService
            .register(options)
            .then(() => {
                console.log('login successful');
            })
            .catch((reason) => {
                console.log(JSON.stringify(reason));
            });
    }

    async logout() {
        if (await this.keycloakService.isLoggedIn()) {
            this.registrationService.clear().subscribe((value) => {
                console.log(value);
                this.keycloakService.logout(environment.logoutRedirectUri);
            });
        }
    }

    getAll(pageIndex: number, pageSize: number) {
        this.itemListSubscription = this.itemListService
            .allItems(pageIndex, pageSize)
            .subscribe((value) => {
                this.itemLists = value;
            });
    }

    categorySelect(category: string) {
        console.log('You have selected ' + category);
        this.router.navigate(['/products', category]);
    }

    search() {
        const name: string = this.form.value.itemTitle;
        const category: string = this.category.name;

        if (!name || name.length === 0 || name === '') {
            if (category === this.allCategories.name) {
                this.router.navigate(['/products', 'shop']);
            } else {
                this.router.navigate(['/products/category', category]);
            }
            this.form.reset();
        } else {
            if (category === this.allCategories.name) {
                const b = this.router.url.includes('/products/search');
                if (b) {
                    this.router.navigate(['/products/search', name]);
                    this.itemListService.searchItems({itemTitle: name});
                } else {
                    this.router.navigate(['/products/search', name]);
                }
            } else {
                const b = this.router.url.includes('/products/search');
                if (b) {
                    this.router.navigate(['/products/search', name],
                        {queryParams: {category: this.category.name}});
                    this.itemListService.searchItems({itemTitle: name, category: this.category.name});
                } else {
                    this.router.navigate(['/products/search', name],
                        {queryParams: {category: this.category.name}});
                }
            }
            this.form.reset();
        }
    }

    public getCategories() {
        this.categoriesService.getData().subscribe(value => {
            let newValue = [...[this.allCategories], ...value];
            this.category = newValue[0];
            this.categories = newValue;
        });
    }

    public changeCategory(event) {
        if (event) {
            this.category = event;
        }
        if (window.innerWidth < 960) {
            this.stopClickPropagate(event);
        }
    }

    public remove(product) {
        const index: number = this.appService.Data.cartList.indexOf(product);
        if (index !== -1) {
            this.appService.Data.cartList.splice(index, 1);
            this.appService.Data.totalPrice =
                this.appService.Data.totalPrice - product.newPrice * product.cartCount;
            this.appService.Data.totalCartCount =
                this.appService.Data.totalCartCount - product.cartCount;
            this.appService.resetProductCartCount(product);
        }
    }

    public clear() {
        this.appService.Data.cartList.forEach((product) => {
            this.appService.resetProductCartCount(product);
        });
        this.appService.Data.cartList.length = 0;
        this.appService.Data.totalPrice = 0;
        this.appService.Data.totalCartCount = 0;
    }

    public changeTheme(theme) {
        this.settings.theme = theme;
    }

    public stopClickPropagate(event: any) {
        event.stopPropagation();
        event.preventDefault();
    }


    public scrollToTop() {
        var scrollDuration = 200;
        var scrollStep = -window.pageYOffset / (scrollDuration / 20);
        var scrollInterval = setInterval(() => {
            if (window.pageYOffset != 0) {
                window.scrollBy(0, scrollStep);
            } else {
                clearInterval(scrollInterval);
            }
        }, 10);
        if (window.innerWidth <= 768) {
            setTimeout(() => {
                if (isPlatformBrowser(this.platformId)) {
                    window.scrollTo(0, 0);
                }
            });
        }
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll($event) {
        $event.target.documentElement.scrollTop > 300
            ? (this.showBackToTop = true)
            : (this.showBackToTop = false);
    }

    ngAfterViewInit() {
        this.getAll(this.pageIndex, this.pageSize);
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.sidenav.close();
            }
        });
        this.sidenavMenuService.expandActiveSubMenu(
            this.sidenavMenuService.getSidenavMenuItems()
        );

        this.categorizedListSubscription = forkJoin([
            this.itemListService.listCategorizedItems(),
        ]).subscribe(
            (value) => {
                //this.isLoading = false;
            },
            (error) => {
                // this.isLoading = false;
                console.log(error);
            }
        );
    }

    onChange(event: MatSelectChange) {
        if (event.value == 'All') {
            this.getAll(this.pageIndex, this.pageSize);
        }

        console.log('You have selected ', event.value);
        this.itemListSubscription = this.itemListService
            .listItems(event.value, this.pageIndex, this.pageSize)
            .subscribe((value) => {
                this.itemLists = value;
            });
    }

    public closeSubMenus() {
        if (window.innerWidth < 960) {
            this.sidenavMenuService.closeAllSubMenus();
        }
    }


    hasRole(rolaname: string) {
        let hasRole = false;
        this.roles.forEach((r) => {
            if (r === rolaname) {
                hasRole = true;
            }
        });
        return hasRole;
    }

    openMegaMenu() {
        let pane = document.getElementsByClassName('cdk-overlay-pane');
        [].forEach.call(pane, function(el) {
            if (el.children.length > 0) {
                if (el.children[0].classList.contains('mega-menu')) {
                    el.classList.add('mega-menu-pane');
                }
            }
        });
    }

    getMember() {
        this.userMemberSubscription = this.userMemberService
            .getMemberByUser()
            .subscribe();
    }

    getMembership() {
        this.membershipSubscription = this.membershipApplicationService.getByUser(this.keycloakUserId).subscribe();
    }
    private getExistingMembershipApplication(keycloakUserId) {
        this.membershipApplicationSubscription = this.membershipApplicationService.getApplicationStatusByUserId(keycloakUserId).subscribe();
    }

    ngOnDestroy(): void {
        if (this.userMemberSubscription) {
            this.userMemberSubscription.unsubscribe();
        }
        if (this.membershipSubscription) {
            this.membershipSubscription.unsubscribe();
        }
        if (this.membershipApplicationSubscription) {
            this.membershipApplicationSubscription.unsubscribe();
        }
    }

    goToSell(route: string) {
        if (this.activeMembership) {
            this.route.navigate([route]);
        } else {
            this.route.navigate(['/choose-service']);
        }
    }

    private async checkIfLoggedIn() {
        if (await this.keycloakService.isLoggedIn()) {
            this.authService.setIsLoggedIn(true);
        } else {
            this.authService.setIsLoggedIn(false);
        }
    }
}
