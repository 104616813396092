import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {RegistrationService} from 'src/app/common-services/registration.service';
import {DocumentService} from '../../../common-services/document.service';
import {DocumentUpload} from '../../../models/document-upload';
import {PERSON_DETAIL_STORAGE_KEY, PersonDetail} from '../../../models/person-deatils';
import {FileAttachment, FileAttachmentNUpload} from '../../../models/file-attachment';
import {FileUpload} from '../../../models/file-upload';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UserRepresentationService} from '../../../common-services/user-representation.service';
import {CheckKraPinIndividualExists} from '../../utils/krapin-validator';

@Component({
    selector: 'app-person-details',
    templateUrl: './person-details.component.html',
    styleUrls: ['./person-details.component.scss'],
})
export class PersonDetailsComponent implements OnInit, OnDestroy {

    personalDetailsForm: FormGroup;
    personDetailSubscription: Subscription;
    registrationSubscription: Subscription;
    logo: DocumentUpload;
    @Output() nextTab = new EventEmitter<number>();
    @Input() selectedIndex: number;
    @Input() isDisabled = false;

    constructor(private fb: FormBuilder,
                private registrationService: RegistrationService,
                private userRepresentationService: UserRepresentationService,
                private _snackBar: MatSnackBar,
                private documentService: DocumentService) {
    }

    ngOnInit() {
        this.buildForm();
        this.registrationSubscription = this.registrationService
            .getValueInStorage(PERSON_DETAIL_STORAGE_KEY)
            .subscribe((value) => {
                if (value) {
                    const personDetail: PersonDetail = JSON.parse(JSON.stringify(value));
                    this.personalDetailsForm.patchValue(personDetail);
                    if (personDetail.logo) {
                        this.logo = personDetail.logo;
                    }
                }
            });
    }

    async presentToast(text, duration = 3000) {
        this._snackBar.open(text, 'Show', {
            duration,
        });
    }

    save() {
        if (!this.personalDetailsForm.valid) {
            return;
        }
        this.saveForm();
    }


    saveForm() {
        const personDetailFormValue = this.personalDetailsForm.value;

        const files: File[] = personDetailFormValue.fileUpload?.files;
        delete personDetailFormValue.fileUpload;
        const personDetail: PersonDetail = personDetailFormValue;
        if (files?.length > 0) {
            const file: File = files[0];
            const formData = this.createFormData(file, 'LOGO');
            this.startFileUpload(formData).subscribe(value => {
                const fileAtt: FileAttachment = !(value instanceof Array) ? value : value[0];
                if (fileAtt) {
                    const fileAttachment = fileAtt;
                    const fileEntry = this.createFileEntry(formData);
                    const fileAttachmentNUpload = this.createFileAttachmentNUpload(fileAttachment, fileEntry);
                    personDetail.logo = this.createLogo(fileAttachmentNUpload);
                    this.savePersonalDetail(personDetail);
                }
            }, error => {

            });
        } else {
            this.savePersonalDetail(personDetail);
        }
    }

    deleteLogo() {
        // delete in backend.
        this.logo = null;
        this.personalDetailsForm.get('logo').setValue(null);
        this.personalDetailsForm.get('fileUpload').setValue(null);
        this.presentToast('File removed.');
    }


    goBack() {
        console.log(this.selectedIndex);
        if (this.selectedIndex !== 0) {
            this.nextTab.emit(--this.selectedIndex);
        }
    }

    ngOnDestroy(): void {
        if (this.personDetailSubscription) {
            this.personDetailSubscription.unsubscribe();
        }
        if (this.registrationSubscription) {
            this.registrationSubscription.unsubscribe();
        }
        if (this.personalDetailsForm) {
            this.personalDetailsForm.reset();
        }
    }

    private savePersonalDetail(personDetail: PersonDetail) {
        this.personDetailSubscription = this.registrationService
            .setValueInStorage(PERSON_DETAIL_STORAGE_KEY, personDetail)
            .subscribe(
                (value) => {
                    this.presentToast('Saved!');
                    this.nextTab.emit(++this.selectedIndex);
                },
                (error) => {
                    this.presentToast('An error occured! Please try again.', 5000);
                }
            );
    }

    private createFileAttachmentNUpload(fileAttachment: FileAttachment, fileEntry: FileUpload) {
        const fileAttachmentNUpload: FileAttachmentNUpload = {
            fileAttachment,
            fileUpload: fileEntry,
        };
        return fileAttachmentNUpload;
    }

    private createFileEntry(formData: FormData) {
        const file = formData.get('file');
        const fileName = (file instanceof File) ? file.name : file;
        const fileEntry: FileUpload = {
            name: fileName,
            fileType: formData.get('fileAttachmentType') as string
        };
        return fileEntry;
    }

    private createFormData(file: File, fileType: string): FormData {
        const formData = new FormData();
        const currentName = file.name;
        formData.append('file', file, currentName);
        formData.append('fileAttachmentType', fileType);
        return formData;
    }

    private startFileUpload(formData: FormData): Observable<FileAttachment | FileAttachment[]> {
        return this.documentService
            .uploads(formData);
    }

    private createLogo(fileAttachmentNUpload: FileAttachmentNUpload) {
        const logo: DocumentUpload = {
            type: 'LOGO',
            description: 'Profile LOGO',
            uploaded: true,
            fileUpload: fileAttachmentNUpload.fileUpload,
            fileAttachment: fileAttachmentNUpload.fileAttachment
        };
        return logo;
    }

    private buildForm() {
        this.personalDetailsForm = this.fb.group({
            businessEntityUuid: [''],
            identificationNumber: ['', Validators.required],
            tin: ['',
                {
                    validators: [Validators.required],
                    asyncValidators: [CheckKraPinIndividualExists(this.userRepresentationService)],
                    updateOn: 'blur'
                }],
            email: ['', [Validators.required, Validators.email]],
            telPhoneNumber: ['', [Validators.required, Validators.pattern('^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$')]],
            postalAddress: [''],
            physicalAddress: [''],
            websiteUrl: [''],
            logo: [],
            fileUpload: []
        });
    }

    getExistingKraPinErrorMessage() {
        return this.personalDetailsForm.get('tin').errors
            && this.personalDetailsForm.get('tin').hasError('ExistingTin');

    }
}
