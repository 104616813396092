import { Component, OnInit } from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-application-form-test-view',
  templateUrl: './application-form-test-view.component.html',
  styleUrls: ['./application-form-test-view.component.scss']
})
export class ApplicationFormTestViewComponent implements OnInit {

  toppingList: string[] = ['Kenya', 'Uganda', 'Tanzania', 'Rwanda', 'Zanzibar', 'Burundi'];

  constructor() { }

  ngOnInit(): void {
  }

}
