import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable, Subscription} from 'rxjs';
import {RegistrationService} from 'src/app/common-services/registration.service';
import {COUNTY_N_SERVICE_STORAGE_KEY, CountyNService, } from 'src/app/models/county-n-service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {KncciService} from 'src/app/admin/configs/models/kncci-services';
import {StaffCapacity} from '../../../admin/configs/models/staff-capacity';
import {Counties, CountiesService} from '../../membership-services/counties.service';
import {Sector, SectorService} from '../../membership-services/sectors.service';
import {Division, DivisionService} from '../../membership-services/division.service';
import {Group, GroupService} from '../../membership-services/group.service';
import {Class, ClassService} from '../../membership-services/class.service';
import {KncciServiceService} from '../../membership-services/kncci-service.service';
import {Turnover, TurnoverService} from '../../membership-services/turnover.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CmisDuration, CmisDurationService} from '../../membership-services/CmisDuration.service';


@Component({
    selector: 'app-county-n-services',
    templateUrl: './county-n-services.component.html',
    styleUrls: ['./county-n-services.component.scss'],
})
export class CountyNServicesComponent implements OnInit, AfterViewInit {
    countyNServicesForm: FormGroup;
    countyNServicesSubscription: Subscription;
    registrationSubscription: Subscription;
    @Input('memberShipCategory') memberShipCategory: string;
    @Input('memberShipCategoryCounty') memberShipCategoryCounty: string;
    @Output() nextTab = new EventEmitter<number>();
    @Input() selectedIndex: number;

    mainSectors: Observable<Sector[]>;
    divisions: Observable<Division[]>;
    groups: Observable<Group[]>;
    classes: Observable<Class[]>;
    staffCapacity: Observable<StaffCapacity[]>;
    locationCounties: Counties[] = [];
    memberShipCounties: Counties[] = [];
    durations: CmisDuration[] = [];
    services: KncciService[] = [];
    turnovers: Turnover[] = [];
    @Input() isDisabled = false;

    constructor(
        public formBuilder: FormBuilder,
        private fb: FormBuilder,
        public renderer: Renderer2,
        private _snackBar: MatSnackBar,
        private router: Router,
        private route: ActivatedRoute,
        private sectorService: SectorService,
        private divisionService: DivisionService,
        private durationService: CmisDurationService,
        private companyTurnoverService: TurnoverService,
        private groupService: GroupService,
        private classService: ClassService,
        private registrationService: RegistrationService,
        private kncciservice: KncciServiceService,
        private countyService: CountiesService
    ) {
    }

    ngOnInit() {
        this.buildForm();
        this.registrationSubscription = this.registrationService
            .getValueInStorage(COUNTY_N_SERVICE_STORAGE_KEY)
            .subscribe((value) => {
                if (value) {
                    value.firstRegistrationDate = new Date(value.firstRegistrationDate);
                    if (value.lastAccountRenewalDate) {
                        value.lastAccountRenewalDate = new Date(value.lastAccountRenewalDate);
                    }
                    this.countyNServicesForm.patchValue(value);
                }
            });
        this.loadMainSectors();
        this.loadCompanyTurnovers();
        this.loadCounties();
        this.loadKncciServices();

    }

    ngAfterViewInit() {
        if (this.memberShipCategory) {
            this.countyNServicesForm
                .get('memberShipCategory')
                .setValue(this.memberShipCategory);
        }
        if (this.memberShipCategoryCounty) {
            this.countyNServicesForm
                .get('memberShipCategoryCounty')
                .setValue(this.memberShipCategoryCounty);
        }
        if (this.countyNServicesForm) {
            this.countyNServicesForm.get('mainSector').valueChanges.subscribe(val => {
                console.log(val);
                this.countyNServicesForm.get('division').setValue('');
                if (val) {
                    this.divisions = this.divisionService.findBySector(val);
                }
            });
            this.countyNServicesForm.get('division').valueChanges.subscribe(val => {
                console.log(val);
                this.countyNServicesForm.get('group').setValue('');
                if (val) {
                    this.groups = this.groupService.findByDivision(val);
                }
            });
            this.countyNServicesForm.get('group').valueChanges.subscribe(val => {
                console.log(val);
                this.countyNServicesForm.get('sector').setValue('');
                if (val) {
                    this.classes = this.classService.findByGroup(val);
                }
            });
        }
        console.log(this.memberShipCategory);
        this.durationService.findByMembershipCategory(Number(this.memberShipCategory)).subscribe(value => {
            this.durations = value;
        });
    }

    onSubmit() {
        if (!this.countyNServicesForm.valid) {
            return;
        }
        this.saveForm();
    }

    async presentToast(text, duration = 3000) {
        this._snackBar.open(text, 'Show', {
            duration,
        });
    }

    saveForm() {
        const countyNService: CountyNService = this.countyNServicesForm.value;

        if (!countyNService.lastAccountRenewalDate){
            delete countyNService.lastAccountRenewalDate;
        }

        this.countyNServicesSubscription = this.registrationService
            .setValueInStorage(COUNTY_N_SERVICE_STORAGE_KEY, countyNService)
            .subscribe(
                (value) => {
                    this.presentToast('Saved!');
                    this.nextTab.emit(++this.selectedIndex);
                },
                (error) => {
                    this.presentToast('An error occured! Please try again.', 5000);
                }
            );
    }

    ngOnDestroy(): void {
        if (this.countyNServicesSubscription) {
            this.countyNServicesSubscription.unsubscribe();
        }
        if (this.registrationSubscription) {
            this.registrationSubscription.unsubscribe();
        }
        if (this.countyNServicesForm) {
            this.countyNServicesForm.reset();
        }
    }

    goBack() {
        console.log(this.selectedIndex);
        if (this.selectedIndex !== 0) {
            this.nextTab.emit(--this.selectedIndex);
        }
    }

    private loadCompanyTurnovers() {
        this.companyTurnoverService.getAll().subscribe((value) => {
            this.turnovers = value;
        });
    }

    private loadCounties() {
        this.countyService.findCounties().subscribe(value => {
            this.locationCounties = value;
            this.memberShipCounties = value;
        });
    }

    private loadMainSectors() {
        this.mainSectors = this.sectorService.findSector();
    }

    private loadKncciServices() {
        this.kncciservice.getAll().subscribe((value) => {
            this.services = value;
        });
    }

    private buildForm() {
        this.countyNServicesForm = this.fb.group({
            ownerUuid: [''],
            accountType: [''],
            locationCounty: ['', Validators.required],
            memberShipCategory: [''],
            memberShipCategoryCounty: [''],
        //    memberShipCounty: ["", Validators.required],
            duration: ['', Validators.required],
            goodsService: [''],
            mainSector: [''],
            division: [''],
            group: [''],
            sector: ['', Validators.required],
            businessServices: ['', [Validators.required]],
            firstRegistrationDate: ['', [Validators.required]],
            lastAccountRenewalDate: [],
            companyTurnoverUuid: ['', Validators.required],
            expectations: ['']
        });
    }
}
