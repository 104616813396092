<link href="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"></script>
<script src="//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
<!------ Include the above in your HEAD tag ---------->

<link href="//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap.min.css" rel="stylesheet" id="bootstrap-css">
<script src="//netdna.bootstrapcdn.com/bootstrap/3.0.0/js/bootstrap.min.js"></script>
<script src="//code.jquery.com/jquery-1.11.1.min.js"></script>
<!------ Include the above in your HEAD tag ---------->


<!DOCTYPE html>
<html class=''>

<head>
    <link href='https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700,300' rel='stylesheet'
          type='text/css'>


    <link rel='stylesheet prefetch' href='https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css'>
    <link rel='stylesheet prefetch'
          href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.2/css/font-awesome.min.css'>
</head>

<body>


<div id="frame">
    <div class="content">

        <div class="contact-profile">
            <p style="margin-left: 10px;font-size: 21px;color: #000;">My Messages</p>


        </div>
        <div #scrollMe class="messages" id="chats">
            <div style="background-color: rgb(197 197 197 / 44%) !important; color: #000 !important;" fxLayout="row"
                 fxLayout.xs="column" fxLayoutGap="7px" fxLayoutGap.sm="0px">
                <img [routerLink]="['/products', selectedChat?.product, selectedChat?.productName]"
                     style="width: 85px !important; border-radius: 0% !important; margin-right: 36px !important;"
                     [src]="getImagePath(selectedChat?.itemImage)" alt="product"/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <p fxFlex>
                    Product: {{selectedChat?.productName }}<br><br>
                    Price: {{selectedChat?.itemPrice }}&nbsp;&nbsp; <br>

                </p>
            </div>


            <ul *ngFor="let message of chatMessages">
                <li fxFlex [fxLayoutAlign]="getSlot(message.sender)" class="sent">
                    <!-- <img src="http://emilcarlsson.se/assets/mikeross.png" alt="" /> -->
                    <p [style]="getStyle(message.receiver)">{{ message.message }}
                    </p>
                    {{ message.sentDate |  date: 'dd/MM/yyyy hh:mm:ss' }}
                </li>
            </ul>

        </div>
        <div class="message-input">
            <form [formGroup]="chatForm">
                <div class="wrap">
                    <input type="text" formControlName="message" placeholder="Write your message..."/>
                    <i class="fa fa-paperclip attachment" aria-hidden="true"></i>
                    <button (click)="onSend()" [disabled]="!chatForm.valid" class="submit"><i
                            class="fa fa-paper-plane" aria-hidden="true"></i></button>
                </div>
            </form>
        </div>
    </div>
</div>
</body>

</html>

<!-- "getSlot(message.sender)" -->
