<style>
    input:read-only {
        color: grey;
    }
</style>

<ngx-spinner bdColor="rgba(51,51,51,0.7)" size="large" color="#fff" type="ball-clip-rotate"></ngx-spinner>
<mat-card fxLayout="row wrap" fxLayoutAlign="space-around">
    <form [formGroup]="companyDetailsForm" fxLayout="row wrap">
        <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Company Name</mat-label>
                <input matInput formControlName="name" placeholder="Company Name" required>
            </mat-form-field>
        </div>



        <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Company Registration Number</mat-label>
                <input matInput formControlName="registrationNumber" placeholder="Registration Number" required [readonly]="isDisabled">

                <mat-error *ngIf="getExistingCompanyRegistrationErrorMessage()">
                    Company Registration Number already exists !!
                </mat-error>


<!--                <mat-error-->
<!--                        *ngIf="companyDetailsForm.get('registrationNumber').hasError('required') || companyDetailsForm.get('registrationNumber').touched">-->
<!--                    :{{msg.value | testpipe}}-->
<!--                </mat-error>-->
<!--                <mat-hint style="margin-left: 20px;" align="end">-->
<!--                    :{{msg.value | testpipe}}-->
<!--                </mat-hint>-->
            </mat-form-field>
        </div>


        <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Company Registration Date</mat-label>
                <input formControlName="registrationDate" matInput [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker [calendarHeaderComponent]=""></mat-datepicker>
            </mat-form-field>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Company KRA Pin Number</mat-label>
                <input     matInput formControlName="tin" placeholder="Pin Number" required [readonly]="isDisabled">
                <mat-error *ngIf="getExistingKraPinErrorMessage()">
                    KRA Pin already exists !!
                </mat-error>


<!--                <mat-error-->
<!--                        *ngIf="companyDetailsForm.get('tin').hasError('required') || companyDetailsForm.get('tin').touched">-->
<!--                    :{{msg1.value | pinpipe}}-->
<!--                </mat-error>-->
            </mat-form-field>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Company Email</mat-label>
                <input matInput formControlName="email" placeholder="Business Email">
                <mat-error
                        *ngIf="companyDetailsForm.get('email').hasError('required') || companyDetailsForm.get('email').touched">
                    Please enter a valid email address
                </mat-error>
            </mat-form-field>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Company Telephone Number</mat-label>
                <input matInput formControlName="telPhoneNumber" placeholder="Business Telephone Number">
                <mat-error *ngIf="companyDetailsForm.get('telPhoneNumber').hasError('required') || companyDetailsForm.get('telPhoneNumber').touched">
                    Please enter a valid Telephone  Number
                </mat-error>
            </mat-form-field>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Company Mobile Number</mat-label>
                <input matInput formControlName="mobilePhoneNumber" placeholder="Business Mobile Number">
                <mat-error *ngIf="companyDetailsForm.get('mobilePhoneNumber').hasError('required') || companyDetailsForm.get('mobilePhoneNumber').touched">
                    Please enter a valid Telephone  Number
                </mat-error>
            </mat-form-field>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Postal Address</mat-label>
                <input matInput formControlName="postalAddress" placeholder="Postal Address" required>
            </mat-form-field>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Physical Address</mat-label>
                <input matInput formControlName="physicalAddress" placeholder="Physical Address" required>
            </mat-form-field>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Staff Capacity</mat-label>
                <mat-select formControlName="staffCapacityUuid">
                    <mat-option *ngFor="let staff of staffs" [value]="staff.name">
                        <span>{{ staff.name }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Where did you learn about us?</mat-label>
                <mat-select formControlName="hearUsFrom">
                    <mat-option *ngFor="let option of options" [value]="option.name">
                        <span>{{ option.name }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Website Url</mat-label>
                <input matInput formControlName="websiteUrl" placeholder="Website Url" >
            </mat-form-field>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Company Nature</mat-label>
                <mat-select formControlName="hearUsFrom" required>
                    <mat-option *ngFor="let nature of natures" [value]="nature.name">
                        <span>{{ nature.name }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1" *ngIf="!logo">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label> Upload Company Logo</mat-label>
                <ngx-mat-file-input [value]="" #removableInput formControlName="fileUpload" placeholder="Click to Select Logo">
                </ngx-mat-file-input>
                <button (click)="removableInput.clear($event)" *ngIf="!removableInput.empty" mat-icon-button
                        matSuffix>
                    <mat-icon>clear</mat-icon>
                </button>
                <mat-icon matSuffix>folder</mat-icon>
            </mat-form-field>
        </div>

        <div *ngIf="logo">
            <p>Logo: {{ logo.fileUpload.name }}
                <span (click)="deleteLogo()" class="material-icons">delete</span>
            </p>
        </div>

        <div fxLayout="row" class="w-100 py-2">
            <button mat-stroked-button color="primary" (click)="goBack()">Previous</button>
            <span fxFlex>&nbsp;</span>
            <button (click)="save()" [disabled]="!companyDetailsForm.valid" mat-raised-button color="primary"
                    type="submit">
                Next
            </button>
        </div>
    </form>
</mat-card>
