import { KeycloakService } from "keycloak-angular";
import { environment } from "../environments/environment";

export function initializer(keycloak: KeycloakService): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init({
          config: environment.keycloak,
          initOptions: {
            // silentCheckSsoRedirectUri: 'http://localhost:8100/folder/silent-check-sso',
            onLoad: "check-sso",
            //onLoad: "login-required",
            checkLoginIframe: false,
            //adapter: 'cordova',
            // responseMode: 'query',
            // redirectUri: 'loginpoc://login-poc.com/home'
          },
          bearerExcludedUrls: ["/landing", "/"],
          enableBearerInterceptor: false,
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
}
