import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ApplicationFormTestViewComponent} from '../application-form-test-view/application-form-test-view.component';
import {MatTable} from '@angular/material/table';

export interface PeriodicElement {

    package: string;
    unit: string;
    tariff: string;
    description: string;
    packages: string;
    unit2: string;
    invoice: string;
    value: string;
    unit3: string;
    show: string;
}


@Component({
    selector: 'app-application-form-test',
    templateUrl: './application-form-test.component.html',
    styleUrls: ['./application-form-test.component.scss']
})
export class ApplicationFormTestComponent implements OnInit {
    public form: FormGroup;

    displayedColumns: string[] = ['package', 'tariff', 'description', 'packages', 'invoice', 'value'];
    dataSource: PeriodicElement[] = [];
    @ViewChild(MatTable) table: MatTable<PeriodicElement>;
    @ViewChild('fileUpload') fileUpload: ElementRef;

    toppings = new FormControl();
    toppingList: string[] = ['Kenya', 'Uganda', 'Tanzania', 'Rwanda', 'Zanzibar', 'Burundi'];

    constructor(
        public fb: FormBuilder,
        public dialog: MatDialog
    ) {
        this.form = this.createForm();

    }
    showUploadDialog() {
        console.log('upload dialog method...');
        this.fileUpload.nativeElement.click();
    }

    ngOnInit(): void {


    }

    onSubmit() {
        this.dataSource.push(this.form.value);
        this.table.renderRows();
        this.form.reset();
        console.log(this.form.value);
    }


    private createForm() {
        return this.fb.group({
            package: [''],
            unit: [''],
            tariff: [''],
            description: [''],
            packages: [''],
            unit2: [''],
            invoice: [''],
            value: [''],
            unit3: [''],
            show: [''],


        });
    }

    add() {
        const dialogRef = this.dialog.open(ApplicationFormTestViewComponent, {
            width: '1000px',
            height: '900px'
        });
    }

}
