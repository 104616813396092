import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AccountType} from 'src/app/models/account-type';
import {Counties, CountiesService} from '../membership-services/counties.service';

interface SelectInterface {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-choose-service',
    templateUrl: './choose-service.component.html',
    styleUrls: ['./choose-service.component.scss'],
})
export class ChooseServiceComponent implements OnInit {
    form: FormGroup;
    counties: Observable<Counties[]>;
    accountType: AccountType[];

    accountTypes: SelectInterface[] = [
        {value: AccountType.COMPANY, viewValue: AccountType.COMPANY},
        {value: AccountType.INDIVIDUAL, viewValue: AccountType.INDIVIDUAL},
    ];


    constructor(
        private router: Router,
        private fb: FormBuilder,
        private countyService: CountiesService
    ) {
    }

    ngOnInit() {
        this.buildForm();
        this.loadCounties();
    }

    onSubmit() {
        if (!this.form.valid) {
            return;
        }

        const countyId = this.form.value.county;
        const accountType = this.form.value.accountType;
        this.router.navigate([
            // "/",
            // "registration",
            'membership-category-packages',
            countyId,
            accountType,
        ]);
    }

    private buildForm() {
        this.form = this.fb.group({
            county: ['', Validators.required],
            accountType: ['', Validators.required],
        });
    }

    private loadCounties() {
        this.counties = this.countyService.findCounties();
    }
}
