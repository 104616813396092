import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RegistrationRoutingModule} from './registration-routing.module';
import {CompanyDetailsComponent} from './company-details/company-details.component';
import {ContactDeatilsComponent} from './contact-deatils/contact-deatils.component';
import {CountyNServicesComponent} from './county-n-services/county-n-services.component';
import {DocumentsComponent} from './documents/documents.component';
import {DownloadDocumentsComponent} from './download-documents/download-documents.component';
import {GeoLocationComponent} from './geo-location/geo-location.component';
import {PersonDetailsComponent} from './person-details/person-details.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from 'src/app/shared/shared.module';
import {MapModalComponent} from './geo-location/map-modal/map-modal.component';
import {IonicStorageModule} from '@ionic/storage';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {AgmCoreModule} from '@agm/core';
import {NgxSpinnerModule} from 'ngx-spinner';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';
import { TestpipePipe } from './company-details/testpipe.pipe';
import { PinpipePipe } from './company-details/pinpipe.pipe';

@NgModule({
    declarations: [
        CompanyDetailsComponent,
        ContactDeatilsComponent,
        CountyNServicesComponent,
        DocumentsComponent,
        DownloadDocumentsComponent,
        GeoLocationComponent,
        PersonDetailsComponent,
        MapModalComponent,
        TestpipePipe,
        PinpipePipe,
    ],
    imports: [
        CommonModule,
        RegistrationRoutingModule,
        SharedModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCMJ-QgnhOQ_9IPMx9_aqj7MclyFws03NE',
            libraries: ['places']
        }),
        ReactiveFormsModule,
        FormsModule,
        MatSelectModule,
        MatSnackBarModule,
        MatFormFieldModule,
        MatDatepickerModule,
        IonicStorageModule.forRoot(),
        NgxSpinnerModule,
        MaterialFileInputModule,
        MatTableModule
    ],

    exports: [
        CompanyDetailsComponent,
        ContactDeatilsComponent,
        CountyNServicesComponent,
        DocumentsComponent,
        DownloadDocumentsComponent,
        GeoLocationComponent,
        PersonDetailsComponent,
    ],
    entryComponents: [MapModalComponent]
})
export class RegistrationModule {
}
