<b>Copyright © Kenya National Chamber of Commerce and Industry 2022 All Rights Reserved </b>

<p>All files and information contained in this Website/App are copyright by the Kenya National Chamber of
    Commerce and Industry, and may not be duplicated, copied, modified or adapted, in any way without our
    written permission. Our website/App may contain our service marks or trademarks as well as those of our
    affiliates or other companies, in the form of words, graphics, and logos. Your use of our Website/App or
    Services does not constitute any right or license for you to use our service marks or trademarks, without
    the prior written permission of the Kenya National Chamber of Commerce and Industry. Our Content, as found
    within our App/Website and Services, is protected under Kenyan and foreign copyrights. The copying, redistribution,
    use or publication by you of any such Content, is strictly prohibited. Your use of our Website and Services does not
    grant you any ownership rights to our Content.</p>
