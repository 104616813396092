<ngx-spinner bdColor="rgba(51,51,51,0.7)" size="large" color="#fff" type="ball-clip-rotate"></ngx-spinner>
<mat-card fxLayout="row wrap" fxLayoutAlign="space-around">
    <form [formGroup]="documentsForm" fxFlex="100" fxFlex.gt-sm="100" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <div fxFlex="100" fxFlex.gt-sm="33" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field fxFlex="100" appearance="standard">
                <mat-label>Select Document Type</mat-label>
                <mat-select formControlName="fileAttachmentType" [disabled]="isDisabled">
                    <mat-option *ngFor="let documentType of documentTypes" [value]="documentType.id">
                        {{documentType.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="50" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <mat-form-field fxFlex="100" appearance="standard">
                <mat-label> Click to Select File</mat-label>
                <ngx-mat-file-input [disabled]="isDisabled" #removableInput formControlName="file" placeholder="Click to Select File">
                </ngx-mat-file-input>
                <button (click)="removableInput.clear($event)" *ngIf="!removableInput.empty" mat-icon-button
                        matSuffix>
                    <mat-icon>clear</mat-icon>
                </button>
                <mat-icon matSuffix>folder</mat-icon>
            </mat-form-field>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="17" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
            <button mat-raised-button (click)="uploadFile()" [disabled]="!documentsForm.valid" color="primary"
                    type="submit">
                Upload Document
            </button>
        </div>

    </form>


    <div fxFlex="100" fxFlex.gt-sm="100" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
        <table mat-table *ngIf="!isDisabled" [dataSource]="documents">

            <ng-container matColumnDef="fileUpload">
                <th mat-header-cell *matHeaderCellDef> Name</th>
                <td mat-cell *matCellDef="let element"> {{element.fileUpload.name}} </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Document Type</th>
                <td mat-cell *matCellDef="let element"> {{element.fileUpload.fileTypeName}} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button color="warn" (click)="delete(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <table mat-table *ngIf="isDisabled" [dataSource]="fileAttachmentDataSource">

            <ng-container matColumnDef="fileUpload">
                <th mat-header-cell *matHeaderCellDef> Name</th>
                <td mat-cell *matCellDef="let element"> {{element.fileName}} </td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Document Type</th>
                <td mat-cell *matCellDef="let element"> {{element.attachmentType}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns1;"></tr>
        </table>
    </div>

    <div fxLayout="row" class="w-100 py-2">
        <button mat-stroked-button color="primary" (click)="goBack()">Previous</button>
        <span fxFlex>&nbsp;</span>
        <button (click)="save()" *ngIf="!isDisabled" [disabled]="!documents || documents.length === 0"
                mat-raised-button color="primary" type="submit">
            Save
        </button>
    </div>

</mat-card>
