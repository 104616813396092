import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import {Observable, of} from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import {UserRepresentationService} from '../../common-services/user-representation.service';

function isEmptyInputValue(value: any): boolean {
    return value === null || value.length === 0;
}


export function CheckKraPinExists(userRepresentationService: UserRepresentationService): AsyncValidatorFn {
    let initialKraPin: string = "";
    return (control: AbstractControl): Promise<ValidationErrors> | Observable<ValidationErrors> => {
        if (isEmptyInputValue(control.value)) {
            return of(null);
        } else if (control.value === initialKraPin) {
            return of(null);
        } else {
            return userRepresentationService.searchByCompanyKrapin(control.value).pipe(
                debounceTime(1000000),
                distinctUntilChanged(),
                map(userRepExists => {
                    return (userRepExists && userRepExists.status === false) ? {ExistingTin: true} : null;
                })
            );
        }

    };

}




export function CheckKraPinIndividualExists(userRepresentationService: UserRepresentationService): AsyncValidatorFn {
    let initialKraPin: string = "";
    return (control: AbstractControl): Promise<ValidationErrors> | Observable<ValidationErrors> => {
        if (isEmptyInputValue(control.value)) {
            return of(null);
        } else if (control.value === initialKraPin) {
            return of(null);
        } else {
            return userRepresentationService.searchByIndividualKrapin(control.value).pipe(
                debounceTime(1000000),
                distinctUntilChanged(),
                map(userRepExists => {
                    console.log(';::::::::::::::::::::: userRepExist' , userRepExists);
                    return (userRepExists && userRepExists.status === false) ? {ExistingTin: true} : null;
                })
            );
        }

    };

}
