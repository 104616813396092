import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";


export interface Counties {
  name: string;
  id: number;

}

const headers = new HttpHeaders({
  "Content-Type": "application/json",
});

@Injectable({
  providedIn: 'root'
})
export class CountiesService {
  private url = `${environment.baseCmisServiceURL}:${environment.services.cmis1.port}${environment.services.cmis1.contextRoot}/counties`;

  constructor(private http: HttpClient) {}

  findCounties(): Observable<Counties[]> {
    // const url = 'http://212.22.169.22:8070/cmis/seam/resource/rest/marketplace/counties';
    // return this.http.get<Counties[]>(url);
    const url = `${this.url}`;
    return this.http.get<Counties[]>(url);
  }

  findByCountyId(countyId: number ): Observable<Counties> {
    // const url = 'http://212.22.169.22:8070/cmis/seam/resource/rest/marketplace/counties';
    // return this.http.get<Counties[]>(url);
    const url = `${this.url}/${countyId}`;
    return this.http.get<Counties>(url);
  }
}
