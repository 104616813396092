import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-banner-sides-switch',
  templateUrl: './banner-sides-switch.component.html',
  styleUrls: ['./banner-sides-switch.component.scss']
})
export class BannerSidesSwitchComponent implements OnInit {
  @Input('banners1') banners1: Array<any> = [];

  constructor() { }

  ngOnInit() { }

  public getBanner(index){
    return this.banners1[index];
  }

  public getBgImage(index){
    const bgImage1 = {
      'background-image': index != null ? "url(" + this.banners1[index].image + ")" : "url(https://via.placeholder.com/600x400/ff0000/fff/)"
    };
    return bgImage1;
  }


}
