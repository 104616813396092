import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { Storage } from '@ionic/storage';
@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  constructor(private storage: Storage) {}

  setValueInStorage(STORAGE_KEY: string, data: any): Observable<any> {
    return from(this.storage.set(STORAGE_KEY, data));
  }

  getValueInStorage(STORAGE_KEY: string): Observable<any> {
    return from(this.storage.get(STORAGE_KEY));
  }

  removeValueInStorage(STORAGE_KEY: string): Observable<any> {
    return from(this.storage.remove(STORAGE_KEY));
  }
  clear(): Observable<any> {
    return from(this.storage.clear());
  }

  // public setValueInStorage(STORAGE_KEY: string, data: any): Observable<any> {
  //   return of(localStorage.setItem(STORAGE_KEY, data));
  // }

  // public getValueInStorage(STORAGE_KEY: string): Observable<any> {
  //   return of(localStorage.getItem(STORAGE_KEY));
  // }
  // public removeValueInStorage(STORAGE_KEY: string) {
  //   localStorage.removeItem(STORAGE_KEY);
  // }
  // public clear() {
  //   localStorage.clear();
  // }
}
