import { Pipe, PipeTransform } from '@angular/core';
import {RegistrationNumberCOnfirmationService} from './CompanyNumberService';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';


export interface CMISRESPONSE {
  message: string;
  status: boolean;
}

export interface CmisPayload {
  value: string;
}


@Pipe({
  name: 'pinpipe'
})
export class PinpipePipe implements PipeTransform {


  percentage: number;
  str = "";
  response: CMISRESPONSE;

  private cachedData: string = null;
  private cachedMedicineAppRef = "";

  constructor(
      private service: RegistrationNumberCOnfirmationService,
  ) { }

  transform(value: string): string {
    this.getPercentage(value);
    return this.str;
  }

  async getPercentage(regNumber: string) {
    const CmisPayload: CmisPayload = {
      value: regNumber
    };
    if (regNumber !== this.cachedMedicineAppRef) {
      this.cachedData = null;
      this.cachedMedicineAppRef = regNumber;
      this.service
          .validateKra(CmisPayload).subscribe(value => {
        debounceTime(1000000),
            distinctUntilChanged(),
            this.response = value;
        console.log('\n\n The response from CMIS\n\n ', value);
      });
    }
  }
}
