import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {BehaviorSubject, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {RegistrationService} from '../registration.service';

export const LOGO_STORAGE_KEY = "logo";

@Injectable({
  providedIn: "root",
})
export class BusinessProfileService {
  private url = `${environment.baseMicroServiceURL}:${environment.services.business.port}${environment.services.business.contextRoot}/business-profile-controller`;
  private defaultProfileImageUrl = `${environment.imagesServerPath}${environment.defaultImageName}`;
  constructor(
    private keycloakService: KeycloakService,
    private registrationService: RegistrationService
  )
  {}

  private _profileUrl = new BehaviorSubject<string>(this.defaultProfileImageUrl);

  get profileUrl(): Observable<string> {
    return this._profileUrl.asObservable();
  }
  // TODO: FIXME Logo
  getLogo(): Observable<any> {
    return this.registrationService.getValueInStorage(LOGO_STORAGE_KEY).pipe(
      tap((x) => {
        if (x) {
          this._profileUrl.next(x);
        }
      })
    );
  }

  // TODO: FIXME Logo
  async saveLogo(logoUrl: string) {
    const blob = await fetch(logoUrl).then((r) => r.blob());
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = async () => {
      localStorage.set(LOGO_STORAGE_KEY, reader.result as string);
      this._profileUrl.next(reader.result as string);
    };
  }
}
