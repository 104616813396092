import { Component, OnInit } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-news-and-press',
  templateUrl: './news-and-press.component.html',
  styleUrls: ['./news-and-press.component.scss']
})
export class NewsAndPressComponent implements OnInit {

  name = 'Set iframe source';
  url: string = "https://www.kenyachamber.or.ke/posts/";
  urlSafe: SafeResourceUrl;


  constructor( public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
