import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FileAttachment } from '../models/file-attachment';
import { environment } from 'src/environments/environment';
import {ImageData} from '../models/item-add';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private url = `${environment.baseMicroServiceURL}:${environment.services.document.port}${environment.services.document.contextRoot}/file-upload-controller`;

  constructor(private http: HttpClient) {}

  post(formData: FormData, fileType: string): Observable<FileAttachment> {
    const url = `${this.url}/upload/${fileType}`;
    return this.http.post<FileAttachment>(url, formData);
  }

  uploadItemImages(
    formData: FormData,
    itemType: string
  ): Observable<FileAttachment[]> {
    const url = `${this.url}/upload/all/${itemType}`;
    return this.http.post<FileAttachment[]>(url, formData);
  }
  uploadPreviewItemImages( formData: FormData, itemType: string): Observable<ImageData> {
    const url = `${this.url}/upload/all/preivew/images/${itemType}`;
    return this.http.post<ImageData>(url, formData);
  }

  editFile(
    formData: FormData,
    fileAttachmentUuid: string
  ): Observable<FileAttachment> {
    console.log('editing file .....');
    const url = `${this.url}/upload/edit/${fileAttachmentUuid}`;
    console.log(JSON.stringify(url));
    return this.http.put<FileAttachment>(url, formData);
  }
  saveFile(
    formData: FormData,
    ownerUuid: string,
    fileType: string
  ): Observable<FileAttachment> {
    console.log('saving file .....');
    const url = `${this.url}/upload/save/${ownerUuid}/${fileType}`;
    console.log(JSON.stringify(url));
    return this.http.post<FileAttachment>(url, formData);
  }

  saveAllFiles(formData: FormData, ownerUuid: string, fileType: string): Observable<FileAttachment[]> {
    console.log('saving files .....');
    const url = `${this.url}/upload/all/save/${ownerUuid}/${fileType}`;
    console.log(JSON.stringify(url));
    return this.http.post<FileAttachment[]>(url, formData);
  }
  uploads(formData: FormData): Observable<FileAttachment | FileAttachment[]> {
    const url = `${this.url}/uploads`;
    return this.http.post<FileAttachment>(url, formData);
  }
}
