<ngx-spinner bdColor="rgba(51,51,51,0.7)" size="large" color="#fff" type="ball-clip-rotate"></ngx-spinner>
<mat-card fxLayout="row wrap" fxLayoutAlign="space-around">

<!--  <button mat-stroked-button color="warn"-->
<!--          (click)="selectDisabled = !selectDisabled">{{selectDisabled ? 'Edit' : 'Edit'}} </button>-->

  <br>
  <form [formGroup]="contactPersonForm" fxLayout="row wrap">
    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline"   class="w-100">
        <mat-label>First Name</mat-label>
        <input formControlName="firstName"   [disabled]="isDisabled" matInput placeholder="First Name">
      </mat-form-field>




<!--      <mat-form-field>-->
<!--        <mat-select placeholder="Sample" [disabled]="selectDisabled">-->
<!--          <mat-option *ngFor="let opt of [1, 2, 3, 4]" [value]="opt">-->
<!--            Option {{ opt }}-->
<!--          </mat-option>-->
<!--        </mat-select>-->
<!--      </mat-form-field>-->
    </div>

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Last Name</mat-label>
        <input formControlName="lastName" matInput placeholder="Last Name">
      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Other Name</mat-label>
        <input matInput formControlName="otherName" placeholder="Other Name" required>
      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label> Email Address </mat-label>
        <input matInput formControlName="email" placeholder="Email">
        <mat-error
                *ngIf="contactPersonForm.get('email').hasError('required') || contactPersonForm.get('email').touched">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>
    </div>


    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label> Mobile Number </mat-label>
        <input  formControlName="phoneNumber"  matInput placeholder=" Mobile Number">
        <mat-error *ngIf="contactPersonForm.get('phoneNumber').hasError('required') || contactPersonForm.get('phoneNumber').touched">
          Please enter a valid Telephone  Number
        </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field   appearance="outline" class="w-100">
        <mat-label>Select Gender</mat-label>
        <select formControlName="gender" [disabled]="isDisabled" matNativeControl>
          <option value="MALE">Male</option>
          <option value="FEMALE">Female</option>
        </select>

      </mat-form-field>
    </div>

    <div fxFlex="100" fxFlex.gt-sm="33.3" class="px-1" ngClass.sm="mt-1" ngClass.xs="mt-1">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label> Designation </mat-label>
        <input formControlName="role" matInput placeholder="Role" required>
      </mat-form-field>
    </div>

    <div fxLayout="row" class="w-100 py-2">
      <div></div>
      <span fxFlex></span>
      <button mat-raised-button color="primary" (click)="onSubmit()"
              [disabled]="!contactPersonForm.valid" type="submit">
        Next
      </button>
    </div>
  </form>
</mat-card>
