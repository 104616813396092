<mat-card fxLayout="row wrap">

    <div fxFlex="100" fxFlex.gt-xs="33.3" ngClass.xs="mt-2">
        <div fxLayout="column" fxLayoutAlign="center center">
        </div>
    </div>
    <div fxFlex="100" fxFlex.gt-xs="33.3" ngClass.xs="mt-2">
        <div fxLayout="column" fxLayoutAlign="center center">
        </div>
    </div>
    <div class="divider w-100 mt-2"></div>
    <h3 class="w-100 text-center py-2">Join us in building a vibrant and prosperous business community in Kenya</h3>

    <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="row wrap">
        <div fxFlex="100" >

            <h4>Select Chapter</h4>
            <mat-form-field appearance="outline" class="w-50">
                <mat-label>Selected Chapter</mat-label>
                <select matNativeControl formControlName="county">
                    <option value="" selected></option>
                    <option *ngFor="let county of counties | async" [value]=" county.id">
                        {{ county.name }}
                    </option>
                </select>
            </mat-form-field>


            <h4>Select Account Type</h4>
            <mat-form-field appearance="outline" class="w-50">
                <mat-label>Account Type</mat-label>
                <select matNativeControl formControlName="accountType">
                    <option value="" selected></option>
                    <option *ngFor="let accountType of accountTypes" [value]="accountType.value">
                        {{ accountType.viewValue }}
                    </option>
                </select>
            </mat-form-field>
        </div>
        <div class="w-100 py-2 text-left">
            <button mat-raised-button color="primary" type="submit">Submit</button>
        </div>
    </form>

</mat-card>
