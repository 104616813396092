import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";

export interface KncciService {
  name: string;
  id: number;
}

const headers = new HttpHeaders({
  "Content-Type": "application/json",
});

@Injectable({
  providedIn: 'root'
})
export class KncciServiceService {

  private url = `${environment.baseCmisServiceURL}:${environment.services.cmis1.port}${environment.services.cmis1.contextRoot}/services`;

  constructor(private http: HttpClient) {}

  getAll(): Observable<KncciService[]> {
    const url = `${this.url}`;
    return this.http.get<KncciService[]>(url);
  }
}
